import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { adminLogin } from '../../actions/auth';
import { Navigate } from 'react-router-dom';

const AdminLogin = ({ isAdminAuthenticated, adminLogin, onClose }) => {
    const [formData, setFormData] = useState({ 
        email: '',
        password: '' 
    });
    const [showPassword, setShowPassword] = useState(false);
    const modalRef = useRef(null);

    const { email, password } = formData;

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onClose]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const success = await adminLogin({ email, password });
        if (success && onClose) {
            onClose();
        }
    };

    if (isAdminAuthenticated) {
        return <Navigate to="/admin-dashboard" />;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
            <div ref={modalRef} className="bg-white rounded-lg w-full max-w-md shadow-2xl">
           
                <div className="p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-3xl font-bold text-gray-900">Admin Login</h2>
                    </div>
                    
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-1" htmlFor="email">
                                Email ID
                            </label>
                            <input
                                id="email"
                                type="email"
                                placeholder="Enter your Email ID"
                                name="email"
                                value={email}
                                onChange={handleChange}
                                className="w-full p-3 border border-gray-300 rounded-md text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                        </div>
                        <div className="relative">
                            <label className="block text-gray-700 text-sm font-semibold mb-1" htmlFor="password">
                                Password
                            </label>
                            <input
                                id="password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter your Password"
                                name="password"
                                value={password}
                                onChange={handleChange}
                                minLength="6"
                                className="w-full p-3 border border-gray-300 rounded-md text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute right-3 top-9 text-blue-600 text-sm font-medium"
                            >
                                {showPassword ? "Hide" : "Show"}
                            </button>
                        </div>
                        <button 
                            type="submit" 
                            className="w-full bg-blue-600 text-white p-3 rounded-md text-sm font-medium transition duration-300 hover:bg-blue-700"
                        >
                            Login
                        </button>
                    </form>
                </div>
         
            </div>
        </div>
    );
};

AdminLogin.propTypes = {
    isAdminAuthenticated: PropTypes.bool,
    adminLogin: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isAdminAuthenticated: state.auth.isAdminAuthenticated,
});

export default connect(mapStateToProps, { adminLogin })(AdminLogin);