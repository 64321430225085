import React, { useState } from 'react';
import JobSeekerProfileTop from "./ProfileTop";
import ProfileEducation from "./ProfileEducation";
import ProfileExperience from './ProfileExp';
import EditJobseekerProfile from "./EditProfileOverlay";
import ProfileAbout from './ProfileAbout';
import ProfileSidebar from './ProfileSidebar';
import PropTypes from 'prop-types';

const JobSeekerProfile = ({ profile }) => {
  console.log('JobSeekerProfile props:', { profile });
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);

  const handleEditProfile = () => {
    setIsEditProfileOpen(true);
  };

  const handleCloseEditProfile = () => {
    setIsEditProfileOpen(false);
  };

  if (!profile) {
    return <div>Loading profile...</div>;
  }

  // Inline styles for adjusting width and top margin
  const profileStyles = {
    width: '100%',  
    marginTop: '8px',  
    marginLeft: 'auto',
    marginRight: 'auto'
  };

  return (
    <div className="container mx-auto sm:px-2 sm:py-2" style={profileStyles}>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <JobSeekerProfileTop profile={profile} onEditProfile={handleEditProfile} />
        <ProfileAbout profile={profile} />
        <ProfileEducation education={profile.education} />
        <ProfileExperience workExperiences={profile.workExperiences} />
      </div>

      {isEditProfileOpen && (
        <EditJobseekerProfile
          profile={profile}
          onClose={handleCloseEditProfile}
        />
      )}
    </div>
  );
};

JobSeekerProfile.propTypes = {
  profile: PropTypes.object
};

export default JobSeekerProfile;
