import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getEmployerJobs } from '../../../actions/jobActions';
import { FaBriefcase, FaBell, FaUsers, FaBookmark, FaSpinner } from 'react-icons/fa';

const CompanyDashboardHome = ({ 
  employer, 
  profile, 
  getEmployerJobs, 
  jobs, 
  loading 
}) => {
  const [isProfileLoaded, setIsProfileLoaded] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    jobsPosted: 0,
    notifications: 0,
    applicants: 0,
    savedJobs: 0
  });

  useEffect(() => {
    getEmployerJobs();
  }, [getEmployerJobs]);

  useEffect(() => {
    if (profile !== undefined) {
      setIsProfileLoaded(true);
    }
  }, [profile]);

  useEffect(() => {
    if (jobs) {
      const totalApplicants = jobs.reduce((sum, job) => sum + job.applicants.length, 0);
      setDashboardData(prevState => ({
        ...prevState,
        jobsPosted: jobs.length,
        applicants: totalApplicants,
        notifications: totalApplicants // Assuming notifications are based on applications
      }));
    }
  }, [jobs]);

  if (loading && !isProfileLoaded) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-blue-600 text-3xl" />
      </div>
    );
  }

  const dashboardCards = [
    {
      title: "Jobs Posted",
      count: dashboardData.jobsPosted,
      icon: <FaBriefcase />,
      color: "bg-yellow-100",
      textColor: "text-yellow-500"
    },
    {
      title: "Notifications",
      count: dashboardData.notifications,
      icon: <FaBell />,
      color: "bg-red-100",
      textColor: "text-red-500"
    },
    {
      title: "Applicants",
      count: dashboardData.applicants,
      icon: <FaUsers />,
      color: "bg-blue-100",
      textColor: "text-blue-500"
    },
    {
      title: "Saved Jobs",
      count: dashboardData.savedJobs,
      icon: <FaBookmark />,
      color: "bg-green-100",
      textColor: "text-green-500"
    }
  ];

  return (
    <div className="bg-white p-6 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        {employer && (
          <div className="flex flex-col">
            <span className="text-xl font-semibold text-black">
              Welcome, <span className="capitalize text-blue-600">{employer.name}</span>
            </span>
            <span className="text-sm text-gray-500">
              {new Date().toLocaleDateString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </span>
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        {dashboardCards.map((card, index) => (
          <DashboardCard key={index} {...card} />
        ))}
      </div>

      {isProfileLoaded && profile === null && (
        <div className="bg-blue-50 border-l-4 border-blue-500 rounded-lg p-6 mb-6 transition-all duration-300 ease-in-out">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-lg font-medium text-blue-800">Complete Your Company Profile</h3>
              <div className="mt-2 text-blue-700">
                <p className="text-sm">Create your company profile to start posting jobs and reviewing applications.</p>
                <button className="mt-3 bg-blue-600 text-white px-4 py-2 rounded-md text-sm hover:bg-blue-700 transition duration-300">
                  Create Company Profile
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-gray-50 rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4">Recent Applications</h2>
        <RecentApplicationsList jobs={jobs} loading={loading} />
      </div>
    </div>
  );
};

const DashboardCard = ({ title, count, icon, color, textColor }) => (
  <div className={`${color} rounded-lg p-4 transform hover:scale-105 transition-transform duration-300 cursor-pointer`}>
    <div className="flex items-center justify-between mb-2">
      <div className={`${textColor} text-3xl font-bold`}>{count}</div>
      <div className={`${color} p-2 rounded-lg`}>
        <span className={`${textColor} text-xl`}>{icon}</span>
      </div>
    </div>
    <div className="text-gray-600 text-sm font-medium">{title}</div>
  </div>
);

const RecentApplicationsList = ({ jobs, loading }) => {
  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <FaSpinner className="animate-spin text-blue-600 text-xl" />
      </div>
    );
  }

  if (!jobs || jobs.length === 0) {
    return (
      <div className="text-center py-4 text-gray-500">
        No recent applications
      </div>
    );
  }

  const recentApplications = jobs
    .flatMap(job =>
      job.applicants.map(applicant => ({
        name: applicant.user.name,
        jobTitle: job.jobTitle,
        date: new Date(applicant.appliedDate)
      }))
    )
    .sort((a, b) => b.date - a.date)
    .slice(0, 5);

  if (recentApplications.length === 0) {
    return (
      <div className="text-center py-4 text-gray-500">
        No applications received yet
      </div>
    );
  }

  return (
    <ul className="space-y-4">
      {recentApplications.map((application, index) => (
        <li
          key={index}
          className="flex items-center space-x-3 p-3 hover:bg-gray-100 rounded-lg transition-colors duration-300"
        >
          <div className="bg-blue-100 p-2 rounded-lg">
            <FaBriefcase className="text-blue-500 text-sm" />
          </div>
          <div className="flex flex-col">
            <span className="text-sm">
              <span className="text-blue-500 font-medium">{application.name}</span> applied for {application.jobTitle}
            </span>
            <span className="text-xs text-gray-500">
              {application.date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              })}
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
};

CompanyDashboardHome.propTypes = {
  employer: PropTypes.object,
  profile: PropTypes.object,
  getEmployerJobs: PropTypes.func.isRequired,
  jobs: PropTypes.array,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  employer: state.auth.user,
  profile: state.profile.profile,
  jobs: state.job.jobs,
  loading: state.profile.loading || state.job.loading
});

export default connect(mapStateToProps, { getEmployerJobs })(CompanyDashboardHome);