import React from 'react';

const CompanyProfileAbout = ({ about, mission, vision }) => {
  return (
    <div className="p-6">
      <h3 className="text-2xl font-semibold mb-4">About Us</h3>
      <p className="text-gray-600 text-base font-ligh mb-4">{about}</p>
      
      {mission && (
        <>
          <h4 className="text-xl font-semibold mb-2">Our Mission</h4>
          <p className="text-gray-600 text-base font-light mb-4">{mission}</p>
        </>
      )}
      
      {vision && (
        <>
          <h4 className="text-xl font-semibold mb-2">Our Vision</h4>
          <p className="text-gray-600 text-base font-light">{vision}</p>
        </>
      )}
    </div>
  );
};

export default CompanyProfileAbout;