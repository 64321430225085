import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getAdminJobs, updateJobStatus,updateJobDetails,updateMultipleJobStatus } from '../../actions/adminJobActions';
import { setAlert } from '../../actions/alert';

const EditJobOverlay = ({ job, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    jobTitle: job.jobTitle || '',
    jobDescription: job.jobDescription || '',
    jobType: job.jobType || '',
    location: job.location || '',
    experienceLevel: job.experienceLevel || '',
    experience: job.experience || '',
    qualification: job.qualification || '',
    offeredSalary: job.offeredSalary || '',
    keySkills: job.keySkills || [],
    jobHighlights: job.jobHighlights || '',
    applicationDeadline: job.applicationDeadline ? 
      new Date(job.applicationDeadline).toISOString().split('T')[0] : ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSkillsChange = (e) => {
    const skills = e.target.value.split(',').map(skill => skill.trim());
    setFormData({
      ...formData,
      keySkills: skills
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(job._id, formData);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-2xl font-bold">Edit Job Posting</h3>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Job Title</label>
              <input
                type="text"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Job Type</label>
              <select
                name="jobType"
                value={formData.jobType}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              >
                <option value="">Select Job Type</option>
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Contract">Contract</option>
                <option value="Freelance">Freelance</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Location</label>
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Key Skills (comma-separated)</label>
            <input
              type="text"
              name="keySkills"
              value={formData.keySkills.join(', ')}
              onChange={handleSkillsChange}
              className="w-full p-2 border rounded"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Experience Level</label>
              <input
                type="text"
                name="experienceLevel"
                value={formData.experienceLevel}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Required Experience</label>
              <input
                type="text"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Qualification</label>
            <input
              type="text"
              name="qualification"
              value={formData.qualification}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Salary Range</label>
            <input
              type="text"
              name="offeredSalary"
              value={formData.offeredSalary}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Job Description</label>
            <textarea
              name="jobDescription"
              value={formData.jobDescription}
              onChange={handleChange}
              className="w-full p-2 border rounded h-32"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Job Highlights</label>
            <textarea
              name="jobHighlights"
              value={formData.jobHighlights}
              onChange={handleChange}
              className="w-full p-2 border rounded h-32"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Application Deadline</label>
            <input
              type="date"
              name="applicationDeadline"
              value={formData.applicationDeadline}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>

          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const JobDetailsOverlay = ({ job, onClose }) => {
  if (!job) return null;

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return 'bg-green-100 text-green-800';
      case 'rejected':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-yellow-100 text-yellow-800';
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto shadow-2xl">
        {/* Header Section */}
        <div className="border-b pb-4 mb-6">
          <div className="flex justify-between items-start mb-4">
            <div>
              <h3 className="text-3xl font-bold text-gray-800 mb-2">{job.jobTitle}</h3>
              <div className="flex items-center gap-4">
                <span className="text-xl text-gray-600">
                {job.employerProfile?.companyInfo?.name || 'Not Specified'}
                </span>
                <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(job.status)}`}>
                  {job.status?.charAt(0).toUpperCase() + job.status?.slice(1)}
                </span>
              </div>
            </div>
            <button 
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>

       {/* Company Information */}
<div className="bg-gray-50 rounded-lg p-4 mb-6">
  <h4 className="text-lg font-semibold text-gray-800 mb-3">Company Details</h4>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
    <div>
      <p className="text-sm text-gray-500">Company Name</p>
      <p className="font-medium">{job.employerProfile?.companyInfo?.name || 'Not Specified'}</p>
    </div>
    <div>
      <p className="text-sm text-gray-500">Industry</p>
      <p className="font-medium">{job.employerProfile?.companyInfo?.industry || job.industry || 'Not Specified'}</p>
    </div>
    <div>
      <p className="text-sm text-gray-500">Company Size</p>
      <p className="font-medium">{job.employerProfile?.companyInfo?.size || 'Not Specified'}</p>
    </div>
    <div>
      <p className="text-sm text-gray-500">Company Website</p>
      <p className="font-medium">
        {job.employerProfile?.companyInfo?.website ? (
          <a href={job.employerProfile.companyInfo.website} 
             target="_blank" 
             rel="noopener noreferrer" 
             className="text-blue-600 hover:text-blue-800">
            {job.employerProfile.companyInfo.website}
          </a>
        ) : 'Not Specified'}
      </p>
    </div>
  </div>

  {/* Contact Information */}
  <div className="mt-4">
    <h4 className="text-lg font-semibold text-gray-800 mb-3">Contact Information</h4>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <p className="text-sm text-gray-500">Contact Person</p>
        <p className="font-medium">{job.employerProfile?.contactInfo?.name || 'Not Specified'}</p>
      </div>
      <div>
        <p className="text-sm text-gray-500">Email</p>
        <p className="font-medium">{job.employerProfile?.contactInfo?.email || 'Not Specified'}</p>
      </div>
      <div>
        <p className="text-sm text-gray-500">Phone</p>
        <p className="font-medium">{job.employerProfile?.contactInfo?.phone || 'Not Specified'}</p>
      </div>
    </div>
  </div>

  {/* Company Address */}
  <div className="mt-4">
    <h4 className="text-lg font-semibold text-gray-800 mb-3">Company Address</h4>
    <p className="text-gray-700">
      {job.employerProfile?.companyAddress ? (
        <>
          {job.employerProfile.companyAddress.street}, {job.employerProfile.companyAddress.city},
          <br />
          {job.employerProfile.companyAddress.state} {job.employerProfile.companyAddress.zipCode},
          <br />
          {job.employerProfile.companyAddress.country}
        </>
      ) : 'Address not specified'}
    </p>
  </div>
</div>

        {/* Job Details */}
        <div className="space-y-6">
          {/* Basic Information */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-sm text-gray-500">Location</p>
              <p className="font-medium">{job.location || 'Not Specified'}</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-sm text-gray-500">Job Type</p>
              <p className="font-medium">{job.jobType || 'Not Specified'}</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-sm text-gray-500">Salary Range (LPA)</p>
              <p className="font-medium">{job.offeredSalary || 'Not Specified'}</p>
            </div>
          </div>

          {/* Experience & Qualifications */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="text-lg font-semibold text-gray-800 mb-3">Experience & Qualifications</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-500">Experience Level</p>
                <p className="font-medium">{job.experienceLevel || 'Not Specified'}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Required Experience</p>
                <p className="font-medium">{job.experience || 'Not Specified'}</p>
              </div>
              <div className="md:col-span-2">
                <p className="text-sm text-gray-500">Required Qualifications</p>
                <p className="font-medium">{job.qualification || 'Not Specified'}</p>
              </div>
            </div>
          </div>

          {/* Key Skills */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="text-lg font-semibold text-gray-800 mb-3">Key Skills</h4>
            <div className="flex flex-wrap gap-2">
              {job.keySkills?.length > 0 ? (
                job.keySkills.map((skill, index) => (
                  <span key={index} className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                    {skill}
                  </span>
                ))
              ) : (
                <p className="text-gray-600">No skills specified</p>
              )}
            </div>
          </div>

          {/* Job Description */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="text-lg font-semibold text-gray-800 mb-3">Job Description</h4>
            <p className="text-base whitespace-pre-wrap text-gray-700">{job.jobDescription || 'No description provided'}</p>
          </div>

          {/* Job Highlights */}
          {job.jobHighlights && (
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="text-lg font-semibold text-gray-800 mb-3">Job Highlights</h4>
              <p className="text-base whitespace-pre-wrap text-gray-700">{job.jobHighlights}</p>
            </div>
          )}

          {/* Application Details */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="text-lg font-semibold text-gray-800 mb-3">Application Details</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-500">Application Deadline</p>
                <p className="font-medium">
                  {job.applicationDeadline ? 
                    new Date(job.applicationDeadline).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    }) : 'Not Specified'}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Posted Date</p>
                <p className="font-medium">
                {new Date(job.date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RejectionModal = ({ isOpen, onClose, onConfirm }) => {
  const [reason, setReason] = useState('');

  const handleSubmit = () => {
    onConfirm(reason);
    setReason('');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg w-[500px] max-w-xl">
        <h3 className="text-xl font-semibold mb-4">Reject Job Posting</h3>
        <p className="text-gray-600 mb-4">Please provide a reason for rejecting this job posting. This will be sent to the employer.</p>
        <textarea
          className="w-full p-3 border rounded-lg mb-4 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          rows="4"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Enter rejection reason..."
        />
        <div className="flex justify-end gap-3">
          <button
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-lg transition-colors"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors"
            onClick={handleSubmit}
            disabled={!reason.trim()}
          >
            Confirm Rejection
          </button>
        </div>
      </div>
    </div>
  );
};

const ApprovalModal = ({ isOpen, onClose, onConfirm, jobTitle }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg w-[400px]">
        <h3 className="text-xl font-semibold mb-4">Confirm Approval</h3>
        <p className="text-gray-600 mb-4">
          Are you sure you want to approve the job posting for "{jobTitle}"? 
          This will make it visible to all job seekers.
        </p>
        <div className="flex justify-end gap-3">
          <button
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-lg transition-colors"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg transition-colors"
            onClick={onConfirm}
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  );
};

const StatusBadge = ({ status }) => {
  const statusStyles = {
    pending: 'bg-yellow-100 text-yellow-800 border-yellow-200',
    approved: 'bg-green-100 text-green-800 border-green-200',
    rejected: 'bg-red-100 text-red-800 border-red-200'
  };

  return (
    <span className={`px-3 py-1 rounded-full text-sm font-medium border ${statusStyles[status || 'pending']}`}>
      {(status || 'pending').charAt(0).toUpperCase() + (status || 'pending').slice(1)}
    </span>
  );
};


const JobPostingManagement = ({ 
  getAdminJobs, 
  updateJobStatus,
  updateJobDetails,
  updateMultipleJobStatus,
  adminJobs: { jobs = [], loading = false, error = null } = {}
}) => {
  const [filter, setFilter] = useState('all');
  const [dateFilter, setDateFilter] = useState('all');
  const [selectedJob, setSelectedJob] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [employerStats, setEmployerStats] = useState({});
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [jobToReject, setJobToReject] = useState(null);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState([]);
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [jobsPerPage] = useState(10);
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
  
  const dispatch = useDispatch();

  useEffect(() => {
    getAdminJobs();
  }, [getAdminJobs]);
  // Filter and sort jobs
  const getFilteredAndSortedJobs = () => {
    let filtered = jobs.filter(job => {
      const matchesStatus = filter === 'all' || job.status === filter;
      const matchesSearch = !searchTerm || 
        job.jobTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.employerProfile?.companyInfo?.name.toLowerCase().includes(searchTerm.toLowerCase());
      
      return matchesStatus && matchesSearch;
    });

    // Sort jobs
    filtered.sort((a, b) => {
      if (sortConfig.key === 'createdAt') {
        return sortConfig.direction === 'asc' 
          ? new Date(a.createdAt) - new Date(b.createdAt)
          : new Date(b.createdAt) - new Date(a.createdAt);
      }
      return 0;
    });

    return filtered;
  };

  // Get current jobs for pagination
  const filteredJobs = getFilteredAndSortedJobs();
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
  const totalPages = Math.ceil(filteredJobs.length / jobsPerPage);


  
  useEffect(() => {
    if (error) {
      dispatch(setAlert(error.msg, 'error'));
    }
  }, [error, dispatch]);

  useEffect(() => {
    // Calculate employer statistics
    const stats = jobs.reduce((acc, job) => {
      const employerId = job.employer?._id;
      if (!acc[employerId]) {
        acc[employerId] = {
          totalJobs: 0,
          activeJobs: 0,
          expiredJobs: 0,
          employerName: job.employerProfile?.companyInfo?.name
        };
      }
      
      acc[employerId].totalJobs++;
      
      const isExpired = new Date(job.applicationDeadline) < new Date();
      if (isExpired) {
        acc[employerId].expiredJobs++;
      } else if (job.status === 'approved') {
        acc[employerId].activeJobs++;
      }
      
      return acc;
    }, {});
    
    setEmployerStats(stats);
  }, [jobs]);

const handleApproveClick = (job) => {
  setSelectedJob(job);
  setShowApprovalModal(true);
};

const handleRejectClick = (job) => {
  setSelectedJob(job);
  setShowRejectionModal(true);
};

const handleSelectAll = (event) => {
  if (event.target.checked) {
    setSelectedJobs(currentJobs.map(job => job._id));
  } else {
    setSelectedJobs([]);
  }
};

const handleSelectJob = (jobId) => {
  setSelectedJobs(prev => {
    if (prev.includes(jobId)) {
      return prev.filter(id => id !== jobId);
    }
    return [...prev, jobId];
  });
};
const handleBulkApprove = async () => {
  try {
    await updateMultipleJobStatus(selectedJobs, 'approved');
    setSelectedJobs([]);
    dispatch(setAlert('Jobs approved successfully', 'success'));
  } catch (error) {
    dispatch(setAlert('Error approving jobs', 'error'));
  }
};

const handleBulkReject = async (reason) => {
  try {
    await updateMultipleJobStatus(selectedJobs, 'rejected', reason);
    setSelectedJobs([]);
    dispatch(setAlert('Jobs rejected successfully', 'success'));
  } catch (error) {
    dispatch(setAlert('Error rejecting jobs', 'error'));
  }
};

const handleApproveConfirm = async () => {
  try {
    await updateJobStatus(selectedJob._id, 'approved');
    setShowApprovalModal(false);
    setSelectedJob(null);
    dispatch(setAlert('Job posting approved successfully', 'success'));
  } catch (error) {
    dispatch(setAlert('Error approving job posting', 'error'));
  }
};

const handleRejectConfirm = async (reason) => {
  try {
    await updateJobStatus(selectedJob._id, 'rejected', reason);
    setShowRejectionModal(false);
    setSelectedJob(null);
    dispatch(setAlert('Job posting rejected successfully', 'success'));
  } catch (error) {
    dispatch(setAlert('Error rejecting job posting', 'error'));
  }
};

  const handleEdit = (job) => {
    setSelectedJob(job);
    setIsEditing(true);
  };

  const handleUpdateJob = async (jobId, updatedData) => {
    await updateJobDetails(jobId, updatedData);
    setIsEditing(false);
    setSelectedJob(null);
  };

  const renderTableRow = (job) => (
  <tr key={job._id} className="block md:table-row border-b border-gray-200 hover:bg-gray-50 transition-colors">
    <td className="p-4 md:px-6 md:py-4 block md:table-cell">
    <input
        type="checkbox"
        checked={selectedJobs.includes(job._id)}
        onChange={() => handleSelectJob(job._id)}
        className="rounded border-gray-300"
      />
      {/* Card-like layout for mobile */}
      <div className="flex flex-col gap-4 md:block">
        {/* Header section with status badge */}
        <div className="flex items-start justify-between">
          <div className="flex-1">
            <h3 className="font-medium text-gray-900 mb-1">{job.jobTitle}</h3>
            <span className="inline-block text-sm text-gray-500 mb-2">{job.jobType}</span>
          </div>
          <div className="md:hidden">
            <StatusBadge status={job.status} />
          </div>
        </div>

        {/* Company info section */}
        <div className="space-y-1 md:hidden">
          <div className="font-medium text-gray-700">{job.employerProfile?.companyInfo?.name || 'N/A'}</div>
          <div className="text-sm text-gray-500 flex items-center">
            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            {job.location}
          </div>
        </div>

        {/* Actions section */}
        <div className="grid grid-cols-2 gap-2 mt-2 md:hidden">
          <button 
            onClick={() => setSelectedJob(job)}
            className="inline-flex items-center justify-center px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md text-sm transition-colors"
          >
            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
            </svg>
            View
          </button>
          
          {job.status === 'pending' && (
            <>
              <button 
                onClick={() => handleEdit(job)}
                className="inline-flex items-center justify-center px-3 py-2 bg-gray-500 hover:bg-gray-600 text-white rounded-md text-sm transition-colors"
              >
                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
                Edit
              </button>

              <button 
                onClick={() => {
                  setSelectedJob(job);
                  setShowApprovalModal(true);
                }}
                className="inline-flex items-center justify-center px-3 py-2 bg-green-500 hover:bg-green-600 text-white rounded-md text-sm transition-colors"
              >
                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Approve
              </button>

              <button 
                onClick={() => {
                  setSelectedJob(job);
                  setShowRejectionModal(true);
                }}
                className="inline-flex items-center justify-center px-3 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md text-sm transition-colors"
              >
                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                Reject
              </button>
            </>
          )}
        </div>
      </div>
    </td>

    {/* Desktop-only columns */}
    <td className="hidden md:table-cell py-4 px-6 text-left">
      <div className="font-medium">{job.employerProfile?.companyInfo?.name || 'N/A'}</div>
      <div className="text-sm text-gray-500">{job.location}</div>
    </td>

    <td className="hidden md:table-cell py-4 px-6 text-center">
      <StatusBadge status={job.status} />
    </td>

    <td className="hidden md:table-cell py-4 px-6">
      <div className="flex items-center justify-end gap-2">
        <button 
          onClick={() => setSelectedJob(job)}
          className="inline-flex items-center px-3 py-1.5 bg-blue-500 hover:bg-blue-600 text-white rounded-md text-sm transition-colors"
        >
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
          </svg>
          View
        </button>
        
        {job.status === 'pending' && (
          <>
            <button 
              onClick={() => handleEdit(job)}
              className="inline-flex items-center px-3 py-1.5 bg-gray-500 hover:bg-gray-600 text-white rounded-md text-sm transition-colors"
            >
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
              Edit
            </button>

            <button 
              onClick={() => {
                setSelectedJob(job);
                setShowApprovalModal(true);
              }}
              className="inline-flex items-center px-3 py-1.5 bg-green-500 hover:bg-green-600 text-white rounded-md text-sm transition-colors"
            >
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              Approve
            </button>

            <button 
              onClick={() => {
                setSelectedJob(job);
                setShowRejectionModal(true);
              }}
              className="inline-flex items-center px-3 py-1.5 bg-red-500 hover:bg-red-600 text-white rounded-md text-sm transition-colors"
            >
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
              Reject
            </button>
          </>
        )}
      </div>
    </td>
  </tr>
);

  const Pagination = () => (
    <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
      <div className="flex justify-between flex-1 sm:hidden">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{indexOfFirstJob + 1}</span> to{' '}
            <span className="font-medium">{Math.min(indexOfLastJob, filteredJobs.length)}</span> of{' '}
            <span className="font-medium">{filteredJobs.length}</span> results
          </p>
        </div>
        <div>
          <nav className="inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
                  currentPage === page
                    ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                } border`}
              >
                {page}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
  


  if (loading) {
    return <div className="text-center p-4">Loading...</div>;
  }
  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-3xl font-light mb-4">Job Posting Management</h2>
      <div className="mb-4">
        <select
          className="w-full text-base p-2 border rounded"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="all">All Jobs</option>
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
        </select>
      </div>
      <div className="overflow-x-auto">
          {selectedJobs.length > 0 && (
            <div className="mb-4 flex items-center gap-2">
              <span className="text-sm text-gray-600">
                {selectedJobs.length} job{selectedJobs.length === 1 ? '' : 's'} selected
              </span>
              <button
                onClick={handleBulkApprove}
                className="inline-flex items-center px-3 py-1.5 bg-green-500 hover:bg-green-600 text-white rounded-md text-sm transition-colors"
              >
                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Approve Selected
              </button>
              <button
                onClick={() => {
                  setShowRejectionModal(true);
                  setJobToReject({ ids: selectedJobs });
                }}
                className="inline-flex items-center px-3 py-1.5 bg-red-500 hover:bg-red-600 text-white rounded-md text-sm transition-colors"
              >
                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                Reject Selected
              </button>
            </div>
          )}
        <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <th className="py-3 px-6 text-left">
              <input
                type="checkbox"
                checked={selectedJobs.length === currentJobs.length}
                onChange={handleSelectAll}
                className="rounded border-gray-300"
              />
            </th>
            <th className="py-3 px-6 text-left">Title</th>
            <th className="py-3 px-6 text-left">Company</th>
            <th className="py-3 px-6 text-center">Details</th>
            <th className="py-3 px-6 text-center">Actions</th>
          </tr>
        </thead>
          <tbody className="text-gray-600 text-sm font-light">
          {currentJobs.map(renderTableRow)}
          </tbody>
        </table>
      </div>
      <Pagination />
      
      {selectedJob && (
        <JobDetailsOverlay 
          job={selectedJob} 
          onClose={() => setSelectedJob(null)} 
        />
      )}

      {isEditing && selectedJob && (
        <EditJobOverlay 
          job={selectedJob}
          onClose={() => {
            setIsEditing(false);
            setSelectedJob(null);
          }}
          onSave={handleUpdateJob}
        />
      )}
      <ApprovalModal
        isOpen={showApprovalModal}
        onClose={() => {
          setShowApprovalModal(false);
          setSelectedJob(null);
        }}
        onConfirm={handleApproveConfirm}
        jobTitle={selectedJob?.jobTitle}
      />
         <RejectionModal
  isOpen={showRejectionModal}
  onClose={() => {
    setShowRejectionModal(false);
    setSelectedJob(null);
    setJobToReject(null);
  }}
  onConfirm={(reason) => {
    if (jobToReject?.ids) {
      handleBulkReject(reason);
    } else {
      handleRejectConfirm(reason);
    }
  }}
  jobTitle={selectedJob ? selectedJob.jobTitle : `${selectedJobs.length} selected jobs`}
/>

    </div>
  );
};

export default connect(
  state => ({ adminJobs: state.adminJobs }), 
  { getAdminJobs, updateJobStatus, updateJobDetails, updateMultipleJobStatus }
)(JobPostingManagement);