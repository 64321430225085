// utils/setAuthToken.js
import axios from 'axios';

const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common['x-auth-token'] = token;
    console.log('Token set in axios defaults:', token);
  } else {
    delete axios.defaults.headers.common['x-auth-token'];
    console.log('Token removed from axios defaults');
  }
};

export default setAuthToken;