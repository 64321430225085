import React from 'react';

const CompanyProfileValues = ({ values }) => {
  return (
    <div className="p-6">
      <h3 className="text-2xl font-semibold mb-4">Our Values</h3>
      <ul className="list-disc pl-5 space-y-2">
        {values.map((value, index) => (
          <li key={index} className="text-gray-700">
            <span className="text-base font-light ">{value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CompanyProfileValues;