import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import AdminDashboardHome from './AdminDashboardHome';
import UserOverview from './UserOverview';
import JobPostingManagement from './JobPostingManagement';
import ApplicationManagement from './ApplicationManagement';
import ReportsAnalytics from './ReportsAnalytics';

const Spinner = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

const AdminDashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Simulating data loading
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
      <AdminSidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
      <div className="flex-1 md:ml-64 bg-white flex flex-col">
        <header className="bg-white shadow-sm p-3 md:hidden sticky top-0 z-10">
          <button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className="text-gray-500 focus:outline-none focus:text-gray-700"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </header>
        <main className="flex-grow p-3 sm:p-4 md:p-6 bg-gray-50">
          <div className="max-w-7xl mx-auto w-full">
            <Routes>
              <Route path="/" element={<AdminDashboardHome />} />
              <Route path="user-overview" element={<UserOverview />} />
              <Route path="job-posting-management" element={<JobPostingManagement />} />
              <Route path="application-management" element={<ApplicationManagement />} />
              <Route path="reports-analytics" element={<ReportsAnalytics />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;