import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    EMPLOYER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    EMPLOYER_REGISTER_SUCCESS,
    EMPLOYER_LOGIN_SUCCESS,
    JOBSEEKER_LOADED,
    JOBSEEKER_LOGIN_SUCCESS,
    UPDATE_AUTH_JOBSEEKER,
    PROFILE_CREATED,
    ADMIN_LOGIN_SUCCESS,
    ADMIN_LOGIN_FAIL,
    ADMIN_LOADED,
} from '../actions/types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    isEmployerAuthenticated: null,
    isAdminAuthenticated: null,
    loading: true,
    jobseeker: null,
    employer: null,
    userType: null,
    user:null
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case USER_LOADED:
            return {
              ...state,
              isAuthenticated: true,
              loading: false,
              user: payload
            };
            case PROFILE_CREATED:
                return {
                  ...state,
                  profileCreated: true
                };
        case JOBSEEKER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isEmployerAuthenticated: false,
                loading: false,
                jobSeeker: payload,
                userType: 'jobseeker'
            };
   
            case UPDATE_AUTH_JOBSEEKER:
                return {
                    ...state,
                    isAuthenticated: true,
                    loading: false,
                    jobseeker: payload,
                    userType: payload.status
                };
        case EMPLOYER_LOADED:
            return {
                ...state,
                isAuthenticated: false,
                isEmployerAuthenticated: true,
                loading: false,
                employer: payload,
                userType: 'employer'
            };
            
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
        case JOBSEEKER_LOGIN_SUCCESS:
            localStorage.setItem('token', payload.token);
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                isEmployerAuthenticated: false,
                loading: false,
                userType: payload.jobSeeker ? payload.jobSeeker.status : null
            };
        case EMPLOYER_REGISTER_SUCCESS:
        case EMPLOYER_LOGIN_SUCCESS:
            localStorage.setItem('token', payload.token);
            return {
                ...state,
                ...payload,
                isAuthenticated: false,
                isEmployerAuthenticated: true,
                loading: false,
                userType: 'employer'
            };
        case REGISTER_FAIL:
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                isEmployerAuthenticated: false,
                loading: false,
                jobseeker: null,
                employer: null,
                userType: null,
                user:null
            };
            case ADMIN_LOADED:
            return {
                ...state,
                isAdminAuthenticated: true,
                loading: false,
                admin: payload
            };
        case ADMIN_LOGIN_SUCCESS:
            localStorage.setItem('token', payload.token);
            return {
                ...state,
                ...payload,
                isAdminAuthenticated: true,
                loading: false
            };
        case ADMIN_LOGIN_FAIL:
        case AUTH_ERROR:
        case LOGOUT:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAdminAuthenticated: false,
                loading: false,
                admin: null
            };
        default:
            return state;
    }
}