import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import JobSeekerDashboardHome from './DashboardHome';
import PropTypes from 'prop-types';
import Sidebar from '../../layout/Sidebar';
import JobSeekerProfile from '../../profile/ApplicantProfile/ApplicantProfile';
import { getCurrentJobseekerProfile } from '../../../actions/jobseekerProfile';
import UploadResume from './UploadResume';
import ResetPassword from './PasswordReset';
import LogOut from './Logout';
import AppliedJobs from './AppliedJobs';
import JobListings from './JobListings';
import { setAlert } from '../../../actions/alert';

const Spinner = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);


const JobSeekerDashboard = ({
  getCurrentJobseekerProfile,
  setAlert,
  auth: { jobSeeker },
  profile: { profile, loading },
}) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        console.log("Fetching jobseeker profile");
        await getCurrentJobseekerProfile();
        console.log("Profile fetched successfully");
        if (location.state?.profileCreated) {
          console.log("Profile was just created, showing success alert");
          setAlert('Profile created successfully!', 'success');
          // Clear the state after showing the alert
          navigate(location.pathname, { replace: true, state: {} });
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        if (retryCount < 3) {
          console.log(`Retrying profile fetch. Attempt ${retryCount + 1}`);
          setTimeout(() => {
            setRetryCount(prevCount => prevCount + 1);
          }, 1000);
        }
      }
    };
  
    fetchProfile();
  }, [getCurrentJobseekerProfile, retryCount, location, setAlert, navigate]);

   if (loading) {
    return <Spinner />;
  }

  if (!loading && profile === null && retryCount >= 3) {
    return <div>Error loading profile. Please refresh the page.</div>;
  }

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
      <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
      <div className="flex-1 md:ml-64 bg-white">
        <header className="bg-white shadow-sm p-3 md:hidden sticky top-0 z-10">
          <button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className="text-gray-500 focus:outline-none focus:text-gray-700"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </header>
        <main className="p-3 sm:p-4 md:p-6 bg-gray-50 min-h-screen">
          <div className="max-w-7xl mx-auto">
            <Routes>
            <Route index element={<JobSeekerDashboardHome jobSeeker={jobSeeker} profile={profile} />} />
              <Route path="profile" element={<JobSeekerProfile profile={profile} />} />
              <Route path="upload-resume" element={<UploadResume />} />
              {/* <Route path="apply-job" element={<ApplyForJob />} /> */}
              <Route path="applied-jobs" element={<AppliedJobs />} />
              <Route path="job-listings" element={<JobListings />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="logout" element={<LogOut />} />
    
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
};
JobSeekerDashboard.propTypes = {
  getCurrentJobseekerProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  getCurrentJobseekerProfile,
  setAlert,
})(JobSeekerDashboard);