// actions/applicantManagement.js
import axios from 'axios';
import {
  GET_ALL_APPLICATIONS_REQUEST,
  GET_ALL_APPLICATIONS_SUCCESS,
  GET_ALL_APPLICATIONS_FAIL,
  UPDATE_APPLICATION_STATUS_REQUEST,
  UPDATE_APPLICATION_STATUS_SUCCESS,
  UPDATE_APPLICATION_STATUS_FAIL
} from './types';
import { setAlert } from './alert';

export const getAllApplications = () => async dispatch => {
    try {
      dispatch({ type: GET_ALL_APPLICATIONS_REQUEST });
  
      const res = await axios.get('/api/admin/applications');
  
      dispatch({
        type: GET_ALL_APPLICATIONS_SUCCESS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: GET_ALL_APPLICATIONS_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };
  
  // Update application status
  export const updateApplicationStatus = (jobId, applicantId, status) => async dispatch => {
    try {
      dispatch({ type: UPDATE_APPLICATION_STATUS_REQUEST });
  
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  
      const res = await axios.put(
        `/api/admin/applications/${jobId}/${applicantId}/status`,
        { status },
        config
      );
  
      dispatch({
        type: UPDATE_APPLICATION_STATUS_SUCCESS,
        payload: res.data
      });
  
      dispatch(setAlert(`Application ${status}`, 'success'));
    } catch (err) {
      dispatch({
        type: UPDATE_APPLICATION_STATUS_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };