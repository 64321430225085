import React, { useState, useEffect } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { connect } from 'react-redux';
import { updateEmployerProfile } from '../../../actions/employerProfileActions';

const EditCompanyProfile = ({ profile, onClose, updateEmployerProfile }) => {
  const [formData, setFormData] = useState({
    hrDetails: {
      name: '',
      email: '',
      phone: '',
      image: profile.hrDetails.image || null
    },
    companyInfo: {
      name: '',
      industry: '',
      size: '',
      description: '',
      website: '',
      linkedinUrl: '',
      about: '',
      mission: '',
      vision: '',
      values: []
    },
    contactInfo: {
      name: '',
      email: '',
      phone: '',
      jobTitle: ''
    },
    companyAddress: {
      country: '',
      city: '',
      street: '',
      zipCode: ''
    }
  });

  const handleValuesChange = (index, value) => {
    const newValues = [...formData.companyInfo.values];
    newValues[index] = value;
    setFormData({
      ...formData,
      companyInfo: {
        ...formData.companyInfo,
        values: newValues
      }
    });
  };

  const addValue = () => {
    setFormData({
      ...formData,
      companyInfo: {
        ...formData.companyInfo,
        values: [...formData.companyInfo.values, '']
      }
    });
  }
  const removeValue = (index) => {
    const newValues = [...formData.companyInfo.values];
    newValues.splice(index, 1);
    setFormData({
      ...formData,
      companyInfo: {
        ...formData.companyInfo,
        values: newValues
      }
    });
  };
  useEffect(() => {
    if (profile) {
      setFormData({
        hrDetails: { 
          ...profile.hrDetails,
          image: profile.hrDetails.image ? profile.hrDetails.image : { url: '' }
        },
        companyInfo: { ...profile.companyInfo },
        contactInfo: { ...profile.contactInfo },
        companyAddress: { ...profile.companyAddress }
      });
    }
  }, [profile]);

  const handleChange = (e, section) => {
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [e.target.name]: e.target.value
      }
    });
  };
  
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        alert('File size should not exceed 5MB');
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          hrDetails: {
            ...formData.hrDetails,
            image: reader.result // Store the base64 string directly
          }
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateEmployerProfile(formData);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-100 rounded-lg shadow-xl w-full max-w-4xl mx-4 my-8 overflow-y-auto max-h-[90vh]">
        <div className="p-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-3xl font-bold">Edit Company Profile</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <XIcon className="w-6 h-6" />
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="space-y-6">
              {/* HR Details */}
              <div>
                <h3 className="text-xl font-semibold mb-3">HR Details</h3>
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    name="name"
                    value={formData.hrDetails.name}
                    onChange={(e) => handleChange(e, 'hrDetails')}
                    placeholder="HR Name"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="email"
                    name="email"
                    value={formData.hrDetails.email}
                    onChange={(e) => handleChange(e, 'hrDetails')}
                    placeholder="HR Email"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="tel"
                    name="phone"
                    value={formData.hrDetails.phone}
                    onChange={(e) => handleChange(e, 'hrDetails')}
                    placeholder="HR Phone"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <div>
                  <label htmlFor="hrImage" className="block text-sm font-semibold text-gray-700 mb-1">
                    HR Image
                  </label>
                  <div className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer relative overflow-hidden">
                  {formData.hrDetails.image && formData.hrDetails.image.url ? (
                        <img src={formData.hrDetails.image.url} alt="HR" className="w-full h-full object-cover" />
                      ) : (
                      <div className="text-center">
                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                          <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p className="mt-1 text-sm text-gray-600">Upload HR Image</p>
                      </div>
                    )}
                    <input
                      id="hrImage"
                      type="file"
                      accept=".png,.jpg,.jpeg"
                      onChange={handleImageChange}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    />
                  </div>
                </div>
                </div>
              </div>

              {/* Company Info */}
              <div>
                <h3 className="text-xl font-semibold mb-3">Company Information</h3>
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    name="name"
                    value={formData.companyInfo.name}
                    onChange={(e) => handleChange(e, 'companyInfo')}
                    placeholder="Company Name"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="text"
                    name="industry"
                    value={formData.companyInfo.industry}
                    onChange={(e) => handleChange(e, 'companyInfo')}
                    placeholder="Industry"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="text"
                    name="size"
                    value={formData.companyInfo.size}
                    onChange={(e) => handleChange(e, 'companyInfo')}
                    placeholder="Company Size"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="text"
                    name="website"
                    value={formData.companyInfo.website}
                    onChange={(e) => handleChange(e, 'companyInfo')}
                    placeholder="Website"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="text"
                    name="linkedinUrl"
                    value={formData.companyInfo.linkedinUrl}
                    onChange={(e) => handleChange(e, 'companyInfo')}
                    placeholder="LinkedIn URL"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                </div>
                <textarea
                  name="description"
                  value={formData.companyInfo.description}
                  onChange={(e) => handleChange(e, 'companyInfo')}
                  placeholder="Company Description"
                  className="block w-full px-4 py-2 mt-4 rounded-xl text-sm"
                  rows="3"
                ></textarea>
              </div>

              {/* Contact Info */}
              <div>
                <h3 className="text-xl font-semibold mb-3">Contact Information</h3>
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    name="name"
                    value={formData.contactInfo.name}
                    onChange={(e) => handleChange(e, 'contactInfo')}
                    placeholder="Contact Name"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="email"
                    name="email"
                    value={formData.contactInfo.email}
                    onChange={(e) => handleChange(e, 'contactInfo')}
                    placeholder="Contact Email"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="tel"
                    name="phone"
                    value={formData.contactInfo.phone}
                    onChange={(e) => handleChange(e, 'contactInfo')}
                    placeholder="Contact Phone"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="text"
                    name="jobTitle"
                    value={formData.contactInfo.jobTitle}
                    onChange={(e) => handleChange(e, 'contactInfo')}
                    placeholder="Job Title"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                </div>
                
              </div>

              {/* Company Address */}
              <div>
                <h3 className="text-xl font-semibold mb-3">Company Address</h3>
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    name="country"
                    value={formData.companyAddress.country}
                    onChange={(e) => handleChange(e, 'companyAddress')}
                    placeholder="Country"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="text"
                    name="city"
                    value={formData.companyAddress.city}
                    onChange={(e) => handleChange(e, 'companyAddress')}
                    placeholder="City"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="text"
                    name="street"
                    value={formData.companyAddress.street}
                    onChange={(e) => handleChange(e, 'companyAddress')}
                    placeholder="Street"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="text"
                    name="zipCode"
                    value={formData.companyAddress.zipCode}
                    onChange={(e) => handleChange(e, 'companyAddress')}
                    placeholder="Zip Code"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                </div>
                <textarea
              name="about"
              value={formData.companyInfo.about}
              onChange={(e) => handleChange(e, 'companyInfo')}
              placeholder="About the Company"
              className="block w-full px-4 py-2 mt-4 rounded-xl text-sm"
              rows="3"
            ></textarea>
            <textarea
              name="mission"
              value={formData.companyInfo.mission}
              onChange={(e) => handleChange(e, 'companyInfo')}
              placeholder="Company Mission"
              className="block w-full px-4 py-2 mt-4 rounded-xl text-sm"
              rows="3"
            ></textarea>
            <textarea
              name="vision"
              value={formData.companyInfo.vision}
              onChange={(e) => handleChange(e, 'companyInfo')}
              placeholder="Company Vision"
              className="block w-full px-4 py-2 mt-4 rounded-xl text-sm"
              rows="3"
            ></textarea>
            <div className="mt-4">
              <h4 className="text-lg font-semibold mb-2">Company Values</h4>
              {formData.companyInfo.values.map((value, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => handleValuesChange(index, e.target.value)}
                    placeholder={`Value ${index + 1}`}
                    className="block w-full px-4 py-2 rounded-xl text-sm mr-2"
                  />
                  <button
                    type="button"
                    onClick={() => removeValue(index)}
                    className="bg-red-500 text-white px-2 py-1 text-sm rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={addValue}
                className="bg-green-500 text-sm text-white px-2 py-2 rounded mt-2"
              >
                Add Value
              </button>
              </div>
              </div>
            </div>

            <div className="mt-8 flex justify-center">
              <button
                type="submit"
                className="inline-block justify-center px-8 py-2 text-white bg-blue-500 rounded-md text-lg font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { updateEmployerProfile })(EditCompanyProfile);