import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AboutPage from './AboutPage'
import ServicesPage from './ServicesPage'
import LandingloginPage from './Landinglogin'
import Footer from './Footer'
import AnalyzePage from './AnaylzePage'
import Hero from './Hero'
import Login from '../auth/Login'
import ContactUs from './ContactUs'
import CompanyStats from './CompanyStats'
import FeaturedJobs from './FeaturedJobs'
import FeaturedCompanies from './FeaturedCompanies'
import SponsoredCompanies from './SponsoredCompanies'
import ErrorBoundary from './ErrorBounday'



function Landing({isAuthenticated, isCompanyAuthenticated}) {

  const [showLogin, setShowLogin] = useState(false);

  // if(isAuthenticated){
  //   return <Navigate to='/dashboard' />
  // }
  // if(isCompanyAuthenticated){
  //   return <Navigate to='/company-dashboard' />
  // }


  const toggleLogin = () => setShowLogin(!showLogin);
 
  
  return (
    <div>
      <ErrorBoundary>
      <Hero/> 
      </ErrorBoundary>
      <CompanyStats/>
      <FeaturedCompanies/>
      <FeaturedJobs/>
      <SponsoredCompanies/>
      <Footer/>

      {showLogin && <Login onClose={toggleLogin} />}
     
    </div>
  )
}

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
  isCompanyAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isCompanyAuthenticated: state.auth.isCompanyAuthenticated
})

export default connect(mapStateToProps)(Landing)