// reducers/applicantManagement.js
import {
    GET_ALL_APPLICATIONS_REQUEST,
    GET_ALL_APPLICATIONS_SUCCESS,
    GET_ALL_APPLICATIONS_FAIL,
    UPDATE_APPLICATION_STATUS_REQUEST,
    UPDATE_APPLICATION_STATUS_SUCCESS,
    UPDATE_APPLICATION_STATUS_FAIL
  } from '../actions/types';
  
  const initialState = {
    applications: [],
    loading: false,
    error: null,
    stats: {
      total: 0,
      new: 0,
      accepted: 0,
      rejected: 0
    }
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_ALL_APPLICATIONS_REQUEST:
      case UPDATE_APPLICATION_STATUS_REQUEST:
        return {
          ...state,
          loading: true
        };
  
      case GET_ALL_APPLICATIONS_SUCCESS:
        const stats = payload.reduce((acc, app) => {
          acc.total++;
          acc[app.status] = (acc[app.status] || 0) + 1;
          return acc;
        }, { total: 0, new: 0, accepted: 0, rejected: 0 });
  
        return {
          ...state,
          loading: false,
          applications: payload,
          stats
        };
  
      case UPDATE_APPLICATION_STATUS_SUCCESS:
        return {
          ...state,
          loading: false,
          applications: state.applications.map(app =>
            app.id === payload.id ? { ...app, status: payload.status } : app
          )
        };
        case UPDATE_APPLICATION_STATUS_SUCCESS:
            const updatedApplications = state.applications.map(app =>
                app.id === payload.id ? { ...app, status: payload.status } : app
            );
            
            const updatedStats = updatedApplications.reduce((acc, app) => {
                acc.total++;
                acc[app.status] = (acc[app.status] || 0) + 1;
                return acc;
            }, { total: 0, new: 0, accepted: 0, rejected: 0 });

            return {
                ...state,
                loading: false,
                applications: updatedApplications,
                stats: updatedStats
            };
  
      case GET_ALL_APPLICATIONS_FAIL:
      case UPDATE_APPLICATION_STATUS_FAIL:
        return {
          ...state,
          loading: false,
          error: payload
        };
  
      default:
        return state;
    }
  }