import React from 'react';
import PropTypes from 'prop-types';

const CompanyAddressInformation = ({ prevStep, nextStep, handleChange, values }) => {
  const continueStep = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div className="bg-gray-100 p-4 sm:p-6 md:p-8 rounded-lg shadow-md max-w-4xl mx-auto">
      <h2 className="text-lg sm:text-xl font-semibold mb-4 sm:mb-6">Company Address Information</h2>
      <form onSubmit={continueStep}>
        <div className="space-y-4 sm:space-y-6">
          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="address1">
              Address Line 1
            </label>
            <input
              type="text"
              id="address1"
              className="w-full p-2 sm:p-3 border border-gray-300 rounded-xl text-base placeholder:text-base placeholder-gray-400"
              placeholder="Enter address line 1"
              value={values.address1}
              onChange={(e) => handleChange('address1')(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="address2">
              Address Line 2 <span className="text-gray-500">(Optional)</span>
            </label>
            <input
              type="text"
              id="address2"
              className="w-full p-2 sm:p-3 border border-gray-300 rounded-xl text-base placeholder:text-base placeholder-gray-400"
              placeholder="Enter address line 2"
              value={values.address2}
              onChange={(e) => handleChange('address2')(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="country">
                Country
              </label>
              <input
                type="text"
                id="country"
                className="w-full p-2 sm:p-3 border border-gray-300 rounded-xl text-base placeholder:text-base placeholder-gray-400"
                placeholder="Enter country"
                value={values.country}
                onChange={(e) => handleChange('country')(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="city">
                City
              </label>
              <input
                type="text"
                id="city"
                className="w-full p-2 sm:p-3 border border-gray-300 rounded-xl text-base placeholder:text-base placeholder-gray-400"
                placeholder="Enter city"
                value={values.city}
                onChange={(e) => handleChange('city')(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="state">
                State/Province
              </label>
              <input
                type="text"
                id="state"
                className="w-full p-2 sm:p-3 border border-gray-300 rounded-xl text-base placeholder:text-base placeholder-gray-400"
                placeholder="Enter state/province"
                value={values.state}
                onChange={(e) => handleChange('state')(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="postalCode">
                Postal Code
              </label>
              <input
                type="text"
                id="postalCode"
                className="w-full p-2 sm:p-3 border border-gray-300 rounded-xl text-base placeholder:text-base placeholder-gray-400"
                placeholder="Enter postal code"
                value={values.postalCode}
                onChange={(e) => handleChange('postalCode')(e.target.value)}
                required
              />
            </div>
          </div>
        </div>
        <div className="mt-4 sm:mt-4 flex justify-between">
          <button
            type="button"
            onClick={prevStep}
            className="mb-2 sm:mb-0 bg-gray-300 text-gray-700 py-2 px-6 rounded-xl hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            ← Previous
          </button>
          <button
            type="submit"
            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 sm:px-6 rounded-md flex items-center text-sm sm:text-base"
          >
            Next
            <svg className="w-4 h-4 sm:w-5 sm:h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

CompanyAddressInformation.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default CompanyAddressInformation;