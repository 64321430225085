import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const ProfileEducation = ({ education }) => {
  return (
    <div className="p-6 bg-white">
      <h2 className="text-2xl font-bold mb-4">Education</h2>
      <div className="space-y-6">
        {education && education.map((edu, index) => (
          <EducationItem 
            key={index}
            degree={edu.degree}
            school={edu.school}
            fieldOfStudy={edu.fieldofstudy}
            from={edu.from}
            to={edu.to}
            current={edu.current}
            description={edu.description}
          />
        ))}
      </div>
    </div>
  );
};

const EducationItem = ({ degree, school, fieldOfStudy, from, to, current, description }) => {
  const formatDate = (date) => moment(date).format('YYYY');
  const period = `${formatDate(from)} - ${current ? 'Present' : formatDate(to)}`;

  return (
    <div>
      <div className="flex justify-between items-start mb-2">
        <div>
          <h3 className="text-xl font-semibold">{degree} | {fieldOfStudy}</h3>
          <p className="text-blue-600 text-lg">{school}</p>
          {/* <p className="text-gray-700 text-lg mt-1"></p> */}
        </div>
        <span className="text-sm bg-blue-100 text-blue-800 px-3 py-1 rounded-full">{period}</span>
      </div>
      {description && <p className="text-gray-600 text-sm mt-2">{description}</p>}
    </div>
  );
};

ProfileEducation.propTypes = {
  education: PropTypes.arrayOf(
    PropTypes.shape({
      school: PropTypes.string.isRequired,
      degree: PropTypes.string.isRequired,
      fieldofstudy: PropTypes.string.isRequired,
      from: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      current: PropTypes.bool,
      description: PropTypes.string
    })
  )
};

EducationItem.propTypes = {
  degree: PropTypes.string.isRequired,
  school: PropTypes.string.isRequired,
  fieldOfStudy: PropTypes.string.isRequired,
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  current: PropTypes.bool,
  description: PropTypes.string
};

export default ProfileEducation;