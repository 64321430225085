import React from 'react';
import PropTypes from 'prop-types';

const ContactInformation = ({ prevStep, nextStep, handleChange, values }) => {
  const continueStep = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div className="bg-gray-50 p-4 sm:p-6 md:p-8 rounded-lg shadow-md max-w-4xl mx-auto">
      <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">Contact Information</h2>
      <form onSubmit={continueStep}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          <div className="col-span-1 sm:col-span-2 md:col-span-1">
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="contactName">
              Contact Person's Name
            </label>
            <input
              type="text"
              id="contactName"
              className="w-full p-2 sm:p-3 border border-gray-300 rounded-lg text-base placeholder:text-base placeholder-gray-400"
              placeholder="Enter the contact person's name"
              value={values.name}
              onChange={(e) => handleChange('name')(e.target.value)}
              required
            />
          </div>
          <div className="col-span-1 sm:col-span-2 md:col-span-1">
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="contactEmail">
              Contact Person's Email
            </label>
            <input
              type="email"
              id="contactEmail"
              className="w-full p-2 sm:p-3 border border-gray-300 rounded-lg text-base placeholder:text-base placeholder-gray-400"
              placeholder="Enter the contact person's email address"
              value={values.email}
              onChange={(e) => handleChange('email')(e.target.value)}
              required
            />
          </div>
          <div className="col-span-1 sm:col-span-2 md:col-span-1">
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="contactPhone">
              Contact Person's Phone Number
            </label>
            <input
              type="tel"
              id="contactPhone"
              className="w-full p-2 sm:p-3 border border-gray-300 rounded-lg text-base placeholder:text-base placeholder-gray-400"
              placeholder="Enter the contact person's phone number"
              value={values.phone}
              onChange={(e) => handleChange('phone')(e.target.value)}
              required
            />
          </div>
          <div className="col-span-1 sm:col-span-2 md:col-span-1">
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="contactJobTitle">
              Job Title of Contact Person
            </label>
            <input
              type="text"
              id="contactJobTitle"
              className="w-full p-2 sm:p-3 border border-gray-300 rounded-lg text-base placeholder:text-base placeholder-gray-400"
              placeholder="Enter the job title of the contact person"
              value={values.jobTitle}
              onChange={(e) => handleChange('jobTitle')(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mt-6 sm:mt-8 flex justify-between">
          <button
            type="button"
            onClick={prevStep}
            className="mb-2 sm:mb-0 bg-gray-300 text-gray-700 py-2 px-6 rounded-xl hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            ← Previous
          </button>
          <button
            type="submit"
            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-1 px-4 sm:px-6 rounded-xl flex items-center text-sm sm:text-base"
          >
            Next
            <svg className="w-4 h-4 sm:w-5 sm:h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

ContactInformation.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default ContactInformation;