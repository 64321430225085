import React from 'react';
import PropTypes from 'prop-types';

const CompanyProfileTop = ({ profile, onEditProfile }) => {
  return (
    <div className="rounded-lg p-4 md:p-6 mb-6 bg-white">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4">
        <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4 sm:mb-0">
          <div className="w-20 h-20 sm:w-24 sm:h-24 rounded-lg mr-0 sm:mr-4 mb-4 sm:mb-0 overflow-hidden">
            {profile?.hrDetails?.image?.url ? (
              <img 
                src={profile.hrDetails.image.url} 
                alt={profile.hrDetails.name} 
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full bg-indigo-600 flex items-center justify-center text-white text-2xl font-bold">
                {profile?.hrDetails?.name?.[0] || ''}
              </div>
            )}
          </div>
          <div>
            <h2 className="text-xl sm:text-2xl font-bold">{profile?.companyInfo?.name || 'Company Name'}</h2>
            <p className="text-indigo-600 text-base sm:text-lg">{profile?.companyInfo?.industry || 'Industry'}</p>
            <div className="flex flex-col sm:flex-row items-start sm:items-center text-sm text-gray-600 mt-1">
              <div className="flex items-center mb-1 sm:mb-0">
                <i className="fas fa-map-marker-alt mr-2"></i>
                <span>{profile?.companyAddress?.city || 'City'}, {profile?.companyAddress?.country || 'Country'}</span>
              </div>
              <div className="flex items-center sm:ml-4">
                <i className="fas fa-clock mr-2"></i>
                <span>Since: {new Date(profile?.date).toLocaleDateString()}</span>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={onEditProfile}
          className="bg-white text-blue-600 border border-blue-400 font-roboto font-medium py-1 px-4 rounded hover:bg-blue-50 hover:border-blue-500 hover:text-blue-700 w-full sm:w-auto"
          style={{ color: '#2563eb' }} 
        >
          Edit Profile
        </button>
      </div>
      
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {[
          { icon: 'fa-industry', label: 'Industry', value: profile?.companyInfo?.industry },
          { icon: 'fa-users', label: 'Company size', value: profile?.companyInfo?.size },
          { icon: 'fa-calendar', label: 'Founded in', value: '2011' },
          { icon: 'fa-phone', label: 'Phone', value: profile?.contactInfo?.phone },
          { icon: 'fa-envelope', label: 'Email', value: profile?.contactInfo?.email },
          { icon: 'fa-map-marker-alt', label: 'Location', value: `${profile?.companyAddress?.city}, ${profile?.companyAddress?.country}` },
        ].map((item, index) => (
          <div key={index} className="flex items-center">
            <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center mr-3 flex-shrink-0">
              <i className={`fas ${item.icon} text-blue-500`}></i>
            </div>
            <div className="flex-grow min-w-0">
              <p className="text-sm sm:text-base font-semibold text-gray-600 truncate">{item.label}</p>
              <p className="font-light text-sm sm:text-base truncate">{item.value || 'N/A'}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

CompanyProfileTop.propTypes = {
  profile: PropTypes.shape({
    companyInfo: PropTypes.shape({
      name: PropTypes.string,
      industry: PropTypes.string,
      size: PropTypes.string,
    }),
    companyAddress: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
    }),
    contactInfo: PropTypes.shape({
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
    hrDetails: PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    date: PropTypes.string,
  }),
  onEditProfile: PropTypes.func.isRequired,
};

export default CompanyProfileTop;