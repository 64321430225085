import React, { useState, useEffect } from 'react';
import { StarIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const companies = [
  { name: "Devyani International", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0Xs4FQolnzT7pCGYJDNSQ1UZqWALRAZST2Q&s", rating: 3.9, reviews: "1K+", tags: ["Food Processing", "Retail", "Public"], category: "manufacturing" },
  { name: "Uflex", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0Xs4FQolnzT7pCGYJDNSQ1UZqWALRAZST2Q&s", rating: 3.8, reviews: "1.4K+", tags: ["Packaging & Containers", "Printing / Packaging"], category: "manufacturing" },
  { name: "Dabur", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUHnluaTBu7rG3KmwINa8nEDAFWGCtqu1QdHZ8w0zE3xt7ECADGj_zerAzQavQkaZ03Ok&usqp=CAU", rating: 3.8, reviews: "2.4K+", tags: ["Indian MNC", "Fortune500", "Consumer goods"], category: "manufacturing" },
  { name: "Dow Chemical International", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkTbwtP1Z-wRaHiHgtuLB6Vv-tZ5nqrdYCRQ&s", rating: 4.2, reviews: "263", tags: ["Chemicals", "Fortune Global 500", "Foreign MNC"], category: "manufacturing" },
  { name: "MIOT International", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUHnluaTBu7rG3KmwINa8nEDAFWGCtqu1QdHZ8w0zE3xt7ECADGj_zerAzQavQkaZ03Ok&usqp=CAU", rating: 3.8, reviews: "224", tags: ["B2C", "Corporate", "Medical Services / Hospital"], category: "healthcare" },
  { name: "Encora", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkTbwtP1Z-wRaHiHgtuLB6Vv-tZ5nqrdYCRQ&s", rating: 3.9, reviews: "603", tags: ["B2B", "Private", "Service"], category: "it" },
  { name: "Lucknow Healthcity Trauma Center", logo: "https://img.freepik.com/free-vector/square-business-logo_23-2147503134.jpg", rating: 5.0, reviews: "19", tags: ["Medical Services / Hospital"], category: "healthcare" },
  { name: "Zydus Lifesciences", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSY499qfskNiRDlVcw6ILdx6P-52I84lZaAjTrL8XWvdyg01HYxWkukV4PiFWnkcBxFEtA&usqp=CAU", rating: 4.2, reviews: "5.5K+", tags: ["Public", "Service", "Pharmaceutical & Life Sciences"], category: "healthcare" },
  { name: "Zydus Lifesciences", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkTbwtP1Z-wRaHiHgtuLB6Vv-tZ5nqrdYCRQ&s", rating: 4.2, reviews: "5.5K+", tags: ["Public", "Service", "Pharmaceutical & Life Sciences"], category: "healthcare" },
  { name: "Zydus Lifesciences", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkTbwtP1Z-wRaHiHgtuLB6Vv-tZ5nqrdYCRQ&s", rating: 4.2, reviews: "5.5K+", tags: ["Public", "Service", "Pharmaceutical & Life Sciences"], category: "healthcare" },
  { name: "Zydus Lifesciences", logo: "https://img.freepik.com/free-vector/square-business-logo_23-2147503134.jpg", rating: 4.2, reviews: "5.5K+", tags: ["Public", "Service", "Pharmaceutical & Life Sciences"], category: "healthcare" },
  { name: "Zydus Lifesciences", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrZ5-4mdSQLXaG8ADji4VHHNTRwlqwu3hzpA&s", rating: 4.2, reviews: "5.5K+", tags: ["Public", "Service", "Pharmaceutical & Life Sciences"], category: "healthcare" },
  { name: "Zydus Lifesciences", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkTbwtP1Z-wRaHiHgtuLB6Vv-tZ5nqrdYCRQ&s", rating: 4.2, reviews: "5.5K+", tags: ["Public", "Service", "Pharmaceutical & Life Sciences"], category: "healthcare" },
  { name: "Zydus Lifesciences", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrZ5-4mdSQLXaG8ADji4VHHNTRwlqwu3hzpA&s", rating: 4.2, reviews: "5.5K+", tags: ["Public", "Service", "Pharmaceutical & Life Sciences"], category: "healthcare" },
  { name: "Zydus Lifesciences", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkTbwtP1Z-wRaHiHgtuLB6Vv-tZ5nqrdYCRQ&s", rating: 4.2, reviews: "5.5K+", tags: ["Public", "Service", "Pharmaceutical & Life Sciences"], category: "healthcare" },
  { name: "Zydus Lifesciences", logo: "https://img.freepik.com/free-vector/square-business-logo_23-2147503134.jpg", rating: 4.2, reviews: "5.5K+", tags: ["Public", "Service", "Pharmaceutical & Life Sciences"], category: "healthcare" },
  // Add more companies as needed
];

const filters = [
  { name: "All", value: "all" },
  { name: "IT Services", value: "it" },
  { name: "Technology", value: "tech" },
  { name: "Manufacturing & Production", value: "manufacturing" },
  { name: "Infrastructure, Transport & Real Estate", value: "infrastructure" },
  { name: "Healthcare & Life Sciences", value: "healthcare" },
];
const SponsoredCompanies = () => {
    const [activeFilter, setActiveFilter] = useState("all");
    const [filteredCompanies, setFilteredCompanies] = useState(companies);
    const [currentPage, setCurrentPage] = useState(0);
  
    useEffect(() => {
      if (activeFilter === "all") {
        setFilteredCompanies(companies);
      } else {
        setFilteredCompanies(companies.filter(company => company.category === activeFilter));
      }
      setCurrentPage(0);
    }, [activeFilter]);
  
    const totalPages = Math.ceil(filteredCompanies.length / 8);
  
    const handlePrev = () => {
      setCurrentPage((prev) => (prev > 0 ? prev - 1 : totalPages - 1));
    };
  
    const handleNext = () => {
      setCurrentPage((prev) => (prev < totalPages - 1 ? prev + 1 : 0));
    };
  
    return (
      <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-semibold text-gray-900 mb-8 text-center">Sponsored Companies</h1>
          
          <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Filter by Category</h2>
            <div className="flex flex-wrap gap-3">
              {filters.map((filter) => (
                <button
                  key={filter.value}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                    activeFilter === filter.value
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                  onClick={() => setActiveFilter(filter.value)}
                >
                  {filter.name}
                </button>
              ))}
            </div>
          </div>
  
          <div className="relative bg-white rounded-xl shadow-lg overflow-hidden">
            {filteredCompanies.length === 0 ? (
              <div className="p-6 text-center">
                <p className="text-xl font-semibold text-gray-700">No companies found for this category.</p>
                <p className="text-gray-500 mt-2">Please try another filter or check back later.</p>
              </div>
            ) : (
              <>
                <div 
                  className="flex transition-transform duration-300 ease-in-out"
                  style={{ transform: `translateX(-${currentPage * 100}%)` }}
                >
                  {Array.from({ length: totalPages }).map((_, pageIndex) => (
                    <div key={pageIndex} className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 p-6 w-full flex-shrink-0">
                      {filteredCompanies.slice(pageIndex * 8, (pageIndex + 1) * 8).map((company, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center transition-transform duration-200 hover:scale-105">
                          <img src={company.logo} alt={company.name} className="w-24 h-24 object-contain mb-4" />
                          <h3 className="font-bold text-lg text-gray-800 mb-2">{company.name}</h3>
                          <div className="flex items-center mb-3">
                            <StarIcon className="h-5 w-5 text-yellow-400 mr-1" />
                            <span className="font-semibold text-gray-700">{company.rating}</span>
                            <span className="text-gray-500 ml-1 text-base">({company.reviews} reviews)</span>
                          </div>
                          <div className="flex flex-wrap justify-center gap-2">
                            {company.tags.map((tag, tagIndex) => (
                              <span key={tagIndex} className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
                                {tag}
                              </span>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <button
                  className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg text-gray-800 hover:bg-gray-100 transition-colors duration-200"
                  onClick={handlePrev}
                >
                  <ChevronLeftIcon className="h-6 w-6" />
                </button>
                <button
                  className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg text-gray-800 hover:bg-gray-100 transition-colors duration-200"
                  onClick={handleNext}
                >
                  <ChevronRightIcon className="h-6 w-6" />
                </button>
              </>
            )}
          </div>
  
          {filteredCompanies.length > 0 && (
            <div className="mt-6 flex justify-center">
              <p className="text-gray-600">
                Page {currentPage + 1} of {totalPages}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };
  
  export default SponsoredCompanies;