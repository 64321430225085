import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Skills = ({ prevStep, nextStep, handleChange, values }) => {
    const [currentSkill, setCurrentSkill] = useState('');
    const [skills, setSkills] = useState(values || []);

    const addSkill = () => {
        if (currentSkill.trim() !== '' && !skills.includes(currentSkill.trim())) {
            const newSkills = [...skills, currentSkill.trim()];
            setSkills(newSkills);
            handleChange(newSkills);
            setCurrentSkill('');
        }
    };

    const removeSkill = (skillToRemove) => {
        const newSkills = skills.filter(skill => skill !== skillToRemove);
        setSkills(newSkills);
        handleChange(newSkills);
    };

    const continueStep = (e) => {
        e.preventDefault();
        nextStep();
    };

    return (
        <div className="bg-gray-100 p-4 sm:p-6 md:p-8 rounded-lg shadow-md max-w-4xl mx-auto">
            <h1 className="text-2xl font-bold mb-4">Set up your Profile</h1>
            <h2 className="text-xl font-semibold mb-4">Skills</h2>
            <form onSubmit={continueStep}>
                <div className="border border-gray-300 p-4 rounded-md mb-4">
                    <div className="mb-4">
                        <label htmlFor="skillInput" className="block text-sm font-medium text-gray-700 mb-1">Add Skills</label>
                        <input
                            id="skillInput"
                            type="text"
                            placeholder="Enter your skills"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={currentSkill}
                            onChange={(e) => setCurrentSkill(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && (e.preventDefault(), addSkill())}
                        />
                    </div>
                    <div className="flex flex-wrap gap-2 mb-6">
                        {skills.map((skill, index) => (
                            <div key={index} className="bg-gray-100 px-3 py-1 rounded-full flex items-center">
                                <span className="mr-2">{skill}</span>
                                <button
                                    type="button"
                                    onClick={() => removeSkill(skill)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex justify-between">
                    <button
                        type="button"
                        onClick={prevStep}
                        className="mb-2 sm:mb-0 bg-gray-300 text-gray-700 py-2 px-6 rounded-xl hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    >
                        ← Previous
                    </button>
                    <button
                        type="submit"
                        className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700"
                    >
                        Next →
                    </button>
                </div>
            </form>
        </div>
    );
};

Skills.propTypes = {
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Skills;