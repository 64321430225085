// components/jobseeker/AppliedJobs.js

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAppliedJobs } from '../../../actions/jobActions';
import { FaMapMarkerAlt, FaClock, FaBriefcase, FaBookmark } from 'react-icons/fa';

const AppliedJobs = ({ getAppliedJobs, job: { appliedJobs, loading } }) => {
  useEffect(() => {
    getAppliedJobs();
  }, [getAppliedJobs]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      {Array.isArray(appliedJobs) && appliedJobs.length > 0 ? (
        appliedJobs.map((job) => (
          <div key={job._id} className="bg-white rounded-xl shadow-md p-4 mb-4 flex flex-col sm:flex-row items-start sm:items-center">
            <div className="flex items-center mb-4 sm:mb-0 sm:mr-4">
              {job.companyLogo ? (
                <img src={job.companyLogo} alt={job.companyName} className="w-12 h-12 rounded-md object-cover" />
              ) : (
                <div className={`w-12 h-12 rounded-md flex items-center justify-center text-white font-bold text-xl`} style={{backgroundColor: job.companyColor || '#6366F1'}}>
                  {job.companyName[0].toUpperCase()}
                </div>
              )}
            </div>
            <div className="flex-grow">
              <h2 className="text-lg font-semibold text-gray-900">{job.jobTitle}</h2>
              <div className="flex flex-wrap items-center text-sm text-gray-500 mt-1">
                <div className="flex items-center mr-4">
                  <FaMapMarkerAlt className="mr-1" />
                  <span>{job.location}</span>
                </div>
                <div className="flex items-center">
                  <FaClock className="mr-1" />
                  <span>Posted on: {formatDate(job.postedDate)}</span>
                </div>
              </div>
              <div className="flex items-center mt-2">
                <span className={`px-2 py-1 rounded-full text-xs font-semibold ${job.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                  Status: {job.status}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-end mt-4 sm:mt-0">
              <span className="text-sm font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded flex items-center mb-2">
                <FaBriefcase className="mr-1" />
                Full time
              </span>
              <button className="bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-700 transition duration-300">
                View Job
              </button>
            </div>
            <FaBookmark className="text-yellow-500 ml-4 mt-4 sm:mt-0 cursor-pointer" />
          </div>
        ))
      ) : (
        <p>No applied jobs found.</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  job: state.job
});

export default connect(mapStateToProps, { getAppliedJobs })(AppliedJobs);