import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardAnalytics, getMonthlyStats,getDashboardCounts } from '../../actions/analyticsActions';

const AdminDashboardHome = () => {
  const dispatch = useDispatch();
  const { dashboardStats, monthlyStats, dashboardCounts, loading, error } = useSelector(state => state.analytics);

  useEffect(() => {
    dispatch(getDashboardAnalytics());
    dispatch(getMonthlyStats());
    dispatch(getDashboardCounts());
  }, [dispatch]);

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-red-500">Error: {error.msg}</div>
      </div>
    );
  }

  if (loading || !dashboardStats || !monthlyStats) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="bg-white p-6">
      <div className="flex justify-between items-center mb-6">
        <span className="text-xl font-semibold text-black">
          Welcome, <span className="capitalize text-blue-600">{dashboardStats.adminName || 'Admin'}</span>
        </span>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <DashboardCard 
          title="Total Users" 
          count={dashboardStats.totalUsers || 0} 
          icon="users" 
          color="bg-blue-100" 
          textColor="text-blue-500" 
        />
        <DashboardCard 
          title="Job Postings" 
          count={dashboardCounts?.totalJobs || 0} 
          icon="briefcase" 
          color="bg-green-100" 
          textColor="text-green-500" 
        />
        <DashboardCard 
          title="Applications" 
          count={dashboardCounts?.totalApplications || 0} 
          icon="file-alt" 
          color="bg-yellow-100" 
          textColor="text-yellow-500" 
        />
        <DashboardCard 
          title="Pending Approvals" 
          count={dashboardCounts?.pendingApprovals || 0} 
          icon="clock" 
          color="bg-purple-100" 
          textColor="text-purple-500" 
        />
      </div>

      <div className="bg-gray-50 rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4">Recent Activities</h2>
        <RecentActivitiesList activities={dashboardStats.recentActivities || []} />
      </div>
    </div>
  );
};

const DashboardCard = ({ title, count, icon, color, textColor }) => (
  <div className={`${color} rounded-lg p-4`}>
    <div className="flex items-center mb-2">
      <div className={`${color} p-2 rounded-lg mr-3`}>
        <i className={`fas fa-${icon} ${textColor} text-xl`}></i>
      </div>
      <div className={`${textColor} text-3xl font-bold`}>{count}</div>
    </div>
    <div className="text-gray-600 text-sm">{title}</div>
  </div>
);

const RecentActivitiesList = ({ activities }) => (
  <ul className="space-y-4">
    {activities.map((activity, index) => (
      <ActivityItem 
        key={activity.id || index}
        icon={getActivityIcon(activity.type)}
        text={activity.description}
        timestamp={activity.timestamp}
      />
    ))}
  </ul>
);

const ActivityItem = ({ icon, text, timestamp }) => (
  <li className="flex items-center justify-between">
    <div className="flex items-center space-x-3">
      <div className="bg-blue-100 p-2 rounded-lg">
        <i className={`fas fa-${icon} text-blue-500 text-sm`}></i>
      </div>
      <span className="text-sm">{text}</span>
    </div>
    {timestamp && (
      <span className="text-xs text-gray-500">
        {new Date(timestamp).toLocaleDateString()}
      </span>
    )}
  </li>
);

const getActivityIcon = (type) => {
  const icons = {
    USER_REGISTRATION: 'user-plus',
    JOB_POSTING: 'briefcase',
    APPLICATION: 'file-alt',
    JOB_APPROVAL: 'check-circle',
    DEFAULT: 'info-circle'
  };
  return icons[type] || icons.DEFAULT;
};

export default AdminDashboardHome;