import React from 'react';
import { FaTimes, FaDownload } from 'react-icons/fa';
import moment from 'moment';

const ApplicantProfileOverlay = ({ profile, onClose }) => {
  if (!profile) return null;

  const getCurrentOrMostRecentJob = (workExperiences) => {
    if (!workExperiences || workExperiences.length === 0) return null;
    
    const sortedExperiences = [...workExperiences].sort((a, b) => 
      moment(b.startDate).diff(moment(a.startDate))
    );

    return sortedExperiences.find(exp => !exp.endDate) || sortedExperiences[0];
  };

  const currentJob = getCurrentOrMostRecentJob(profile.workExperiences);

  const formatMemberSince = (date) => {
    if (!date) return 'Not specified';
    return moment(date).format('MMMM YYYY');
  };

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return `${age} years`;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto relative">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
          <FaTimes size={24} />
        </button>
        
        <div className="p-4 sm:p-6 bg-white shadow-sm rounded-lg">
          <h1 className="text-2xl sm:text-3xl font-bold mb-6">Applicant Profile</h1>
          <div className="flex flex-col sm:flex-row items-start sm:items-center">
            <div className="w-24 h-24 rounded-full mb-4 sm:mb-0 sm:mr-6 overflow-hidden">
              {profile.avatar?.url ? (
                <img 
                  src={profile.avatar.url} 
                  alt={`${profile.firstName} ${profile.lastName}`} 
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full bg-indigo-600 flex items-center justify-center text-white text-xl font-bold">
                  {profile.firstName[0]}
                </div>
              )}
            </div>
            <div className="flex-grow">
              <h2 className="text-xl sm:text-2xl font-semibold">{`${profile.firstName} ${profile.lastName}`}</h2>
              <p className="text-blue-600 text-lg">
                {currentJob ? `${currentJob.jobTitle} at ${currentJob.companyName}` : 'Job Title Not Specified'}
              </p>
              <div className="flex items-center text-gray-600 mt-2 text-base">
                <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                <span>{`${profile.address.city}, ${profile.address.country}`}</span>
              </div>
              <div className="flex items-center text-base text-gray-600 mt-1">
                <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>
                <span>Member since: {formatMemberSince(profile.date)}</span>
              </div>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-4">
            {profile.resume?.url ? (
                <a
                  href={profile.resume.url}
                  download
                  className="flex items-center justify-center text-base bg-white text-blue-600 border border-blue-400 font-roboto font-medium py-1 px-4 rounded-lg hover:bg-blue-50 hover:border-blue-500 hover:text-blue-700 transition shadow-md"
                  style={{ color: '#2563eb' }}
                >
                  <FaDownload className="mr-2" />
                  View Resume
                </a>
              ) : (
                <p className="text-gray-500">No resume available</p>
              )}

            </div>
          </div>
        </div>

        <div className="p-4 sm:p-6 bg-gray-50 rounded-lg shadow-sm mt-6">
          <h2 className="text-2xl font-bold mb-6">About</h2>
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-2/3 lg:pr-6 mb-6 lg:mb-0">
              <p className="text-gray-600">
                {profile.bio || 'No bio available'}
              </p>
            </div>
            <div className="w-full lg:w-1/3 space-y-4">
              <AboutItem icon="🏆" title="Experience" value={profile.isFresher ? 'Fresher' : `${profile.workExperiences.length} job(s)`} />
              <AboutItem icon="🎂" title="Age" value={profile.dateOfBirth ? calculateAge(profile.dateOfBirth) : 'Not specified'} />
              <AboutItem icon="👤" title="Gender" value={profile.gender || 'Not specified'} />
              <AboutItem icon="🎓" title="Education" value={profile.education.length > 0 ? profile.education[0].degree : 'Not specified'} />
            </div>
          </div>
        </div>

        <div className="p-6 bg-white">
          <h2 className="text-2xl font-bold mb-4">Education</h2>
          <div className="space-y-6">
            {profile.education.map((edu, index) => (
              <EducationItem 
                key={index}
                degree={edu.degree}
                school={edu.school}
                fieldOfStudy={edu.fieldofstudy}
                from={edu.from}
                to={edu.to}
                current={edu.current}
                description={edu.description}
              />
            ))}
          </div>
        </div>

        <div className="p-6 bg-gray-50">
          <h2 className="text-xl font-bold mb-4">Work and Experience</h2>
          {profile.isFresher ? (
            <FresherMessage />
          ) : profile.workExperiences && profile.workExperiences.length > 0 ? (
            <div className="space-y-4">
              {profile.workExperiences.map((exp, index) => (
                <ExperienceItem 
                  key={index}
                  jobTitle={exp.jobTitle}
                  companyName={exp.companyName}
                  startDate={exp.startDate}
                  endDate={exp.endDate}
                  responsibilities={exp.responsibilities}
                />
              ))}
            </div>
          ) : (
            <p className="text-gray-600">No work experience added yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

const AboutItem = ({ icon, title, value }) => (
  <div className="flex items-center">
    <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mr-4 text-xl">
      {icon}
    </div>
    <div>
      <h3 className="font-semibold text-lg">{title}:</h3>
      <p className="text-gray-600">{value}</p>
    </div>
  </div>
);

const EducationItem = ({ degree, school, fieldOfStudy, from, to, current, description }) => {
  const formatDate = (date) => moment(date).format('YYYY');
  const period = `${formatDate(from)} - ${current ? 'Present' : formatDate(to)}`;

  return (
    <div>
      <div className="flex justify-between items-start mb-2">
        <div>
          <h3 className="text-xl font-semibold">{degree} | {fieldOfStudy}</h3>
          <p className="text-blue-600 text-lg">{school}</p>
        </div>
        <span className="text-sm bg-blue-100 text-blue-800 px-3 py-1 rounded-full">{period}</span>
      </div>
      {description && <p className="text-gray-600 text-sm mt-2">{description}</p>}
    </div>
  );
};

const ExperienceItem = ({ jobTitle, companyName, startDate, endDate, responsibilities }) => {
  const formatDate = (date) => moment(date).format('MMM YYYY');
  const period = `${formatDate(startDate)} - ${endDate ? formatDate(endDate) : 'Present'}`;

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-lg font-semibold">{jobTitle}</h3>
        <span className="text-sm bg-blue-100 text-blue-800 px-3 py-1 rounded-full">{period}</span>
      </div>
      <p className="text-blue-600 mb-2">{companyName}</p>
      {responsibilities && <p className="text-gray-600 text-sm">{responsibilities}</p>}
    </div>
  );
};

const FresherMessage = () => (
  <div className="bg-green-100 border-l-4 border-green-400 text-green-700 p-4 rounded">
    <p className="font-bold text-base">New to the workforce</p>
    <p className="text-base">This applicant is just starting their career journey.</p>
  </div>
);

export default ApplicantProfileOverlay;