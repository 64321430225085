// actions/userActions.js
import axios from 'axios';
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE
} from './types';

export const fetchUsers = () => async (dispatch) => {
  dispatch({ type: FETCH_USERS_REQUEST });
  
  try {
      const token = localStorage.getItem('token');
      const config = {
          headers: {
              'x-auth-token': token
          },
          timeout: 10000  
      };

      const [jobSeekersRes, employersRes] = await Promise.all([
          axios.get('/api/admin/jobseekers', config),
          axios.get('/api/admin/employers', config)
      ]);

      const jobSeekers = (jobSeekersRes.data || []).map(user => ({
          ...user,
          role: 'Job Seeker',
          status: user.status || 'Active',
          avatar: user.avatar || null
      }));

      const employers = (employersRes.data || []).map(user => ({
          ...user,
          role: 'Employer',
          status: 'Active'
      }));

      dispatch({
          type: FETCH_USERS_SUCCESS,
          payload: [...jobSeekers, ...employers]
      });
  } catch (error) {
      console.error('Fetch users error:', error);
      dispatch({
          type: FETCH_USERS_FAILURE,
          payload: error.response?.data?.msg || 'Error fetching users'
      });
  }
};
export const deleteUser = (userId, userType) => async (dispatch) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'x-auth-token': token
        }
      };
  
      await axios.delete(`/api/admin/${userType}/${userId}`, config);
  
      // Refresh the users list after deletion
      dispatch(fetchUsers());
    } catch (error) {
      dispatch({
        type: FETCH_USERS_FAILURE,
        payload: error.response?.data?.msg || 'Error deleting user'
      });
    }
  };

  export const updateUser = (userId, userType, userData) => async (dispatch) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json'
        }
      };
  
      await axios.put(`/api/admin/${userType}/${userId}`, userData, config);
      dispatch(fetchUsers());
    } catch (error) {
      dispatch({
        type: FETCH_USERS_FAILURE,
        payload: error.response?.data?.msg || 'Error updating user'
      });
    }
  };