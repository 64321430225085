import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { getDashboardAnalytics, getMonthlyStats } from '../../actions/analyticsActions';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      mode: 'index',
      intersect: false,
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        drawBorder: false,
      }
    },
    x: {
      grid: {
        display: false,
      }
    }
  }
};

const ReportsAnalytics = () => {
  const dispatch = useDispatch();
  const { dashboardStats, monthlyStats, loading, error } = useSelector(state => state.analytics);
  
  useEffect(() => {
    dispatch(getDashboardAnalytics());
    dispatch(getMonthlyStats());
  }, [dispatch]);

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-red-500">Error: {error.msg}</div>
      </div>
    );
  }

  if (loading || !dashboardStats || !monthlyStats) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const monthlyUserData = {
    labels: monthlyStats.monthlyUsers.map(item => `${item._id.month}/${item._id.year}`),
    datasets: [{
      label: 'User Registrations',
      data: monthlyStats.monthlyUsers.map(item => item.count),
      fill: true,
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.4
    }]
  };

  const categoryData = {
    labels: monthlyStats.categoryApplications.map(item => item._id),
    datasets: [{
      label: 'Applications',
      data: monthlyStats.categoryApplications.map(item => item.count),
      backgroundColor: 'rgba(54, 162, 235, 0.7)',
      borderRadius: 6,
    }]
  };

  return (
    <div className="bg-gray-50 min-h-screen p-6">
      <div className="max-w-7xl mx-auto space-y-6">
        <div className="bg-white rounded-xl shadow-sm p-6">
          <h2 className="text-3xl font-semibold text-gray-800 mb-6">Dashboard Overview</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <AnalyticsCard 
              title="Total Users" 
              value={dashboardStats.totalUsers.toLocaleString()} 
              icon="fas fa-users" 
              color="bg-gradient-to-r from-blue-500 to-blue-600" 
            />
            <AnalyticsCard 
              title="Active Jobs" 
              value={dashboardStats.activeJobs.toLocaleString()} 
              icon="fas fa-briefcase" 
              color="bg-gradient-to-r from-green-500 to-green-600" 
            />
            <AnalyticsCard 
              title="Monthly Applications" 
              value={dashboardStats.monthlyApplications.toLocaleString()} 
              icon="fas fa-file-alt" 
              color="bg-gradient-to-r from-yellow-500 to-yellow-600" 
            />
            <AnalyticsCard 
              title="Approved Companies" 
              value={dashboardStats.approvedCompanies.toLocaleString()} 
              icon="fas fa-building" 
              color="bg-gradient-to-r from-purple-500 to-purple-600" 
            />
            <AnalyticsCard 
              title="Application Rate" 
              value={`${dashboardStats.averageApplicationRate}%`} 
              icon="fas fa-chart-line" 
              color="bg-gradient-to-r from-red-500 to-red-600" 
            />
            <AnalyticsCard 
              title="User Growth" 
              value={`${dashboardStats.userGrowthRate}%`} 
              icon="fas fa-user-plus" 
              color="bg-gradient-to-r from-indigo-500 to-indigo-600" 
            />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">User Growth Trend</h3>
            <div className="h-[400px]">
              <Line options={chartOptions} data={monthlyUserData} />
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Applications by Category</h3>
            <div className="h-[400px]">
              <Bar options={chartOptions} data={categoryData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AnalyticsCard = ({ title, value, icon, color }) => (
  <div className={`${color} rounded-xl shadow-sm p-6 transition-transform duration-200 hover:scale-105`}>
    <div className="flex items-center justify-between text-white">
      <div>
        <p className="text-sm font-medium uppercase tracking-wider opacity-90">{title}</p>
        <p className="text-3xl font-bold mt-2">{value}</p>
      </div>
      <div className="bg-white/10 rounded-lg p-3">
        <i className={`${icon} text-2xl opacity-90`}></i>
      </div>
    </div>
  </div>
);

export default ReportsAnalytics;