import { combineReducers } from "redux";
import alert from './alert'
import auth from './auth'
import profile from './profile'
import post from './post'
import job from './job'
import interview from "./interview";
import scoreReducer from "./score";
import employerProfile from "./employerProfile"
import employerProfileReducer from './employerProfileReducer';
import jobSeekerProfile from './jobSeekerProfile';
import users from './users';
import adminJobReducer from './adminJobReducer';
import applicantManagement from './applicantManagement';
import analyticsReducer from './analyticsReducer';


export default combineReducers({
    alert,
    auth,
    profile,
    post,
    job,
    interview,
    score:scoreReducer,
    employerProfile,
    employerProfileReducer,
    jobSeekerProfile,
    users,
    adminJobs: adminJobReducer ,
    applicantManagement,
    analytics: analyticsReducer,
    
})