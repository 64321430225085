import React from 'react';
import { CalendarIcon, ClockIcon } from '@heroicons/react/outline';

const dummyJobs = [
  {
    id: 1,
    jobTitle: "IT Security Specialist",
    company: "HDFC",
    companyLogo: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
    jobType: "Full Time",
    offeredSalary: "INR1,300,000 - INR1,700,000 / YEAR",
    location: "Mumbai, Maharashtra",
    applicationDeadline: "2023-09-30",
    keySkills: ["Cybersecurity", "Network Security", "Risk Management"],
    jobDescription: "IT Security Specialist at HDFC responsible for implementing and maintaining security measures to protect computer networks and systems.",
  },
  {
    id: 2,
    jobTitle: "Applications Support Analyst",
    company: "Citi",
    companyLogo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfUlGcxUmqLQ08e_4msz7RwXoNtq6yR_f_PA&s",
    jobType: "Full Time",
    offeredSalary: "INR800,000 - INR1,800,000 / YEAR",
    location: "New York, NY",
    applicationDeadline: "2023-10-15",
    keySkills: ["Technical Support", "Problem Solving", "SQL"],
    jobDescription: "Applications Support Analyst at Citi to provide technical assistance and support for critical banking applications.",
  },
  {
    id: 3,
    jobTitle: "Assistant Store Keeper",
    company: "Sodexo",
    companyLogo: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
    jobType: "Full Time",
    offeredSalary: "INR15,000 - INR19,000 / MONTH",
    location: "Issy-Les-Moulineaux, France",
    applicationDeadline: "2023-09-20",
    keySkills: ["Inventory Management", "Record Keeping", "Logistics"],
    jobDescription: "Assistant Store Keeper at Sodexo to manage inventory, maintain records, and assist in daily store operations.",
  },
  {
    id: 4,
    jobTitle: "Machine Learning Engineer",
    company: "Adobe",
    companyLogo: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
    jobType: "Full Time",
    offeredSalary: "INR700,000 - INR4,300,000 / YEAR",
    location: "Noida, Uttar Pradesh",
    applicationDeadline: "2023-10-31",
    keySkills: ["Machine Learning", "Python", "TensorFlow", "Data Analysis"],
    jobDescription: "Machine Learning Engineer at Adobe to develop and implement ML models and algorithms for creative software solutions.",
  },
  {
    id: 5,
    jobTitle: "Network Services",
    company: "Kyndryl",
    companyLogo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfUlGcxUmqLQ08e_4msz7RwXoNtq6yR_f_PA&s",
    jobType: "Full Time",
    offeredSalary: "INR500,000 - INR700,000 / YEAR",
    location: "Noida, Uttar Pradesh",
    applicationDeadline: "2023-10-10",
    keySkills: ["Networking", "Troubleshooting", "Cisco", "Network Security"],
    jobDescription: "Network Services professional at Kyndryl to manage and maintain client network infrastructure and provide technical support.",
  },
  {
    id: 6,
    jobTitle: "Production Support Analyst",
    company: "Oracle",
    companyLogo: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
    jobType: "Full Time",
    offeredSalary: "INR550,000 - INR750,000 / YEAR",
    location: "Austin, TX",
    applicationDeadline: "2023-11-15",
    keySkills: ["SQL", "Database Management", "Troubleshooting", "Customer Support"],
    jobDescription: "Production Support Analyst at Oracle to provide technical support for database products and ensure smooth operation of client systems.",
  }
];

const FeaturedJobs = () => {
    return (
      <div className="bg-gray-100 py-16"   style={{
        backgroundImage: `
          linear-gradient(
            to bottom, 
            rgba(255, 255, 255, 0.7), 
            rgba(255, 255, 255, 0.7)
          ),
          url('https://t3.ftcdn.net/jpg/03/61/47/30/360_F_361473045_NNRY3V24e491CMDQXYBxxQOSvQklx3xB.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
       
      }}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-sm font-semibold text-blue-600 uppercase tracking-wide">Jobs Category</h2>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 sm:text-5xl">Featured Jobs</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {dummyJobs.map((job) => (
              <JobCard key={job.id} job={job} />
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  const JobCard = ({ job }) => {
    return (
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <div className="flex items-center justify-between mb-4">
            <img src={job.companyLogo} alt={job.company} className="w-10 h-10 object-contain" />
            <span className="text-sm text-gray-500">{job.jobType}</span>
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-1">{job.jobTitle}</h3>
          <p className="text-sm text-blue-600 mb-2">{job.company}</p>
          <p className="text-sm text-green-600 font-medium mb-2">{job.offeredSalary}</p>
          <p className="text-sm text-gray-600 mb-3">{job.location}</p>
          <div className="flex items-center text-sm text-gray-500 mb-3">
            <CalendarIcon className="h-4 w-4 mr-2" />
            <span>Apply before {new Date(job.applicationDeadline).toLocaleDateString()}</span>
          </div>
          <div className="flex flex-wrap gap-2 mb-3">
            {job.keySkills.map((skill, index) => (
              <span key={index} className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
                {skill}
              </span>
            ))}
            {job.keySkills.length > 3 && (
              <span className="bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded">
                +{job.keySkills.length - 3} more
              </span>
            )}
          </div>
          <p className="text-sm text-gray-600 mb-4 line-clamp-2">{job.jobDescription}</p>
          <div className="flex items-center justify-between">
            <div className="flex items-center text-sm text-gray-500">
              <ClockIcon className="h-4 w-4 mr-2" />
              <span>Posted 2 weeks ago</span>
            </div>
            <button className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded text-sm transition duration-300">
              Apply Job
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default FeaturedJobs;