import React, { useState } from 'react';
import PropTypes from 'prop-types';

const PersonalInfo = ({ prevStep, nextStep, handleChange, values, handleSubmit }) => {
    const [imagePreview, setImagePreview] = useState(null);

    const continueStep = (e) => {
        e.preventDefault();
        nextStep();
    };

    const handlePersonalInfoChange = (field) => (e) => {
        handleChange(field)(e.target.value);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file.size > 5 * 1024 * 1024) { // 5MB limit
          alert('File size should not exceed 5MB');
          return;
        }
        setFileToBase(file);
      };
    
      const setFileToBase = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          handleChange('avatar')(reader.result);
          setImagePreview(reader.result);
        };
      };

    return (
        <div className="bg-gray-100 p-4 sm:p-6 md:p-8 rounded-lg shadow-md max-w-4xl mx-auto">
            <h2 className="text-2xl font-semibold mb-4">Personal Information</h2>
            <form onSubmit={continueStep} className="space-y-6">
            <div className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer relative overflow-hidden">
          {imagePreview ? (
            <img src={imagePreview} alt="HR" className="w-full h-full object-cover" />
          ) : (
            <div className="text-center">
              <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p className="mt-1 text-sm text-gray-600">Upload Profile Photo</p>
            </div>
          )}
          <input
            type="file"
            accept=".png,.jpg,.jpeg"
            onChange={handleImageUpload}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          />
        </div>
                <p className="text-sm text-gray-500 text-start">To upload file size is (Max 5Mb) and allowed file types are (.png, .jpg)</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label htmlFor="firstName" className="block text-sm font-semibold text-gray-700 mb-1">First Name</label>
                        <input
                            type="text"
                            id="firstName"
                            placeholder="Enter your first name"
                            onChange={handlePersonalInfoChange('firstName')}
                            value={values.firstName}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="lastName" className="block text-sm font-semibold text-gray-700 mb-1">Last Name</label>
                        <input
                            type="text"
                            id="lastName"
                            placeholder="Enter your last name"
                            onChange={handlePersonalInfoChange('lastName')}
                            value={values.lastName}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="phone" className="block text-sm font-semibold text-gray-700 mb-1">Phone Number</label>
                        <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                                +91
                            </span>
                            <input
                                type="tel"
                                id="phone"
                                placeholder="Enter your phone number"
                                onChange={handlePersonalInfoChange('phone')}
                                value={values.phone}
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-semibold text-gray-700 mb-1">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="Enter your email address"
                            onChange={handlePersonalInfoChange('email')}
                            value={values.email}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="address1" className="block text-sm font-semibold text-gray-700 mb-1">Address line 1</label>
                    <input
                        type="text"
                        id="address1"
                        placeholder="Enter main address"
                        onChange={handlePersonalInfoChange('address1')}
                        value={values.address1}
                        className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label htmlFor="address2" className="block text-sm font-semibold text-gray-700 mb-1">Address line 2</label>
                    <input
                        type="text"
                        id="address2"
                        placeholder="Enter additional details/address"
                        onChange={handlePersonalInfoChange('address2')}
                        value={values.address2}
                        className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label htmlFor="city" className="block text-sm font-semibold text-gray-700 mb-1">City</label>
                        <input
                            type="text"
                            id="city"
                            placeholder="Enter city"
                            onChange={handlePersonalInfoChange('city')}
                            value={values.city}
                            className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="country" className="block text-sm font-semibold text-gray-700 mb-1">Country</label>
                        <input
                            type="text"
                            id="country"
                            placeholder="Enter country"
                            onChange={handlePersonalInfoChange('country')}
                            value={values.country}
                            className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                </div>

                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="bg-blue-600 text-white py-1 px-6 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        Next →
                    </button>
                </div>
            </form>
        </div>
    );
};

PersonalInfo.propTypes = {
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
};

export default PersonalInfo;
