import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAppliedJobs } from '../../../actions/jobActions';
import { FaBriefcase, FaBookmark, FaUserTie, FaEye, FaSpinner } from 'react-icons/fa';

const JobSeekerDashboardHome = ({ 
  jobSeeker, 
  profile, 
  appliedJobs, 
  appliedJobsCount,
  getAppliedJobs,
  loading 
}) => {
  const [isProfileLoaded, setIsProfileLoaded] = useState(false);

  useEffect(() => {
    getAppliedJobs();
  }, [getAppliedJobs]);

  useEffect(() => {
    // Set profile loaded after initial check
    if (profile !== undefined) {
      setIsProfileLoaded(true);
    }
  }, [profile]);

  if (loading && !isProfileLoaded) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-blue-600 text-3xl" />
      </div>
    );
  }

  const dashboardCards = [
    { 
      title: "Applied Jobs", 
      count: appliedJobsCount || 0, 
      icon: <FaBriefcase />, 
      color: "bg-blue-100", 
      textColor: "text-blue-500" 
    },
    { 
      title: "Saved Jobs", 
      count: 8, 
      icon: <FaBookmark />, 
      color: "bg-green-100", 
      textColor: "text-green-500" 
    },
    { 
      title: "Interviews", 
      count: 3, 
      icon: <FaUserTie />, 
      color: "bg-yellow-100", 
      textColor: "text-yellow-500" 
    },
    { 
      title: "Profile Views", 
      count: 42, 
      icon: <FaEye />, 
      color: "bg-purple-100", 
      textColor: "text-purple-500" 
    }
  ];

  return (
    <div className="bg-white p-6 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        {jobSeeker && (
          <div className="flex flex-col">
            <span className="text-xl font-semibold text-black">
              Welcome, <span className="capitalize text-blue-600">{jobSeeker.name}</span>
            </span>
            <span className="text-sm text-gray-500">
              {new Date().toLocaleDateString('en-US', { 
                weekday: 'long', 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric' 
              })}
            </span>
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        {dashboardCards.map((card, index) => (
          <DashboardCard key={index} {...card} />
        ))}
      </div>

      {isProfileLoaded && profile === null && (
        <div className="bg-blue-50 border-l-4 border-blue-500 rounded-lg p-6 mb-6 transition-all duration-300 ease-in-out">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-lg font-medium text-blue-800">Complete Your Profile</h3>
              <div className="mt-2 text-blue-700">
                <p className="text-sm">Create your profile to unlock all features and apply for jobs.</p>
                <button className="mt-3 bg-blue-600 text-white px-4 py-2 rounded-md text-sm hover:bg-blue-700 transition duration-300">
                  Create Profile
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-gray-50 rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4">Recent Job Applications</h2>
        <RecentApplicationsList appliedJobs={appliedJobs} loading={loading} />
      </div>
    </div>
  );
};

const DashboardCard = ({ title, count, icon, color, textColor }) => (
  <div className={`${color} rounded-lg p-4 transform hover:scale-105 transition-transform duration-300 cursor-pointer`}>
    <div className="flex items-center justify-between mb-2">
      <div className={`${textColor} text-3xl font-bold`}>{count}</div>
      <div className={`${color} p-2 rounded-lg`}>
        <span className={`${textColor} text-xl`}>{icon}</span>
      </div>
    </div>
    <div className="text-gray-600 text-sm font-medium">{title}</div>
  </div>
);

const RecentApplicationsList = ({ appliedJobs, loading }) => {
  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <FaSpinner className="animate-spin text-blue-600 text-xl" />
      </div>
    );
  }

  if (!appliedJobs || appliedJobs.length === 0) {
    return (
      <div className="text-center py-4 text-gray-500">
        No recent job applications
      </div>
    );
  }

  return (
    <ul className="space-y-4">
      {appliedJobs.slice(0, 4).map((job, index) => (
        <li 
          key={index} 
          className="flex items-center space-x-3 p-3 hover:bg-gray-100 rounded-lg transition-colors duration-300"
        >
          <div className="bg-blue-100 p-2 rounded-lg">
            <FaBriefcase className="text-blue-500 text-sm" />
          </div>
          <div className="flex flex-col">
            <span className="text-sm">
              Applied for <span className="text-blue-500 font-medium">{job.jobTitle}</span>
            </span>
            <span className="text-xs text-gray-500">{job.location}</span>
          </div>
        </li>
      ))}
    </ul>
  );
};

JobSeekerDashboardHome.propTypes = {
  jobSeeker: PropTypes.object,
  profile: PropTypes.object,
  appliedJobs: PropTypes.array.isRequired,
  appliedJobsCount: PropTypes.number.isRequired,
  getAppliedJobs: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  jobSeeker: state.auth.user,
  profile: state.profile.profile,
  appliedJobs: state.job.appliedJobs,
  appliedJobsCount: state.job.appliedJobsCount,
  loading: state.profile.loading || state.job.loading
});

export default connect(mapStateToProps, { getAppliedJobs })(JobSeekerDashboardHome);