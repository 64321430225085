import React from 'react';
import { NavLink } from 'react-router-dom';

function CompanySidebar({ isOpen, setIsOpen }) {
  return (
    <>
      <div
        className={`fixed inset-0 bg-white bg-opacity-75 z-20 md:hidden transition-opacity duration-300 ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setIsOpen(false)}
      ></div>
     <aside
  className={`fixed top-0 left-0 z-40 w-64 h-screen pt-16 transition-transform duration-300 transform ${
    isOpen ? 'translate-x-0' : '-translate-x-full'
  } md:translate-x-0 bg-white shadow-lg`}
>
        <div className="h-full px-4 py-6 overflow-y-auto">
          <ul className="space-y-4">
          <SidebarItem to="/company-dashboard/" icon="fas fa-th-large" text="Dashboard" highlight={true} />
          <SidebarItem to="/company-dashboard/profile" icon="fas fa-building" text="Company Profile" />
          <SidebarItem to="/company-dashboard/post-job" icon="fas fa-plus-circle" text="Post a new Job" />
          <SidebarItem to="/company-dashboard/manage-jobs" icon="fas fa-tasks" text="Manage Jobs" />
          <SidebarItem to="/company-dashboard/applicants" icon="fas fa-users" text="Applicants" />
          <SidebarItem to="/company-dashboard/reset-password" icon="fas fa-key" text="Reset Password" />
          <SidebarItem to="/company-dashboard/logout" icon="fas fa-key" text="Logout" />


          </ul>
        </div>
      </aside>
    </>
  );
}

const SidebarItem = ({ to, icon, text, highlight }) => (
  <li>
  <NavLink
    to={to}
    className={({ isActive }) =>
      `flex items-center font-extralight p-2 rounded-lg transition duration-75 ${
        isActive || highlight ? 'bg-blue-50 text-blue-500' : 'text-gray-500 hover:bg-gray-100'
      }`
    }
  >
    {({ isActive }) => (
      <>
        <i
          className={`${icon} w-5 h-5 ${
            isActive || highlight ? 'text-blue-500' : 'text-gray-400'
          }`}
        ></i>
        <span className="ml-3 text-lg font-extralight">{text}</span>
      </>
    )}
  </NavLink>
</li>
);

export default CompanySidebar;