// actions/adminJobActions.js
import axios from 'axios';
import { setAlert } from './alert';

import {
    GET_ADMIN_JOBS,
    UPDATE_JOB_STATUS,
    ADMIN_JOB_ERROR,
    SET_LOADING,
    UPDATE_JOB_DETAILS,
    DELETE_JOB,
    BULK_UPDATE_JOB_STATUS
} from './types';

// Get all jobs for admin
export const getAdminJobs = () => async dispatch => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get('/api/admin/jobs');

    dispatch({
      type: GET_ADMIN_JOBS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ADMIN_JOB_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update job status (approve/reject)
export const updateJobStatus = (jobId, status, rejectionReason = '') => async dispatch => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  
      const body = { status };
      if (status === 'rejected') {
        body.rejectionReason = rejectionReason;
      }
  
      const res = await axios.put(`/api/admin/jobs/${jobId}/status`, body, config);
  
      if (status === 'rejected') {
        dispatch({
          type: DELETE_JOB,
          payload: jobId
        });
        dispatch(setAlert('Job rejected and deleted', 'success'));
      } else {
        dispatch({
          type: UPDATE_JOB_STATUS,
          payload: res.data
        });
        dispatch(setAlert(`Job ${status}`, 'success'));
      }
      dispatch(getAdminJobs()); 
    } catch (err) {
      dispatch({
        type: ADMIN_JOB_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };

// actions/adminJobActions.js
export const updateJobDetails = (jobId, jobData) => async dispatch => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  
      const res = await axios.put(`/api/admin/jobs/${jobId}`, jobData, config);
  
      dispatch({
        type: UPDATE_JOB_DETAILS,
        payload: res.data
      });
  
      dispatch(setAlert('Job updated successfully', 'success'));
      dispatch(getAdminJobs()); 
    } catch (err) {
      dispatch({
        type: ADMIN_JOB_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };

  // action for bulk status update
export const updateMultipleJobStatus = (jobIds, status, rejectionReason = '') => async dispatch => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  
      const body = { jobIds, status };
      if (status === 'rejected') {
        body.rejectionReason = rejectionReason;
      }
  
      const res = await axios.put(`/api/admin/jobs/bulk-status-update`, body, config);
  
      dispatch({
        type: BULK_UPDATE_JOB_STATUS,
        payload: res.data
      });
  
      dispatch(setAlert(`${jobIds.length} jobs ${status} successfully`, 'success'));
      dispatch(getAdminJobs()); 
    } catch (err) {
      dispatch({
        type: ADMIN_JOB_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };