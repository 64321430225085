import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchCurrentResume, updateResume } from '../../../actions/jobseekerProfile';
import ErrorBoundary from './ErrorBoundary';

const UploadResume = ({ fetchCurrentResume, updateResume, currentResume, loading }) => {
  const [file, setFile] = useState(null);

  useEffect(() => {
    fetchCurrentResume();
  }, [fetchCurrentResume]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size <= 5 * 1024 * 1024) {
      setFile(selectedFile);
    } else {
      alert('Please select a file smaller than 5MB');
    }
  };

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('resume', file);
      await updateResume(formData);
      setFile(null);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ErrorBoundary>
    <div className="max-w-6xl mx-auto px-4 py-8 bg-white rounded-lg">
      <div className="p-8">
        <h2 className="text-2xl font-bold mb-6">Resume</h2>
        {currentResume && (
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Current Resume</h3>
            <a href={currentResume.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
              View Current Resume
            </a>
          </div>
        )}
        <div className="max-w-3xl mx-auto px-4 py-8 bg-white rounded-lg">
          <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
            <p className="text-gray-600 mb-4">To upload file size is (Max 5Mb) and allowed file types are (.doc, .docx, .pdf)</p>
            <label htmlFor="resume-upload" className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-block">
              {file ? file.name : 'Select Resume'}
              <input
                id="resume-upload"
                type="file"
                className="hidden"
                accept=".doc,.docx,.pdf"
                onChange={handleFileChange}
              />
            </label>
          </div>
          {file && (
            <div className="mt-4 text-center">
              <button
                onClick={handleUpload}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
              >
                Upload
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
    </ErrorBoundary>
  );
};

UploadResume.propTypes = {
  fetchCurrentResume: PropTypes.func.isRequired,
  updateResume: PropTypes.func.isRequired,
  currentResume: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  currentResume: state.profile.currentResume,
  loading: state.profile.loading
});
export default connect(mapStateToProps, { fetchCurrentResume, updateResume })(UploadResume);