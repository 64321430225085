import React, { useState, useEffect } from 'react';
import { XIcon, PlusIcon } from '@heroicons/react/outline';
import { connect } from 'react-redux';
import { updateJobseekerProfile } from '../../../actions/jobseekerProfile';

const EditJobseekerProfile = ({ profile, onClose, updateJobseekerProfile }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: {
      street: '',
      city: '',
      state: '',
      country: '',
      zipCode: ''
    },
    dateOfBirth: '',
    gender: '',
    education: [],
    workExperiences: [],
    skills: [],
    isFresher: false,
    avatar: { url: '' },
    resume: { url: '' }
  });

  useEffect(() => {
    if (profile) {
      setFormData({
        ...profile,
        avatar: profile.avatar ? profile.avatar : { url: '' },
        resume: profile.resume ? profile.resume : { url: '' }
      });
    }
  }, [profile]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleAddressChange = (e) => {
    setFormData({
      ...formData,
      address: {
        ...formData.address,
        [e.target.name]: e.target.value
      }
    });
  };
  const handleViewResume = () => {
    if (formData.resume && formData.resume.url) {
      window.open(formData.resume.url, '_blank');
    } else {
      alert('No resume uploaded yet.');
    }
  };
  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        alert('File size should not exceed 5MB');
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          [type]: { url: reader.result }
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEducationChange = (index, field, value) => {
    const newEducation = [...formData.education];
    newEducation[index][field] = value;
    setFormData({ ...formData, education: newEducation });
  };

  const handleWorkExperienceChange = (index, field, value) => {
    const newWorkExperiences = [...formData.workExperiences];
    newWorkExperiences[index][field] = value;
    setFormData({ ...formData, workExperiences: newWorkExperiences });
  };

  const addEducation = () => {
    setFormData({
      ...formData,
      education: [...formData.education, { school: '', degree: '', fieldofstudy: '', from: '', to: '', current: false, description: '' }]
    });
  };

  const addWorkExperience = () => {
    setFormData({
      ...formData,
      workExperiences: [...formData.workExperiences, { jobTitle: '', companyName: '', startDate: '', endDate: '', responsibilities: '' }]
    });
  };

  const removeEducation = (index) => {
    const newEducation = formData.education.filter((_, i) => i !== index);
    setFormData({ ...formData, education: newEducation });
  };

  const removeWorkExperience = (index) => {
    const newWorkExperiences = formData.workExperiences.filter((_, i) => i !== index);
    setFormData({ ...formData, workExperiences: newWorkExperiences });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateJobseekerProfile(formData);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-100 rounded-lg shadow-xl w-full max-w-4xl mx-4 my-8 overflow-y-auto max-h-[90vh]">
        <div className="p-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-3xl font-bold">Edit Profile</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <XIcon className="w-6 h-6" />
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="space-y-6">
              {/* Personal Details */}
              <div>
                <h3 className="text-xl font-semibold mb-3">Personal Details</h3>
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="date"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>

              {/* Address */}
              <div>
                <h3 className="text-xl font-semibold mb-3">Address</h3>
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    name="street"
                    value={formData.address.street}
                    onChange={handleAddressChange}
                    placeholder="Street"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="text"
                    name="city"
                    value={formData.address.city}
                    onChange={handleAddressChange}
                    placeholder="City"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="text"
                    name="state"
                    value={formData.address.state}
                    onChange={handleAddressChange}
                    placeholder="State"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="text"
                    name="country"
                    value={formData.address.country}
                    onChange={handleAddressChange}
                    placeholder="Country"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                  <input
                    type="text"
                    name="zipCode"
                    value={formData.address.zipCode}
                    onChange={handleAddressChange}
                    placeholder="Zip Code"
                    className="block w-full px-4 py-2 rounded-xl text-sm"
                  />
                </div>
              </div>

              {/* Avatar and Resume */}
<div>
  <h3 className="text-xl font-semibold mb-3">Profile Picture and Resume</h3>
  <div className="grid grid-cols-2 gap-4">
    <div>
      <label htmlFor="avatar" className="block text-sm font-semibold text-gray-700 mb-1">
        Profile Picture
      </label>
      <div className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer relative overflow-hidden">
        {formData.avatar && formData.avatar.url ? (
          <img src={formData.avatar.url} alt="Avatar" className="w-full h-full object-cover" />
        ) : (
          <div className="text-center">
            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
              <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <p className="mt-1 text-sm text-gray-600">Upload Avatar</p>
          </div>
        )}
        <input
          id="avatar"
          type="file"
          accept=".png,.jpg,.jpeg"
          onChange={(e) => handleFileChange(e, 'avatar')}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        />
      </div>
    </div>
    <div>
      <label htmlFor="resume" className="block text-sm font-semibold text-gray-700 mb-1">
        Resume
      </label>
      <div className="w-full h-32 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer relative overflow-hidden">
        {formData.resume && formData.resume.url ? (
          <div className="text-center">
            <p className="text-sm text-gray-600">Update Resume</p>
            <p className="text-xs text-gray-400">{formData.resume.url.split('/').pop()}</p>
          </div>
        ) : (
          <div className="text-center">
            <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
            <p className="mt-1 text-sm text-gray-600">Upload Resume</p>
          </div>
        )}
        <input
          id="resume"
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={(e) => handleFileChange(e, 'resume')}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        />
      </div>
      {formData.resume && formData.resume.url && (
        <button
          type="button"
          onClick={handleViewResume}
          className="mt-2 w-inline py-2 px-3 text-base border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          View Current Resume
        </button>
      )}
    </div>
  </div>
</div>

              {/* Skills */}
              <div>
                <h3 className="text-xl font-semibold mb-3">Skills</h3>
                <input
                  type="text"
                  name="skills"
                  value={formData.skills.join(', ')}
                  onChange={(e) => setFormData({...formData, skills: e.target.value.split(', ')})}
                  placeholder="Enter skills separated by commas"
                  className="block w-full px-4 py-2 rounded-xl text-sm"
                />
              </div>

              {/* Fresher Status */}
              <div>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="isFresher"
                    checked={formData.isFresher}
                    onChange={(e) => setFormData({...formData, isFresher: e.target.checked})}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <span className="ml-2 text-gray-700 text-lg">I am a fresher</span>
                </label>
              </div>

              {/* Education */}
              <div> 
                <h3 className="text-xl font-semibold mb-3">Education</h3>
                {formData.education.map((edu, index) => (
                  <div key={index} className="mb-4 p-4  rounded-lg shadow">
                    <div className="grid grid-cols-2 gap-4">
                      <input
                        type="text"
                        value={edu.school}
                        onChange={(e) => handleEducationChange(index, 'school', e.target.value)}
                        placeholder="School"
                        className="block w-full px-4 py-2 rounded-xl text-sm"
                      />
                      <input
                        type="text"
                        value={edu.degree}
                        onChange={(e) => handleEducationChange(index, 'degree', e.target.value)}
                        placeholder="Degree"
                        className="block w-full px-4 py-2 rounded-xl text-sm"
                      />
                      <input
                        type="text"
                        value={edu.fieldofstudy}
                        onChange={(e) => handleEducationChange(index, 'fieldofstudy', e.target.value)}
                        placeholder="Field of Study"
                        className="block w-full px-4 py-2 rounded-xl text-sm"
                      />
                      <input
                        type="date"
                        value={edu.from}
                        onChange={(e) => handleEducationChange(index, 'from', e.target.value)}
                        className="block w-full px-4 py-2 rounded-xl text-sm"
                      />
                      <input
                        type="date"
                        value={edu.to}
                        onChange={(e) => handleEducationChange(index, 'to', e.target.value)}
                        className="block w-full px-4 py-2 rounded-xl text-sm"
                      />
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          checked={edu.current}
                          onChange={(e) => handleEducationChange(index, 'current', e.target.checked)}
                          className="mr-2"
                        />
                        <label>Current</label>
                      </div>
                    </div>
                    <textarea
                      value={edu.description}
                      onChange={(e) => handleEducationChange(index, 'description', e.target.value)}
                      placeholder="Description"
                      className="block w-full px-4 py-2 rounded-xl text-sm mt-2"
                    />
                    <button type="button" onClick={() => removeEducation(index)} className="mt-2 text-red-600 text-sm">Remove</button>
                  </div>
                ))}
                <button type="button" onClick={addEducation} className="mt-2 flex items-center text-blue-600">
                  <PlusIcon className="w-5 h-5 mr-1" /> Add Education
                </button>
              </div>

              {/* Work Experience */}
              <div>
                <h3 className="text-xl font-semibold mb-3">Work Experience</h3>
                {formData.workExperiences.map((exp, index) => (
                  <div key={index} className="mb-4 p-4  rounded-lg shadow">
                    <div className="grid grid-cols-2 gap-4">
                      <input
                        type="text"
                        value={exp.jobTitle}
                        onChange={(e) => handleWorkExperienceChange(index, 'jobTitle', e.target.value)}
                        placeholder="Job Title"
                        className="block w-full px-4 py-2 rounded-xl text-sm"
                      />
                      <input
                        type="text"
                        value={exp.companyName}
                        onChange={(e) => handleWorkExperienceChange(index, 'companyName', e.target.value)}
                        placeholder="Company Name"
                        className="block w-full px-4 py-2 rounded-xl text-sm"
                      />
                      <input
                        type="date"
                        value={exp.startDate}
                        onChange={(e) => handleWorkExperienceChange(index, 'startDate', e.target.value)}
                        className="block w-full px-4 py-2 rounded-xl text-sm"
                      />
                      <input
                        type="date"
                        value={exp.endDate}
                        onChange={(e) => handleWorkExperienceChange(index, 'endDate', e.target.value)}
                        className="block w-full px-4 py-2 rounded-xl text-sm"
                      />
                    </div>
                    <textarea
                      value={exp.responsibilities}
                      onChange={(e) => handleWorkExperienceChange(index, 'responsibilities', e.target.value)}
                      placeholder="Responsibilities"
                      className="block w-full px-4 py-2 rounded-xl text-sm mt-2"
                    />
                    <button type="button" onClick={() => removeWorkExperience(index)} className="mt-2 text-red-600 text-sm">Remove</button>
                  </div>
                ))}
                <button type="button" onClick={addWorkExperience} className="mt-2 flex items-center text-blue-600">
                  <PlusIcon className="w-5 h-5 mr-1" /> Add Work Experience
                </button>
              </div>
            </div>

            <div className="mt-8 flex justify-center">
              <button
                type="submit"
                className="inline-block justify-center px-8 py-2 text-white bg-blue-500 rounded-md text-lg font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { updateJobseekerProfile })(EditJobseekerProfile);