import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

const ResumeUpload = ({ prevStep, nextStep, handleChange }) => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [filePreview, setFilePreview] = useState(null);

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile.size > 5 * 1024 * 1024) { // 5MB limit
      alert('File size should not exceed 5MB');
      return;
    }
    setFile(selectedFile);
    setFileToBase(selectedFile);
  };

  const setFileToBase = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      handleChange('resume')(reader.result);
      setFilePreview(file.name);
    };
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (file) {
      nextStep();
    } else {
      alert('Please upload a resume before proceeding.');
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <h2 className="text-xl font-semibold mb-6">Upload Resume</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-6">
          <p className="text-center text-base text-gray-500 mb-4">
            To upload file size is (Max 5Mb) and allowed file types are (.docx, .pdf)
          </p>
          <div className="flex justify-center">
            <button
              type="button"
              onClick={triggerFileInput}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
            >
              Upload Resume
            </button>
            <input
              ref={fileInputRef}
              type="file"
              onChange={onFileChange}
              accept=".doc,.docx,.pdf"
              className="hidden"
            />
          </div>
          {filePreview && (
            <p className="text-center text-base mt-4 text-green-600">
              File selected: {filePreview}
            </p>
          )}
        </div>
        <div className="flex justify-between">
          <button
            type="button"
            onClick={prevStep}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
          >
            ← Previous
          </button>
          <button
            type="submit"
            className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700"
          >
            Next →
          </button>
        </div>
      </form>
    </div>
  );
};

ResumeUpload.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default ResumeUpload;