import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FaMapMarkerAlt, FaClock, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { getEmployerJobs, deleteJob } from '../../../actions/jobActions';

const JobListings = ({ getEmployerJobs, deleteJob, job: { jobs, loading, error } }) => {
  useEffect(() => {
    getEmployerJobs();
  }, [getEmployerJobs]);

  const handleDeleteJob = (jobId) => {
    if (window.confirm('Are you sure you want to delete this job?')) {
      deleteJob(jobId);
    }
  };

  const formatTimestamp = (timestamp) => {
    const dateObj = new Date(timestamp);
    const formattedDate = dateObj.toISOString().split('T')[0];
    return formattedDate;
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'approved':
        return 'bg-green-100 text-green-800';
      case 'rejected':
        return 'bg-red-100 text-red-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-red-500 p-4">Error: {error.msg || 'An error occurred'}</div>;
  }

  if (!jobs || jobs.length === 0) {
    return (
      <div className="text-center p-8">
        <p className="text-gray-500">No jobs posted yet.</p>
        <button 
          onClick={() => window.location.href = '/company-dashboard/post-job'}
          className="mt-4 bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors"
        >
          Post Your First Job
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-4 p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">Posted Jobs</h1>
        <button 
          onClick={() => window.location.href = '/company-dashboard/post-job'}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
        >
          Post New Job
        </button>
      </div>

      {jobs.map((job) => (
        <div
          key={job._id}
          className="bg-white border border-gray-200 p-4 md:p-6 flex flex-col md:flex-row items-start md:items-center rounded-xl shadow-lg hover:shadow-xl transition-shadow"
        >
          <div className="flex-grow flex items-center mb-4 md:mb-0">
            <div className="w-12 h-12 md:w-16 md:h-16 bg-blue-900 rounded-lg flex items-center justify-center mr-4">
              <span className="text-white text-xl font-bold">{job.jobTitle[0]}</span>
            </div>
            <div>
              <h2 className="font-semibold text-lg mb-2">{job.jobTitle}</h2>
              <div className="text-sm text-gray-500 flex flex-wrap items-center gap-4">
                <div className="flex items-center">
                  <FaMapMarkerAlt className="mr-1" />
                  <span>{job.location}</span>
                </div>
                <div className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusBadgeClass(job.status)}`}>
                  {job.status || 'pending'}
                </div>
                {job.status === 'rejected' && job.rejectionReason && (
                  <div className="text-red-600 text-xs">
                    Reason: {job.rejectionReason}
                  </div>
                )}
                <div className="flex items-center">
                  <FaClock className="mr-1" />
                  <span>Posted: {formatTimestamp(job.postedOn || job.date)}</span>
                </div>
              </div>
            </div>
          </div>
          
          <div className="flex flex-col md:flex-row items-center gap-4 w-full md:w-auto mt-4 md:mt-0">
            <div className="text-center">
              <span className="block text-lg font-semibold">{job.applicants?.length || 0}</span>
              <span className="text-sm text-gray-500">Applicants</span>
            </div>
            
            <div className="flex gap-2">
              <button 
                className="p-2 text-blue-500 hover:bg-blue-50 rounded-full transition-colors"
                disabled={job.status === 'rejected'}
                title="Edit Job"
              >
                <FaEdit size={20} />
              </button>
              <button 
                className="p-2 text-red-500 hover:bg-red-50 rounded-full transition-colors"
                onClick={() => handleDeleteJob(job._id)}
                title="Delete Job"
              >
                <FaTrashAlt size={20} />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  job: state.job
});

export default connect(mapStateToProps, { getEmployerJobs, deleteJob })(JobListings);