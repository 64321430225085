import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';
import {
  GET_PROFILE,
  PROFILE_ERROR,
  CLEAR_PROFILE,
  UPDATE_PROFILE,
  JOBSEEKER_PROFILE_CREATE_SUCCESS,
  JOBSEEKER_PROFILE_CREATE_FAIL,
  FETCH_RESUME_SUCCESS,
  FETCH_RESUME_FAIL,
  UPDATE_RESUME_SUCCESS,
  UPDATE_RESUME_FAIL,
  RESUME_LOADING,
  USER_LOADED,
  SET_JOBSEEKER_AUTHENTICATED
  
} from './types';
import { loadJobSeeker } from './auth';


// Get current jobseeker's profile
// export const getCurrentJobseekerProfile = () => async (dispatch, getState) => {
//     try {
//         dispatch({ type: 'SET_LOADING', payload: true });
//         console.log('Fetching jobseeker profile...');
        
//         const token = getState().auth.token;
//         const config = {
//             headers: { 
//                 'Cache-Control': 'no-cache',
//                 'Authorization': `Bearer ${token}`
//             }
//         };

//         const res = await axios.get('/api/jobseekerProfile/me', config);
//         console.log('Jobseeker profile fetched:', res.data);

//         dispatch({
//             type: GET_PROFILE,
//             payload: { ...res.data, type: 'jobseeker' }
//         });
//         console.log('Dispatched GET_PROFILE action');
//     } catch (err) {
//       console.error('Error fetching jobseeker profile:', err.response?.data || err.message);
//       dispatch({
//         type: PROFILE_ERROR,
//         payload: { 
//           msg: err.response?.data?.msg || 'Server Error', 
//           status: err.response?.status || 500 
//         }
//       });
//       console.log('Dispatched PROFILE_ERROR action');
//     } finally {
//       dispatch({ type: 'SET_LOADING', payload: false });
//     }
//   };

export const getCurrentJobseekerProfile = () => async (dispatch) => {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    console.log('Fetching jobseeker profile...');

    const res = await axios.get('/api/jobseekerProfile/me', {
      headers: { 'Cache-Control': 'no-cache' }
    });
    console.log('Jobseeker profile fetched:', res.data);

    dispatch({
      type: GET_PROFILE,
      payload: { ...res.data, type: 'jobseeker' }
    });
    console.log('Dispatched GET_PROFILE action');
  } catch (err) {
    console.error('Error fetching jobseeker profile:', err);
    console.error('Error response:', err.response);
    dispatch({
      type: PROFILE_ERROR,
      payload: { 
        msg: err.response?.data?.msg || 'Server Error', 
        status: err.response?.status || 500 
      }
    });
    console.log('Dispatched PROFILE_ERROR action');
  } finally {
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

export const createJobseekerProfile = (profileData, isFresher) => async (dispatch) => {
  try {
    console.log("Creating jobseeker profile with data:", profileData);
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post(`/api/jobseekerProfile/${isFresher ? 'fresher' : 'experienced'}`, profileData, config);

    dispatch({
      type: JOBSEEKER_PROFILE_CREATE_SUCCESS,
      payload: res.data
    });

    dispatch({
      type: SET_JOBSEEKER_AUTHENTICATED,
      payload: true
    });

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });

    await dispatch(loadJobSeeker());

    return res.data;
  } catch (err) {
    console.error('Error in createJobseekerProfile:', err);
    
    if (err.response && err.response.data && err.response.data.errors) {
      err.response.data.errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    } else {
      dispatch(setAlert('Error creating profile. Please try again.', 'danger'));
    }

    dispatch({
      type: JOBSEEKER_PROFILE_CREATE_FAIL,
      payload: { msg: err.response ? err.response.statusText : 'Server Error', status: err.response ? err.response.status : 500 }
    });

    throw err; // Re-throw the error to be caught in the component
  }
};


// Create or update jobseeker profile
// export const createJobseekerProfile = (profileData, isFresher) => async (dispatch) => {
//     try {
//       const config = {
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${localStorage.getItem('token')}`
//         }
//       };
  
//       console.log('Sending profile data to server:', profileData);
//       console.log('Is Fresher:', isFresher);
      
//       const res = await axios.post(`/api/jobseekerProfile/${isFresher ? 'fresher' : 'experienced'}`, profileData, config);
    
//       console.log('Response received from server:', res.data);
//       await dispatch({
//         type: JOBSEEKER_PROFILE_CREATE_SUCCESS,
//         payload: res.data
//     });
    
//     await dispatch({
//         type: GET_PROFILE,
//         payload: res.data
//     });

//     await dispatch(setAlert('Jobseeker profile created successfully', 'success'));
    
//     return res.data;
      
//     } catch (err) {
//       console.error('Error in createJobseekerProfile:', err);
//       console.error('Error response:', err.response);
//       console.error('Error message:', err.message);
      
//       if (err.response && err.response.data && err.response.data.errors) {
//         err.response.data.errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
//       } else {
//         dispatch(setAlert('Error creating profile. Please try again.', 'danger'));
//       }
  
//       dispatch({
//         type: JOBSEEKER_PROFILE_CREATE_FAIL,
//         payload: { msg: err.response ? err.response.statusText : 'Server Error', status: err.response ? err.response.status : 500 }
//       });
      
//       throw err;
//     }
//   };

  // Update jobseeker profile
export const updateJobseekerProfile = (profileData) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      };
  
      // Convert avatar and resume data to string if they exist
      if (profileData.avatar && profileData.avatar.url) {
        profileData.avatar = profileData.avatar.url;
      }
      if (profileData.resume && profileData.resume.url) {
        profileData.resume = profileData.resume.url;
      }
  
      const res = await axios.put('/api/jobseekerProfile', profileData, config);
  
      dispatch({
        type: UPDATE_PROFILE,
        payload: res.data
      });
  
      dispatch(setAlert('Job seeker profile updated successfully', 'success'));
      return res.data;
    } catch (err) {
      if (err.response) {
        console.error('Error response:', err.response.data);
      }
      
      if (err.response && err.response.data && err.response.data.errors) {
        err.response.data.errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      } else {
        dispatch(setAlert('Error updating profile. Please try again.', 'danger'));
      }
  
      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response ? err.response.statusText : 'Server Error', status: err.response ? err.response.status : 500 }
      });
      
      throw err;
    }
  };
// Clear profile
export const clearProfile = () => ({ type: CLEAR_PROFILE });




// Fetch resume
export const fetchCurrentResume = () => async (dispatch) => {
    try {
      dispatch({ type: RESUME_LOADING });
  
      const res = await axios.get('/api/jobseekerProfile/resume');
  
      dispatch({
        type: FETCH_RESUME_SUCCESS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: FETCH_RESUME_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };
  
  // Update resume
  export const updateResume = (formData) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
  
      const res = await axios.put('/api/jobseekerProfile/resume', formData, config);
  
      dispatch({
        type: UPDATE_RESUME_SUCCESS,
        payload: res.data
      });
  
      dispatch(setAlert('Resume updated successfully', 'success'));
    } catch (err) {
      dispatch({
        type: UPDATE_RESUME_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
  
      dispatch(setAlert('Failed to update resume', 'danger'));
    }
  };