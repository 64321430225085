// src/reducers/employerProfileReducer.js
import {
    GET_PROFILE,
    EMPLOYER_PROFILE_CREATE_SUCCESS,
    EMPLOYER_PROFILE_CREATE_FAIL,
    PROFILE_ERROR,
    UPDATE_PROFILE
  } from '../actions/types';
  
  const initialState = {
    profile: null,
    loading: false,
    error: null
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
        case 'SET_LOADING':
      return {
        ...state,
        loading: payload
      };
      case GET_PROFILE:
        console.log('Reducer: Handling GET_PROFILE action', payload);
        return {
          ...state,
          profile: payload,
          loading: false,
          error: null
        };
      case EMPLOYER_PROFILE_CREATE_SUCCESS:
        console.log('Reducer: Handling EMPLOYER_PROFILE_CREATE_SUCCESS action', payload);
        return {
          ...state,
          profile: payload,
          loading: false,
          error: null
        };
      case EMPLOYER_PROFILE_CREATE_FAIL:
      case PROFILE_ERROR:
        console.log('Reducer: Handling PROFILE_ERROR action', payload);
        return {
          ...state,
          profile: null,
          error: payload,
          loading: false
        };
        case UPDATE_PROFILE:
            console.log('Reducer: Handling UPDATE_PROFILE action', payload);
            return {
              ...state,
              profile: payload,
              loading: false,
              error: null
            };
      default:
        return state;
    }
  }