import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

const WorkExperience = ({ prevStep, nextStep, handleChange, values }) => {
    const [jobs, setJobs] = useState(values.workExperiences || [{
        jobTitle: '',
        companyName: '',
        startDate: '',
        endDate: '',
        responsibilities: '',
    }]);

    const handleJobChange = (index, field, value) => {
        const updatedJobs = jobs.map((job, i) => 
            i === index ? { ...job, [field]: value } : job
        );
        setJobs(updatedJobs);
        handleChange(updatedJobs);
    };

    const validateJobs = () => {
        return jobs.every(job => 
            job.jobTitle && job.companyName && job.startDate
        );
    };
    

      const addMoreJobs = () => {
        const newJobs = [
            ...jobs, 
            {
                jobTitle: '',
                companyName: '',
                startDate: '',
                endDate: '',
                responsibilities: '',
            }
        ];
        setJobs(newJobs);
        handleChange(newJobs);
    };

    return (
        <div className="bg-gray-100 p-4 sm:p-6 md:p-8 rounded-lg shadow-md max-w-4xl mx-auto">
            <h2 className="text-2xl font-bold mb-6">Work Experience</h2>

            {jobs.map((job, index) => (
        <div key={index} className="mb-8">
                    {index > 0 && (
                        <h3 className="text-xl font-semibold mb-4">
                            Previous Job {index} (Optional)
                        </h3>
                    )}

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="block text-sm font-semibold text-gray-700 mb-1">
                                {index === 0 ? 'Current/Most Recent Job Title' : 'Previous Job Title'}
                            </label>
                            <input
                                type="text"
                                className="w-full p-3 border border-gray-300 rounded-xl text-sm placeholder:text-sm"
                                placeholder={index === 0 ? "Enter your current or most recent job title" : "Enter your previous job title"}
                                value={job.jobTitle}
                                onChange={(e) => handleJobChange(index, 'jobTitle', e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-semibold text-gray-700 mb-1">Company Name</label>
                            <input
                                type="text"
                                className="w-full p-3 border border-gray-300 rounded-xl text-sm placeholder:text-sm"
                                placeholder="Enter the company name"
                                value={job.companyName}
                                onChange={(e) => handleJobChange(index, 'companyName', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="block text-sm font-semibold text-gray-700 mb-1">Start Date</label>
                            <input
                                type="date"
                                className="w-full p-3 border border-gray-300 rounded-xl text-sm placeholder:text-sm"
                                value={job.startDate}
                                onChange={(e) => handleJobChange(index, 'startDate', e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-semibold text-gray-700 mb-1">End Date</label>
                            <input
                                type="date"
                                className="w-full p-3 border border-gray-300 rounded-xl text-sm placeholder:text-sm"
                                value={job.endDate}
                                onChange={(e) => handleJobChange(index, 'endDate', e.target.value)}
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-semibold text-gray-700 mb-1">
                            {index === 0 ? 'Job Responsibilities' : 'Previous Job Responsibilities'}
                        </label>
                        <textarea
                            className="w-full p-3 border border-gray-300 rounded-xl text-sm placeholder:text-sm"
                            rows="3"
                            placeholder={index === 0 ? "Describe your current or most recent job responsibilities" : "Describe your previous job responsibilities"}
                            value={job.responsibilities}
                            onChange={(e) => handleJobChange(index, 'responsibilities', e.target.value)}
                        ></textarea>
                    </div>
                </div>
            ))}

            {jobs.length < 3 && (
                <button
                    type="button"
                    onClick={addMoreJobs}
                    className="w-full py-2 px-4 border border-gray-300 rounded-xl placeholder:text-sm shadow-sm text-sm font-medium text-blue-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mb-6"
                >
                    + Add previous jobs
                </button>
            )}


<div className="flex justify-between">
                <button
                    type="button"
                    onClick={prevStep}
                    className="mb-2 sm:mb-0 bg-gray-300 text-gray-700 py-2 px-6 rounded-xl hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                    ← Previous
                </button>
                <button
                    type="button"
                    onClick={() => {
                        if (validateJobs()) {
                            nextStep();
                        } else {
                            alert('Please fill in all required fields for each job experience.');
                        }
                    }}
                    className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700"
                >
                    Next →
                </button>
            </div>
        </div>
    );
};

WorkExperience.propTypes = {
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.shape({
        workExperiences: PropTypes.arrayOf(PropTypes.shape({
            jobTitle: PropTypes.string,
            companyName: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
            responsibilities: PropTypes.string,
        }))
    }).isRequired,
};

export default WorkExperience;