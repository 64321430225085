import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { FaBriefcase, FaMapMarkerAlt, FaCalendar, FaUsers } from 'react-icons/fa';

const JobDetails = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchJobDetails = async () => {
      setIsLoading(true);
      try {
        const jobResponse = await axios.get(`/api/jobs/${id}`);
        setJob(jobResponse.data);
      } catch (error) {
        console.error('Error fetching job details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobDetails();
  }, [id]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!job) {
    return <div className="text-center py-8 text-lg text-gray-600">Job not found</div>;
  }

  return (
    <div className="mt-[-6rem] md:mt-[-6rem] block">
    <div className="bg-gray-50 min-h-screen py-12 sm:py-8 xs:py-6">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="flex flex-col md:flex-row gap-6">
          {/* Main content */}
          <div className="md:w-2/3">
            <div className="bg-white rounded-lg shadow-sm overflow-hidden mb-6">
              <div className="p-6">
                <h1 className="text-xl font-semibold text-gray-800 mb-1">{job.jobTitle}</h1>
                <h2 className="text-base text-gray-600 mb-4">{job.employer?.name}</h2>
                
                <div className="flex flex-wrap gap-4 mb-4 text-sm">
                  <InfoItem icon={<FaMapMarkerAlt />} value={job.location} />
                  <InfoItem icon={<FaBriefcase />} value={`${job.experience}`} />
                  <InfoItem icon={<FaCalendar />} value={`Due: ${new Date(job.applicationDeadline).toLocaleDateString()}`} />
                  <InfoItem icon={<FaUsers />} value={`${job.applicants.length} applicants`} />
                </div>

                <div className="flex justify-end gap-4">
                  <Link to="/register" className="border border-blue-500 text-blue-500 px-4 py-2 rounded text-sm hover:bg-blue-50 transition duration-300">
                    Register to Apply
                  </Link>
                  <Link to="/login" className="bg-blue-600 text-white px-4 py-2 rounded text-sm hover:bg-blue-700 transition duration-300">
                    Login to Apply
                  </Link>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-sm overflow-hidden mb-6">
              <div className="p-6">
                <h2 className="text-lg font-semibold text-gray-800 mb-3">Job Description</h2>
                <div className="text-sm text-gray-600 space-y-4">
                  <p>{job.jobDescription}</p>
                  {job.jobHighlights && (
                    <div>
                      <h3 className="text-lg font-semibold text-gray-800 mb-3">Job Highlights:</h3>
                      <p>{job.jobHighlights}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

          </div>

          {/* Sidebar */}
          <div className="md:w-1/3">
          <div className="bg-white rounded-lg shadow-sm overflow-hidden mb-6">
              <div className="p-6">
                <h2 className="text-lg font-semibold text-gray-800 mb-3">Job Details</h2>
                <ul className="text-sm text-gray-600 space-y-2">
                  <li><span className="font-medium">Job Type:</span> {job.jobType}</li>
                  <li><span className="font-medium">Industry:</span> {job.industry}</li>
                  <li><span className="font-medium">Experience Level:</span> {job.experienceLevel}</li>
                  <li><span className="font-medium">Offered Salary:</span> {job.offeredSalary}</li>
                  <li><span className="font-medium">Qualification:</span> {job.qualification}</li>
                  {job.interviewVenue && <li><span className="font-medium">Interview Venue:</span> {job.interviewVenue}</li>}
                </ul>
              </div>
            </div>

            {job.keySkills && job.keySkills.length > 0 && (
              <div className="bg-white rounded-lg shadow-sm overflow-hidden mb-6">
                <div className="p-6">
                  <h2 className="text-lg font-semibold text-gray-800 mb-3">Key Skills</h2>
                  <div className="flex flex-wrap gap-2">
                    {job.keySkills.map((skill, index) => (
                      <span key={index} className="bg-gray-100 text-gray-700 px-2 py-1 rounded text-xs">
                        {skill}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

const InfoItem = ({ icon, value }) => (
  <div className="flex items-center text-gray-600">
    <span className="mr-1">{icon}</span>
    <span>{value}</span>
  </div>
);

export default JobDetails;