// Existing action types
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const COMPANY_REGISTER_SUCCESS = 'COMPANY_REGISTER_SUCCESS'
export const GOOGLE_SIGNUP_SUCCESS = 'GOOGLE_SIGNUP_SUCCESS';
export const GOOGLE_SIGNUP_FAIL = 'GOOGLE_SIGNUP_FAIL';
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const COMPANY_LOGIN_SUCCESS = 'COMPANY_LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const GET_PROFILE = 'GET_PROFILE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const CLEAR_PROFILE = 'CLEAR_PROFILE'
export const DELETE_PROFILE = 'DELETE_PROFILE'
export const PROFILE_ERROR = 'PROFILE_ERROR'
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED'
export const GET_PROFILES = 'GET_PROFILES'
export const GET_POSTS = 'GET_POSTS'
export const GET_POST = 'GET_POST'
export const POST_ERROR = 'POST_ERROR'
export const UPDATE_LIKES = 'UPDATE_LIKES'
export const DELETE_POST = 'DELETE_POST'
export const ADD_POST = 'ADD_POST'
export const ADD_COMMENT = 'ADD_COMMENT'
export const REMOVE_COMMENT = 'REMOVE_COMMENT'
export const COMPANY_LOADED = 'COMPANY_LOADED'
export const GET_JOBS = 'GET_JOBS'
export const SEARCH_JOBS = 'SEARCH_JOBS'
export const GET_JOB = 'GET_JOB'
export const ADD_JOB = 'ADD_JOB'
export const UPDATE_JOBS = 'UPDATE_JOBS'
export const DELETE_JOB = 'DELETE_JOB'
export const ADMIN_LOADED = 'ADMIN_LOADED'
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS'
export const GET_APPLIED_JOBS = 'GET_APPLIED_JOBS'
export const GET_FAVORITE_JOBS = 'GET_FAVORITE_JOBS'
export const DELETE_APPLIED_JOB = 'DELETE_APPLIED_JOB'
export const REMOVE_FAVORITE_JOB = 'REMOVE_FAVORITE_JOB'
export const ADD_DATA = 'ADD_DATA'
export const ADD_SCHEDULED_INTERVIEW = 'ADD_SCHEDULED_INTERVIEW'
export const GET_INTERVIEWS = 'GET_INTERVIEWS'
export const SET_MEETING_NULL = 'SET_MEETING_NULL'
export const SET_APPLICANT_SCORE = 'SET_APPLICANT_SCORE'

// New action types for admin
export const ADMIN_REGISTER_SUCCESS = 'ADMIN_REGISTER_SUCCESS'
export const ADMIN_AUTH_ERROR = 'ADMIN_AUTH_ERROR'
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT'
export const GET_ADMIN_PROFILE = 'GET_ADMIN_PROFILE'
export const UPDATE_ADMIN_PROFILE = 'UPDATE_ADMIN_PROFILE'
export const ADMIN_PROFILE_ERROR = 'ADMIN_PROFILE_ERROR'
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL'
export const GET_ADMIN_JOBS = 'GET_ADMIN_JOBS'
export const UPDATE_JOB_STATUS = 'UPDATE_JOB_STATUS'
export const ADMIN_JOB_ERROR = 'ADMIN_JOB_ERROR'
export const SET_LOADING = 'SET_LOADING'
export const ADMIN_NOT_FOUND = 'ADMIN_NOT_FOUND'
export const UPDATE_JOB_DETAILS = 'UPDATE_JOB_DETAILS'
export const GET_ALL_APPLICATIONS_REQUEST = 'GET_ALL_APPLICATIONS_REQUEST'
export const GET_ALL_APPLICATIONS_SUCCESS = 'GET_ALL_APPLICATIONS_SUCCESS'
export const GET_ALL_APPLICATIONS_FAIL = 'GET_ALL_APPLICATIONS_FAIL'
export const UPDATE_APPLICATION_STATUS_REQUEST = 'UPDATE_APPLICATION_STATUS_REQUEST'
export const UPDATE_APPLICATION_STATUS_SUCCESS = 'UPDATE_APPLICATION_STATUS_SUCCESS'
export const UPDATE_APPLICATION_STATUS_FAIL = 'UPDATE_APPLICATION_STATUS_FAIL'
export const BULK_UPDATE_JOB_STATUS = 'BULK_UPDATE_JOB_STATUS'

export const GET_ANALYTICS_REQUEST = 'GET_ANALYTICS_REQUEST'
export const GET_ANALYTICS_SUCCESS = 'GET_ANALYTICS_SUCCESS'
export const GET_ANALYTICS_FAIL = 'GET_ANALYTICS_FAIL'
export const GET_MONTHLY_STATS_REQUEST = 'GET_MONTHLY_STATS_REQUEST'
export const GET_MONTHLY_STATS_SUCCESS = 'GET_MONTHLY_STATS_SUCCESS'
export const GET_MONTHLY_STATS_FAIL = 'GET_MONTHLY_STATS_FAIL'

export const GET_DASHBOARD_COUNTS_REQUEST = 'GET_DASHBOARD_COUNTS_REQUEST';
export const GET_DASHBOARD_COUNTS_SUCCESS = 'GET_DASHBOARD_COUNTS_SUCCESS';
export const GET_DASHBOARD_COUNTS_FAIL = 'GET_DASHBOARD_COUNTS_FAIL';



export const GET_EMPLOYER_PROFILE = 'GET_EMPLOYER_PROFILE';
export const EMPLOYER_PROFILE_ERROR = 'EMPLOYER_PROFILE_ERROR';
export const CLEAR_EMPLOYER_PROFILE = 'CLEAR_EMPLOYER_PROFILE';
export const UPDATE_EMPLOYER_PROFILE = 'UPDATE_EMPLOYER_PROFILE';

export const EMPLOYER_REGISTER_SUCCESS = 'EMPLOYER_REGISTER_SUCCESS';
export const EMPLOYER_LOADED='EMPLOYER_LOADED';
export const EMPLOYER_NOT_FOUND='EMPLOYER_NOT_FOUND';

export const EMPLOYER_PROFILE_CREATE_SUCCESS = 'EMPLOYER_PROFILE_CREATE_SUCCESS';
export const EMPLOYER_PROFILE_CREATE_FAIL ='EMPLOYER_PROFILE_CREATE_FAIL';
export const EMPLOYER_LOGIN_SUCCESS ='EMPLOYER_LOGIN_SUCCESS';

//job seeker 

export const JOBSEEKER_LOADED ='JOBSEEKER_LOADED';
export const JOBSEEKER_LOGIN_SUCCESS ='JOBSEEKER_LOGIN_SUCCESS';
export const JOBSEEKER_PROFILE_CREATE_SUCCESS ='JOBSEEKER_PROFILE_CREATE_SUCCESS';
export const JOBSEEKER_PROFILE_CREATE_FAIL ='JOBSEEKER_PROFILE_CREATE_FAIL';
export const SET_JOB_SEEKER ='SET_JOB_SEEKER';

//job actions 
export const POST_JOB_SUCCESS = 'POST_JOB_SUCCESS';
export const POST_JOB_FAIL = 'POST_JOB_FAIL';

export const GET_EMPLOYER_JOBS = 'GET_EMPLOYER_JOBS';

export const JOB_ERROR = 'JOB_ERROR';

export const POST_JOB_ERROR = 'POST_JOB_ERROR'



export const POST_JOB = 'POST_JOB'


//applicant actions 
export const GET_JOB_APPLICANTS = 'GET_JOB_APPLICANTS';
export const GET_JOB_APPLICANTS_SUCCESS = 'GET_JOB_APPLICANTS_SUCCESS';
export const GET_JOB_APPLICANTS_FAIL = 'GET_JOB_APPLICANTS_FAIL';
export const UPDATE_APPLICANT_STATUS = 'UPDATE_APPLICANT_STATUS';
export const UPDATE_APPLICANT_STATUS_SUCCESS = 'UPDATE_APPLICANT_STATUS_SUCCESS';
export const UPDATE_APPLICANT_STATUS_FAIL = 'UPDATE_APPLICANT_STATUS_FAIL';
export const  SET_JOBSEEKER_AUTHENTICATED = ' SET_JOBSEEKER_AUTHENTICATED';
export const UPDATE_AUTH_JOBSEEKER = 'UPDATE_AUTH_JOBSEEKER';
export const APPLY_JOB_FAIL ='APPLY_JOB_FAIL'
export const APPLY_JOB_SUCCESS ='APPLY_JOB_SUCCESS'
export const UPDATE_APPLICATION_STATUS ='UPDATE_APPLICATION_STATUS'
export const JOB_LOADING ='JOB_LOADING'
export const PROFILE_CREATED ='PROFILE_CREATED'
export const SET_REDIRECT_TO_DASHBOARD ='SET_REDIRECT_TO_DASHBOARD'



//Hero component types 


export const FETCH_JOBS_REQUEST ='FETCH_JOBS_REQUEST'
export const FETCH_JOBS_SUCCESS ='FETCH_JOBS_SUCCESS'
export const FETCH_JOBS_FAILURE ='FETCH_JOBS_FAILURE'
export const TOGGLE_BOOKMARK ='TOGGLE_BOOKMARK'

export const FETCH_RESUME_SUCCESS ='FETCH_RESUME_SUCCESS'
export const FETCH_RESUME_FAIL ='FETCH_RESUME_FAIL'
export const UPDATE_RESUME_SUCCESS ='UPDATE_RESUME_SUCCESS'
export const UPDATE_RESUME_FAIL ='UPDATE_RESUME_FAIL'
export const RESUME_LOADING ='RESUME_LOADING'


//fetch users
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';