import axios from 'axios'
import { setAlert } from './alert'
import { 
    REGISTER_SUCCESS,
    REGISTER_FAIL, 
    USER_LOADED, 
    AUTH_ERROR, 
    LOGIN_SUCCESS, 
    LOGIN_FAIL, 
    LOGOUT,
    CLEAR_PROFILE,
    EMPLOYER_REGISTER_SUCCESS,
    EMPLOYER_LOADED,
    EMPLOYER_LOGIN_SUCCESS,
    EMPLOYER_NOT_FOUND,
    ADMIN_LOGIN_SUCCESS,
    ADMIN_LOADED,
    JOBSEEKER_LOADED,
    JOBSEEKER_LOGIN_SUCCESS,
    ADMIN_LOGIN_FAIL,
    ADMIN_NOT_FOUND
} from './types'
import setAuthToken from '../utils/setAuthToken';

// Register User
export const register = ({ name, email, password, phoneNumber, city, status }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ name, email, password, phoneNumber, city, status })
    try {
        const res = await axios.post('/api/auth/register-jobseeker', body, config)
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        })
        setAuthToken(res.data.token);
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('userType', 'jobseeker'); 
        //dispatch(loadJobSeeker())
        return 'profile-setup' 
        // return true
    } catch (err) {
        const errors = err.response.data.errors
        if (errors){
            errors.forEach(error => {
                dispatch(setAlert(error.msg, 'danger'))
            });
        }
        dispatch({
            type: REGISTER_FAIL
        })
        return false
    }
}

// Register Employer
export const employerRegister = ({ name, email, password, phoneNumber, city }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ name, email, password, phoneNumber, city })
    try {
        const res = await axios.post('/api/auth/register-employer', body, config)
        dispatch({
            type: EMPLOYER_REGISTER_SUCCESS,
            payload: res.data
        })
        setAuthToken(res.data.token);
        localStorage.setItem('token', res.data.token);
        dispatch(loadEmployer())
        return true
    } catch (err) {
        const errors = err.response.data.errors
        if (errors){
            errors.forEach(error => {
                dispatch(setAlert(error.msg, 'danger'))
            });
        }
        dispatch({
            type: REGISTER_FAIL
        })
        return false
    }
}

// Load User
// export const loadUser = () => async dispatch => {
//     if(localStorage.token) {
//         setAuthToken(localStorage.token)
//     }
//     try {
//         const res = await axios.get('/api/auth')
//         dispatch({
//             type: USER_LOADED,
//             payload: res.data
//         })
//     } catch (err) {
//         dispatch({
//             type: AUTH_ERROR
//         })
//     }
// }

// export const loadJobSeeker = () => async dispatch => {
//     if(localStorage.token) {
//         setAuthToken(localStorage.token)
//     }
//     try {
        
//         const res = await axios.get('/api/auth/jobseeker')
//         console.log('Response:', res.data);
//         dispatch({
//             type: JOBSEEKER_LOADED,
//             payload: res.data
//         })
//     } catch (err) {
//         console.error('Error in loadJobSeeker:', err.response ? err.response.data : err.message);
//         dispatch({
//             type: AUTH_ERROR
//         })
//     }
// }


export const loadJobSeeker = () => async dispatch => {
    if(localStorage.token) {
        setAuthToken(localStorage.token)
    }
    try {
        const res = await axios.get('/api/auth/jobseeker')
        console.log('Response:', res.data);
        dispatch({
            type: JOBSEEKER_LOADED,
            payload: res.data
        })
    } catch (err) {
        console.error('Error in loadJobSeeker:', err.response ? err.response.data : err.message);
        dispatch({
            type: AUTH_ERROR
        })
    }
}

// Load Employer
export const loadEmployer = () => async dispatch => {
    if(localStorage.token) {
        setAuthToken(localStorage.token)
    }
    try {
        const res = await axios.get('/api/auth/employer')
        dispatch({
            type: EMPLOYER_LOADED,
            payload: res.data
        })
    } catch (err) {
        if (err.response && err.response.status === 404) {
            dispatch({
                type: EMPLOYER_NOT_FOUND
            })
        } else {
            dispatch({
                type: AUTH_ERROR
            })
        }
    }
}

//jobseekerLogin

export const jobseekerLogin = ({ email, password }) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const body = JSON.stringify({ email, password });
    try {
        const res = await axios.post('/api/auth/login-jobseeker', body, config);
        dispatch({
            type: JOBSEEKER_LOGIN_SUCCESS,
            payload: res.data,
        });
        setAuthToken(res.data.token);
        localStorage.setItem('token', res.data.token);
        dispatch(loadJobSeeker());
        return true;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => {
                dispatch(setAlert(error.msg, 'danger'));
            });
        }
        dispatch({
            type: LOGIN_FAIL,
        });
        return false;
    }
};

// Login Employer
export const employerLogin = ({ email, password }) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const body = JSON.stringify({ email, password });
    try {
        const res = await axios.post('/api/auth/login-employer', body, config);
        dispatch({
            type: EMPLOYER_LOGIN_SUCCESS,
            payload: res.data,
        });
        dispatch(loadEmployer());
        return true
    } catch (err) {
        const errors = err.response.data.errors
        if (errors) {
            errors.forEach((error) => {
                dispatch(setAlert(error.msg, 'danger'));
            });
        }
        dispatch({
            type: LOGIN_FAIL,
        });
        return false
    }
};

// Logout / clear profile
export const logout = () => dispatch => {
    dispatch({ type: CLEAR_PROFILE})
    dispatch({ type: LOGOUT })
}
// Login Admin
export const adminLogin = (formData) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const body = JSON.stringify(formData);

    try {
        const res = await axios.post('/api/admin/login', body, config);
        
        dispatch({
            type: ADMIN_LOGIN_SUCCESS,
            payload: res.data
        });

        dispatch(loadAdmin());
        return true;
    } catch (err) {
        const errors = err.response?.data?.errors;

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: ADMIN_LOGIN_FAIL
        });
        return false;
    }
};

// Load Admin
export const loadAdmin = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    
    try {
        const res = await axios.get('/api/admin/auth');

        dispatch({
            type: ADMIN_LOADED,
            payload: res.data
        });
    } catch (err) {
        if (err.response && err.response.status === 404) {
            dispatch({
                type: ADMIN_NOT_FOUND
            });
        } else {
            dispatch({
                type: AUTH_ERROR
            });
        }
    }
};
// Create new admin
export const createAdmin = (formData) => async dispatch => {
    try {
        const res = await axios.post('/admin/create', formData);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
};

// Change password for admin
export const changePassword = (formData) => async dispatch => {
    try {
        const res = await axios.post('/admin/change-password', formData);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
};