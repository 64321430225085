import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, StarIcon } from '@heroicons/react/solid';

const featuredCompanies = [
  {
    id: 1,
    name: 'Amazon',
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSV9zfsiW7635aiQX2NPZViQNoFEwQ82s1Q0g&s',
    rating: 4.1,
    reviews: '22.3K+ reviews',
    description: "World's largest Internet company.",
    category: 'IT Services',
  },
  {
    id: 2,
    name: 'ICICI Bank',
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5JBGbnUrM8cp7g3tbcnyIj0L-F_qY25PjVA&s',
    rating: 4.0,
    reviews: '35K+ reviews',
    description: 'Leading private sector bank in India.',
    category: 'BFSI',
  },
  {
    id: 3,
    name: 'Capgemini',
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrGNxkUoFRXDlkTkJmPjd5M43Vra1ovczKWw&s',
    rating: 3.8,
    reviews: '36.6K+ reviews',
    description: 'Global leader in technology services.',
    category: 'IT Services',
  },
  {
    id: 4,
    name: 'Nagarro',
    logo: 'https://img.freepik.com/premium-vector/abstract-logo-company-made-with-color_341269-925.jpg',
    rating: 4.1,
    reviews: '3.8K+ reviews',
    description: 'Leader in digital product engineering.',
    category: 'IT Services',
  },
  {
    id: 5,
    name: 'Persistent',
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3DDkUb08O-d-tjBk33lxCcaGJey4IgHeSpA&s',
    rating: 3.6,
    reviews: '2.5K+ reviews',
    description: 'Trusted global solutions company.',
    category: 'IT Services',
  },
  {
    id: 6,
    name: 'HDFC Bank',
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRk_3mSlA8AasN5S71iCdp_u0Yy96axdUtF6g&s',
    rating: 4.2,
    reviews: '40K+ reviews',
    description: 'India\'s leading private sector bank.',
    category: 'BFSI',
  },
  {
    id: 7,
    name: 'TCS',
    logo: 'https://img.freepik.com/premium-vector/abstract-logo-company-made-with-color_341269-925.jpg',
    rating: 3.9,
    reviews: '50K+ reviews',
    description: 'Global IT services and consulting company.',
    category: 'IT Services',
  },
  // Add more companies as needed
];

const FeaturedCompanies = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filter, setFilter] = useState('All');
  const [filteredCompanies, setFilteredCompanies] = useState(featuredCompanies);
  const carouselRef = useRef(null);

  useEffect(() => {
    if (filter === 'All') {
      setFilteredCompanies(featuredCompanies);
    } else {
      setFilteredCompanies(featuredCompanies.filter(company => company.category === filter));
    }
    setCurrentIndex(0);
  }, [filter]);

  const nextSlide = () => {
    if (currentIndex < filteredCompanies.length - 4) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-semibold text-center mb-8">Featured Companies Actively Hiring</h2>
        <div className="flex justify-center space-x-4 mb-8">
          {['All', 'IT Services', 'BFSI'].map((category) => (
            <button
              key={category}
              className={`px-4 py-2 rounded-full ${
                filter === category ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-600'
              } font-light`}
              onClick={() => setFilter(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <div className="relative">
          <div className="overflow-hidden" ref={carouselRef}>
            <div
              className="flex transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * 25}%)` }}
            >
              {filteredCompanies.map((company) => (
                <div key={company.id} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex-shrink-0 px-2">
                  <div className="bg-white rounded-lg shadow-md p-6 mb-4">
                    <img src={company.logo} alt={company.name} className="w-16 h-16 object-contain mb-4" />
                    <h3 className="text-lg font-semibold mb-2">{company.name}</h3>
                    <div className="flex items-center mb-2">
                      <StarIcon className="h-5 w-5 text-yellow-400 mr-1" />
                      <span className="font-medium">{company.rating}</span>
                      <span className="text-gray-500 text-sm ml-2">{company.reviews}</span>
                    </div>
                    <p className="text-sm text-gray-600 mb-4">{company.description}</p>
                    <button className="bg-blue-100 text-blue-600 px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-200 transition-colors">
                      View jobs
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {currentIndex > 0 && (
            <button
              onClick={prevSlide}
              className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
            >
              <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
            </button>
          )}
          {currentIndex < filteredCompanies.length - 4 && (
            <button
              onClick={nextSlide}
              className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
            >
              <ChevronRightIcon className="h-6 w-6 text-gray-600" />
            </button>
          )}
        </div>
        <div className="text-center mt-8">
          <button className="px-6 py-2 border border-blue-600 text-blue-600 rounded-full font-medium hover:bg-blue-50 transition-colors">
            View all companies
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedCompanies;