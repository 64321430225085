import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, fetchUsers } from '../../actions/usersActions';
import EditUserModal from './EditUserModel';


const formatDateTime = (date) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(new Date(date));
};


const ConfirmDeleteModal = ({ user, onConfirm, onCancel }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white rounded-lg p-6 w-full max-w-sm mx-4">
      <h3 className="text-lg font-medium mb-4">Confirm Deletion</h3>
      <p className="text-gray-600 mb-6 text-base">
        Are you sure you want to delete user "{user.name}"? This action cannot be undone.
      </p>
      <div className="flex justify-end space-x-3">
        <button
          onClick={onCancel}
          className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          onClick={onConfirm}
          className="px-4 py-2 bg-red-500 text-white rounded-md text-sm font-medium hover:bg-red-600"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
);

const UserOverview = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const resultsOptions = [10, 25, 50, 100];

  const [filters, setFilters] = useState({
    year: '',
    month: '',
    city: '',
    role: '',
  });
  const [sortOrder, setSortOrder] = useState('desc'); 


  const dispatch = useDispatch();
  const { users, loading, error } = useSelector(state => state.users);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  // Get unique cities and years from users
  const uniqueCities = [...new Set(users.map(user => user.city))];
  const uniqueYears = [...new Set(users.map(user => 
    new Date(user.date).getFullYear()
  ))]


  const filteredUsers = users
    .filter(user => {
      const searchFields = `${user.name} ${user.email} ${user.city}`.toLowerCase();
      const matchesSearch = searchFields.includes(searchTerm.toLowerCase());
      
      const matchesYear = !filters.year || new Date(user.date).getFullYear().toString() === filters.year;
      const matchesMonth = !filters.month || new Date(user.date).getMonth().toString() === filters.month;
      const matchesCity = !filters.city || user.city === filters.city;
      const matchesRole = !filters.role || user.role === filters.role;

      return matchesSearch && matchesYear && matchesMonth && matchesCity && matchesRole;
    })
    .sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });



  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const handleDeleteConfirm = () => {
    if (userToDelete) {
      dispatch(deleteUser(
        userToDelete._id,
        userToDelete.role === 'Job Seeker' ? 'jobseekers' : 'employers'
      ));
      setUserToDelete(null);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-white shadow-md rounded-lg p-4 md:p-6">
      <h2 className="text-2xl md:text-3xl font-light mb-4">User Overview</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
        <input
          type="text"
          placeholder="Search users..."
          className="p-2 border rounded text-base"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        
        <select
          className="p-2 border rounded text-base"
          value={filters.city}
          onChange={(e) => setFilters(prev => ({ ...prev, city: e.target.value }))}
        >
          <option value="">All Cities</option>
          {uniqueCities.map(city => (
            <option key={city} value={city}>{city}</option>
          ))}
        </select>

        <select
          className="p-2 border rounded text-base"
          value={filters.year}
          onChange={(e) => setFilters(prev => ({ ...prev, year: e.target.value }))}
        >
          <option value="">All Years</option>
          {uniqueYears.map(year => (
            <option key={year} value={year.toString()}>{year}</option>
          ))}
        </select>

        <select
          className="p-2 border rounded text-base"
          value={filters.role}
          onChange={(e) => setFilters(prev => ({ ...prev, role: e.target.value }))}
        >
          <option value="">All Roles</option>
          <option value="Job Seeker">Job Seeker</option>
          <option value="Employer">Employer</option>
        </select>

        <select
          className="p-2 border rounded text-base"
          value={usersPerPage}
          onChange={(e) => {
            setUsersPerPage(Number(e.target.value));
            setCurrentPage(1); // Reset to first page when changing results per page
          }}
        >
          {resultsOptions.map(option => (
            <option key={option} value={option}>
              Show {option} per page
            </option>
          ))}
        </select>

      </div>

        {/* Sort Controls */}
        <div className="mb-4 flex justify-end">
        <button
          onClick={() => setSortOrder(prev => prev === 'desc' ? 'asc' : 'desc')}
          className="flex items-left text-base space-x-2 px-4 py-2 bg-gray-100 rounded hover:bg-gray-200"
        >
          <span>Sort by Date</span>
          {sortOrder === 'desc' ? '↓' : '↑'}
        </button>
      </div>
      

      <div className="overflow-x-auto">
        <div className="max-h-[600px] overflow-y-auto">
          <table className="min-w-full table-auto relative">
          <thead className="hidden md:table-header-group">
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-4 text-left">Registration Date</th>
              <th className="py-3 px-4 text-left">Name</th>
              <th className="py-3 px-4 text-left">Email</th>
              <th className="py-3 px-4 text-center">Role</th>
              <th className="py-3 px-4 text-center">Info</th>
              <th className="py-3 px-4 text-center">Status</th>
              <th className="py-3 px-4 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {currentUsers.map(user => (
              <tr key={user._id} className="border-b border-gray-200 hover:bg-gray-100 block md:table-row">
                  <td className="py-3 px-4 flex flex-col md:table-cell">
                  <span className="font-medium md:hidden">Registration Date:</span>
                  <span className="text-xs text-gray-500">{formatDateTime(user.date)}</span>
                </td>
                <td className="py-3 px-4 flex flex-col md:table-cell">
                  <span className="font-medium md:hidden">Name:</span>
                  <span>{user.name}</span>
                </td>
                <td className="py-3 px-4 flex flex-col md:table-cell">
                  <span className="font-medium md:hidden">Email:</span>
                  <span>{user.email}</span>
                </td>
                <td className="py-3 px-4 flex flex-col md:table-cell md:text-center">
                  <span className="font-medium md:hidden">Role:</span>
                  <span>{user.role}</span>
                </td>
                <td className="py-3 px-4 flex flex-col md:table-cell md:text-center">
                  <span className="font-medium md:hidden">Contact:</span>
                  <span>{user.city} • {user.phoneNumber}</span>
                </td>
                <td className="py-3 px-4 flex flex-col md:table-cell md:text-center">
                  <span className="font-medium md:hidden">Status:</span>
                  <span className={`inline-flex justify-center bg-${user.status === 'Active' ? 'green' : 'red'}-200 text-${user.status === 'Active' ? 'green' : 'red'}-600 py-1 px-3 rounded-full text-xs`}>
                    {user.status}
                  </span>
                </td>
                <td className="py-3 px-4 flex justify-start md:justify-center space-x-2">
                  <button 
                    className="bg-blue-500 text-white py-1 px-2 rounded text-xs"
                    onClick={() => {
                      setSelectedUser(user);
                      setIsModalOpen(true);
                    }}
                  >
                    Edit
                  </button>
                  <button 
                    className="bg-red-500 text-white py-1 px-2 rounded text-xs"
                    onClick={() => setUserToDelete(user)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="mt-4 flex flex-col md:flex-row justify-between items-center gap-4">
        <div className="text-sm text-gray-500 order-2 md:order-1">
          Showing {indexOfFirstUser + 1} to {Math.min(indexOfLastUser, filteredUsers.length)} of {filteredUsers.length} users
        </div>
        <div className="flex flex-wrap justify-center gap-2 order-1 md:order-2">
          <button
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className="text-sm px-3 py-1 border rounded disabled:opacity-50"
          >
            First
          </button>
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="text-sm px-3 py-1 border rounded disabled:opacity-50"
          >
            Previous
          </button>
          
          <div className="flex gap-1">
            {Array.from({ length: totalPages }, (_, i) => i + 1)
              .filter(page => {
                const buffer = 2;
                return page === 1 || 
                       page === totalPages || 
                       (page >= currentPage - buffer && page <= currentPage + buffer);
              })
              .map((page, index, array) => (
                <React.Fragment key={page}>
                  {index > 0 && array[index - 1] !== page - 1 && (
                    <span className="px-2 py-1 text-sm">...</span>
                  )}
                  <button
                    onClick={() => setCurrentPage(page)}
                    className={`px-3 py-1 border rounded text-sm ${
                      currentPage === page ? 'bg-blue-500 text-white' : ''
                    }`}
                  >
                    {page}
                  </button>
                </React.Fragment>
              ))}
          </div>

          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="text-sm px-3 py-1 border rounded disabled:opacity-50"
          >
            Next
          </button>
          <button
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
            className="text-sm px-3 py-1 border rounded disabled:opacity-50"
          >
            Last
          </button>
        </div>
      </div>

      {/* Modals */}
      {selectedUser && (
        <EditUserModal
          user={selectedUser}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedUser(null);
          }}
        />
      )}

      {userToDelete && (
        <ConfirmDeleteModal
          user={userToDelete}
          onConfirm={handleDeleteConfirm}
          onCancel={() => setUserToDelete(null)}
        />
      )}
    </div>
  );
};

export default UserOverview;