import React, { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import Login from '../auth/Login';

const emailAddress = "info@hirehub.com";

function Navbar({ auth: { isAuthenticated, isCompanyAuthenticated, isAdminAuthenticated, loading, user }, logout }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignUpNav, setShowSignUpNav] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const modalRef = useRef(null);
  const notificationRef = useRef(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = () => setShowLogin(true);
  const handleCloseLogin = useCallback(() => setShowLogin(false), []);
  const [notificationCount, setNotificationCount] = useState(3);

 
  const handleSignup = () => {
    console.log('Navigating to /register');
    setShowSignUpNav(true);
  };

  const handleLogout = () => {
    logout();
    navigate('/'); // Redirect to landing page after logout
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getAvatarUrl = () => {
    if (isCompanyAuthenticated && user && user.employerProfile) {
      return user.employerProfile.hrDetails.image.url;
    } else if (isAuthenticated && user && user.jobseekerProfile) {
      return user.jobseekerProfile.avatar.url;
    }
    return 'https://via.placeholder.com/32'; 
  };

  const avatarUrl = getAvatarUrl();

  const authLinks = (
    <ul className="flex space-x-4">
      <li>
        <button onClick={handleLogout} className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">
          <i className='fas fa-sign-out-alt' />{' '} 
          <span className='hide-sm'>Logout</span>
        </button>
      </li>
    </ul>
  );

  const companyAuthLinks = (
    <ul className="flex space-x-4">
      <li>
        <button onClick={handleLogout} className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">
          <i className='fas fa-sign-out-alt' />{' '} 
          <span className='hide-sm'>Logout</span>
        </button>
      </li>
    </ul>
  );

 

  const adminLinks = (
    <ul className="flex space-x-4">
      <li>
        <button onClick={handleLogout} className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">
          <i className='fas fa-sign-out-alt' />{' '} 
          <span className='hide-sm'>Logout</span>
        </button>
      </li>
    </ul>
  );

  if (
    location.pathname.startsWith('/company-dashboard') ||
    location.pathname.startsWith('/applicant-dashboard') ||
    location.pathname.startsWith('/admin-dashboard') ||
    location.pathname === '/company-profile-creation' ||
    location.pathname === '/fresher-profile-creation' ||
    location.pathname === '/experienced-profile-creation'
  ) {
    return (
        <nav className="fixed top-0 left-0 right-0 z-50 bg-white shadow-md">
          <div className="flex justify-between ml-8 mr-8 py-4">

        <div className="flex items-center text-2xl font-bold text-gray-800">
         
          HireHub
        </div>
        
        <div className="flex items-center">
  <div className="relative mr-6" ref={notificationRef}>
    <button 
      onClick={() => setShowNotifications(!showNotifications)} 
      className="text-xl focus:outline-none"
    >
      🔔
    </button>
    {notificationCount > 0 && (
      <span className="absolute -top-1 -right-1 bg-blue-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
        {notificationCount}
      </span>
    )}
    {showNotifications && (
      <div className="absolute right-[-100px] mt-2 w-64 sm:w-80 bg-white rounded-md shadow-lg overflow-hidden z-20 max-h-60 overflow-y-auto">
        <div className="py-2">
          <div className="px-4 py-2 bg-gray-100 font-semibold text-gray-800">Notifications</div>
          <div className="divide-y divide-gray-200">
            <div className="px-4 py-3 hover:bg-gray-50 transition duration-150 ease-in-out">
              <p className="text-sm font-medium text-gray-900">New job application</p>
              <p className="text-xs text-gray-500">John Doe applied for Software Developer position</p>
            </div>
            <div className="px-4 py-3 hover:bg-gray-50 transition duration-150 ease-in-out">
              <p className="text-sm font-medium text-gray-900">Interview scheduled</p>
              <p className="text-xs text-gray-500">Interview with Jane Smith on 24th August</p>
            </div>
            <div className="px-4 py-3 hover:bg-gray-50 transition duration-150 ease-in-out">
              <p className="text-sm font-medium text-gray-900">New message</p>
              <p className="text-xs text-gray-500">You have a new message from HR</p>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
  <div className="relative" ref={dropdownRef}>
    <button 
      onClick={() => setShowDropdown(!showDropdown)}
      className="flex items-center cursor-pointer focus:outline-none"
    >
      <img src={avatarUrl} alt="Account" className="w-8 h-8 rounded-full mr-2" />
      <span className="text-gray-700 text-sm"> ▼</span>
    </button>
    {showDropdown && (
      <div className="absolute right-[-20px] mt-2 w-48 bg-white rounded-md shadow-lg overflow-hidden z-20">
        <div className="py-1">
          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Profile</a>
          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Settings</a>
          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Help</a>
          <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Sign out</button>
        </div>
      </div>
    )}
  </div>
</div>

        </div>
      </nav>
    );
  }

  if (location.pathname === '/register') {
    return (
      <nav className="fixed top-0 left-0 right-0 z-50 bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-row justify-between items-center py-4 sm:py-6 sm:h-20">
            {/* Logo Section */}
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="text-xl sm:text-2xl font-bold">
                <span className="text-black">Hire</span>
                <span className="text-blue-600">Hub</span>
              </Link>
            </div>
  
            {/* Account and Login Section */}
            <div className="flex flex-row items-center">
           
              <Link 
                to="/login" 
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Log In
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
  
  

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white shadow-md">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="flex justify-between items-center h-16 md:h-20">
      <div className="flex items-center">
        <div className="flex-shrink-0 flex items-center">
          <Link to="/">
            <span className="text-2xl md:text-3xl font-bold">
              <span className="text-black">Hire</span>
              <span className="text-blue-600">Hub</span>
            </span>
          </Link>
        </div>
        <div className="hidden md:block ml-10">
          <div className="flex items-baseline space-x-4">
            <Link
              to="/jobs"
              className="text-gray-600 hover:text-blue-600 px-3 py-2 text-sm font-medium transition duration-150 ease-in-out"
            >
              Jobs
            </Link>
            <Link
              to="/companies"
              className="text-gray-600 hover:text-blue-600 px-3 py-2 text-sm font-medium transition duration-150 ease-in-out"
            >
              Companies
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden md:flex items-center space-x-4">
        <div className="flex flex-col items-center">
          <Link to="/register" onClick={handleSignup} className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-6 rounded-full transition duration-150 ease-in-out text-sm">
            Sign up
          </Link>
          <span className="text-xs text-gray-500 mt-1">New here?</span>
        </div>
        <div className="flex flex-col items-center">
          <button onClick={handleLogin} className="bg-blue-50 text-blue-600 hover:bg-blue-100 font-semibold py-2 px-6 rounded-full transition duration-150 ease-in-out text-sm">
            Log in
          </button>
          <span className="text-xs text-gray-500 mt-1">Already have an account?</span>
        </div>
      </div>
      <div className="md:hidden">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 transition duration-150 ease-in-out"
        >
          <span className="sr-only">Open main menu</span>
          {isMenuOpen ? (
            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          )}
        </button>
      </div>
    </div>
  </div>

  {isMenuOpen && (
  <div className="md:hidden bg-white shadow-lg rounded-b-lg">
    <div className="px-4 py-3 space-y-2">
      <Link
        to="/jobs"
        className="block text-gray-700 hover:text-blue-600 py-2 text-base font-medium transition duration-150 ease-in-out"
      >
        Jobs
      </Link>
      <Link
        to="/companies"
        className="block text-gray-700 hover:text-blue-600 py-2 text-base font-medium transition duration-150 ease-in-out"
      >
        Companies
      </Link>
    </div>
    <div className="px-4 py-4 space-y-3 border-t border-gray-200">
      <Link 
        to="/register" 
        onClick={handleSignup} 
        className="block w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-2.5 px-4 rounded-md text-center text-sm transition duration-150 ease-in-out"
      >
        Sign up
      </Link>
      <button 
        onClick={handleLogin} 
        className="block w-full bg-white text-blue-600 border border-blue-600 hover:bg-blue-50 font-semibold py-2.5 px-4 rounded-md text-center text-sm transition duration-150 ease-in-out"
      >
        Log in
      </button>
    </div>
  </div>
)}

  {isMenuOpen && (
    <div className="md:hidden">
      <div className="px-2 pt-2 pb-3 space-y-1">
        {!loading && (
          <Fragment>
            {isAuthenticated && authLinks}
            {isCompanyAuthenticated && companyAuthLinks}
            {isAdminAuthenticated && adminLinks}
            {!isCompanyAuthenticated && !isAuthenticated && !isAdminAuthenticated }
          </Fragment>
        )}
      </div>
    </div>
  )}
  {showLogin && <Login onClose={handleCloseLogin} />}
</nav>
  );
}


const mapStateToProps = state => ({
  auth: state.auth
});

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { logout })(Navbar);