import {
    GET_PROFILE,
    PROFILE_ERROR,
    CLEAR_PROFILE,
    UPDATE_PROFILE,
    JOBSEEKER_PROFILE_CREATE_SUCCESS,
    JOBSEEKER_PROFILE_CREATE_FAIL,
    FETCH_RESUME_SUCCESS,
    FETCH_RESUME_FAIL,
    UPDATE_RESUME_SUCCESS,
    UPDATE_RESUME_FAIL,
    RESUME_LOADING,
    SET_JOBSEEKER_AUTHENTICATED,
    JOBSEEKER_LOADED,
    SET_REDIRECT_TO_DASHBOARD
  } from '../actions/types';
  
  const initialState = {
    profile: null,
    currentResume: null,
    loading: false,
    error: null,
    isJobSeekerAuthenticated: false,
    redirectToDashboard: false
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
    
      case RESUME_LOADING:
        return {
          ...state,
          loading: true
        };
        case 'SET_LOADING':
      return {
        ...state,
        loading: payload
      };
      
      case GET_PROFILE:
      case UPDATE_PROFILE:
      case JOBSEEKER_PROFILE_CREATE_SUCCESS:
        console.log('Reducer: Handling GET_PROFILE/UPDATE_PROFILE/JOBSEEKER_PROFILE_CREATE_SUCCESS action', payload);
        return {
          ...state,
          profile: payload,
          loading: false,
          error: null
        };
        case JOBSEEKER_PROFILE_CREATE_SUCCESS:
          case JOBSEEKER_LOADED:
            return {
              ...state,
              profile: payload,
              isAuthenticated: true,
              loading: false,
            };
            case SET_REDIRECT_TO_DASHBOARD:
      return {
        ...state,
        redirectToDashboard: action.payload
      };
      case PROFILE_ERROR:
      case JOBSEEKER_PROFILE_CREATE_FAIL:
        console.log('Reducer: Handling PROFILE_ERROR/JOBSEEKER_PROFILE_CREATE_FAIL action', payload);
        return {
          ...state,
          profile: null,
          error: payload,
          loading: false
        };

        case SET_JOBSEEKER_AUTHENTICATED:
      return {
        ...state,
        isJobSeekerAuthenticated: payload
      };
      case CLEAR_PROFILE:
        return {
          ...state,
          profile: null,
          currentResume: null,
          loading: false,
          error: null
        };
      case FETCH_RESUME_SUCCESS:
      case UPDATE_RESUME_SUCCESS:
        return {
          ...state,
          currentResume: payload,
          loading: false,
          error: null
        };
      case FETCH_RESUME_FAIL:
      case UPDATE_RESUME_FAIL:
        return {
          ...state,
          error: payload,
          loading: false
        };
      default:
        return state;
    }
  }