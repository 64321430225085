import React from 'react'

const ProfileAbout = ({ profile }) => {
  return (
    <div className="p-4 sm:p-6 bg-[#F7F9FF] mt-6">
    <h2 className="text-2xl font-bold mb-6">About</h2>
    <div className="flex flex-col lg:flex-row">
      <div className="w-full lg:w-2/3 lg:pr-6 mb-6 lg:mb-0">
        <p className="text-gray-600">
          {profile.bio || 'No bio available'}
        </p>
      </div>
      <div className="w-full lg:w-1/3 space-y-4 text-base">
        <AboutItem icon="🏆" title="Experience" value={profile.isFresher ? 'Fresher' : `${profile.workExperiences.length} job(s)`} />
        <AboutItem icon="🎂" title="Age" value={profile.dateOfBirth ? calculateAge(profile.dateOfBirth) : 'Not specified'} />
        <AboutItem icon="👤" title="Gender" value={profile.gender || 'Not specified'} />
        <AboutItem icon="🎓" title="Education" value={profile.education.length > 0 ? profile.education[0].degree : 'Not specified'} />
      </div>
    </div>
  </div>
  )
}
const AboutItem = ({ icon, title, value }) => (
  <div className="flex items-center">
    <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mr-4 text-xl">
      {icon}
    </div>
    <div>
      <h3 className="font-semibold text-lg">{title}</h3>
      <p className="text-gray-600">{value}</p>
    </div>
  </div>
)

const calculateAge = (dateOfBirth) => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return `${age} years`;
}

export default ProfileAbout