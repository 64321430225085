import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ children, isAuthenticated, loading, profileCreated }) => {
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If the profile is created and we're on the profile creation page, redirect to dashboard
  if (profileCreated && location.pathname.includes('profile-creation')) {
    return <Navigate to="/applicant-dashboard" replace />;
  }

  return children;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  profileCreated: state.auth.profileCreated
});

export default connect(mapStateToProps)(PrivateRoute);