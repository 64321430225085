import {
    GET_JOBS,
    GET_EMPLOYER_JOBS,
    GET_JOB,
    POST_JOB_SUCCESS,
    DELETE_JOB,
    JOB_ERROR,
    POST_JOB_FAIL,
    GET_JOB_APPLICANTS,
    UPDATE_APPLICANT_STATUS,
    GET_JOB_APPLICANTS_SUCCESS,
    UPDATE_APPLICANT_STATUS_SUCCESS,
    GET_JOB_APPLICANTS_FAIL,
    UPDATE_APPLICANT_STATUS_FAIL,
    APPLY_JOB_SUCCESS,
    GET_APPLIED_JOBS,
    APPLY_JOB_FAIL,
    JOB_LOADING,
    TOGGLE_BOOKMARK,
    FETCH_JOBS_REQUEST,
    FETCH_JOBS_SUCCESS,
    FETCH_JOBS_FAILURE,
    UPDATE_JOB_STATUS
  } from '../actions/types';
  
  const initialState = {
    jobs: [],
    job: null,
    loading: false,
    error: null,
    applicants: [],
    jobSearchLoading: false,
    currentJobApplicants: [],
    applicantsLoading: false,
    applicantsError: null,
    appliedJobs: [],
    jobCount: 0,
    totalApplicants: 0,
    appliedJobsCount: 0
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case JOB_LOADING:
      case FETCH_JOBS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_JOBS:
      case FETCH_JOBS_SUCCESS:
        return {
          ...state,
          jobs: payload,
          loading: false,
          error: null
        };
      case GET_EMPLOYER_JOBS:
        return {
          ...state,
          jobs: payload.jobs,
          jobCount: payload.jobCount,
          totalApplicants: payload.totalApplicants,
          loading: false
        };
      case GET_JOB:
        return {
          ...state,
          job: payload,
          loading: false
        };
        case JOB_LOADING:
            return {
                ...state,
                jobSearchLoading: true,
                error: null
            };
      case POST_JOB_SUCCESS:
        return {
          ...state,
          jobs: [payload, ...state.jobs],
          loading: false
        };
      case DELETE_JOB:
        return {
          ...state,
          jobs: state.jobs.filter(job => job._id !== payload),
          loading: false
        };
        case UPDATE_JOB_STATUS:
        return {
          ...state,
          jobs: state.jobs.map(job =>
            job._id === payload._id ? { ...job, status: payload.status } : job
          ),
          loading: false
        };
      case JOB_ERROR:
      case POST_JOB_FAIL:
      case FETCH_JOBS_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false
        };
      case GET_JOB_APPLICANTS:
      case UPDATE_APPLICANT_STATUS:
        return { ...state, applicantsLoading: true };
      case GET_JOB_APPLICANTS_SUCCESS:
        return { ...state, applicants: payload, applicantsLoading: false, applicantsError: null };
      case UPDATE_APPLICANT_STATUS_SUCCESS:
        return {
          ...state,
          jobs: state.jobs.map(job =>
            job._id === payload.jobId
              ? {
                  ...job,
                  applicants: job.applicants.map(applicant =>
                    applicant.user._id === payload.applicantId
                      ? { ...applicant, ...payload.updatedApplicant }
                      : applicant
                  )
                }
              : job
          ),
          applicants: state.applicants.map(applicant =>
            applicant._id === payload._id ? payload : applicant
          ),
          applicantsLoading: false,
          applicantsError: null
        };
      case GET_JOB_APPLICANTS_FAIL:
      case UPDATE_APPLICANT_STATUS_FAIL:
        return { ...state, applicantsLoading: false, applicantsError: payload };
      case APPLY_JOB_SUCCESS:
        return {
          ...state,
          appliedJobs: [...state.appliedJobs, payload],
          loading: false
        };
      case GET_APPLIED_JOBS:
        return {
          ...state,
          appliedJobs: payload.jobs,
          appliedJobsCount: payload.count,
          loading: false
        };
      case TOGGLE_BOOKMARK:
        return {
          ...state,
          jobs: state.jobs.map(job =>
            job._id === payload ? { ...job, status: !job.status } : job
          )
        };
      default:
        return state;
    }
  }