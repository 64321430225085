// CompanyDashboard.js
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getCurrentEmployerProfile } from '../../../actions/employerProfileActions';
import CompanyDashboardHome from './CompanyDashboardHome';
import CompanySidebar from './SideBar';
import CompanyProfile from './CompanyProfile';
import PostJob from './PostJob';
import EditCompanyProfile from './EditCompanyProfile';
import JobListings from './JobListings';
import ApplicantsList from './JobApplicants';
import ResetPassword from './ResetPassword';
import LogOut from '../ApplicantDashboard/Logout';

const Spinner = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

const CompanyDashboard = ({
  getCurrentEmployerProfile,
  auth: { employer },
  profile: { profile, loading },
}) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  useEffect(() => {
    getCurrentEmployerProfile();
  }, [getCurrentEmployerProfile]);

  if (loading && profile === null) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
      <CompanySidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
      <div className="flex-1 md:ml-64 bg-white flex flex-col">
        <header className="bg-white shadow-sm p-3 md:hidden sticky top-0 z-10">
          <button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className="text-gray-500 focus:outline-none focus:text-gray-700"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </header>
        <main className="flex-grow p-3 sm:p-4 md:p-6 bg-gray-50">
          <div className="max-w-7xl mx-auto w-full">
            <Routes>
              <Route index element={<CompanyDashboardHome employer={employer} profile={profile} />} />
              <Route path="profile" element={<CompanyProfile profile={profile} />} />
              <Route path="post-job" element={<PostJob />} />
              <Route path="edit-profile" element={<EditCompanyProfile profile={profile} />} />
              <Route path="manage-jobs" element={<JobListings />} />
              <Route path="applicants" element={<ApplicantsList />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="logout" element={<LogOut />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
};

CompanyDashboard.propTypes = {
  getCurrentEmployerProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  getCurrentEmployerProfile,
})(CompanyDashboard);