import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const HRDetails = ({ nextStep,handleChange, values }) => {
    const [imagePreview, setImagePreview] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const continueStep = (e) => {
        e.preventDefault();
        nextStep();
      };
    
      const handleHRDetailsChange = (field) => (e) => {
        handleChange(field)(e.target.value);
      };

      const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file.size > 5 * 1024 * 1024) { // 5MB limit
          alert('File size should not exceed 5MB');
          return;
        }
        setFileToBase(file);
      }
    
      const setFileToBase = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          handleChange('image')(reader.result);
          setImagePreview(reader.result);
        };
      };
  
  
  return (
    <div className="w-full max-w-4xl bg-gray-100 mx-auto p-4 sm:p-6 lg:p-8 rounded-lg">
      <h2 className="text-2xl font-semibold mb-6">HR Details</h2>
      <form onSubmit={continueStep} className="space-y-6">
        
      <div className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer relative overflow-hidden">
          {imagePreview ? (
            <img src={imagePreview} alt="HR" className="w-full h-full object-cover" />
          ) : (
            <div className="text-center">
              <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p className="mt-1 text-sm text-gray-600">Upload HR Image</p>
            </div>
          )}
          <input
            type="file"
            accept=".png,.jpg,.jpeg"
            onChange={handleImageUpload}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div>
            <label htmlFor="hrName" className="block text-sm font-semibold text-gray-700 mb-1">
              Name
            </label>
            <input
              type="text"
              id="hrName"
              placeholder="Enter your name"
              onChange={handleHRDetailsChange('name')}
              value={values.name}
              required
              className="w-full px-3 py-2 border border-gray-300 text-sm placeholder:text-sm rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="hrEmail" className="block text-sm font-semibold text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              id="hrEmail"
              placeholder="Enter your email"
              onChange={handleHRDetailsChange('email')}
              value={values.email}
              required
              className="w-full px-3 py-2 border border-gray-300 text-sm placeholder:text-sm rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="hrPhone" className="block text-sm font-semibold text-gray-700 mb-1">
              Phone
            </label>
            <input
              type="tel"
              id="hrPhone"
              placeholder="Enter your number"
              onChange={handleHRDetailsChange('phone')}
              value={values.phone}
              className="w-full px-3 py-2 border border-gray-300 text-sm placeholder:text-sm rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="hrDesignation" className="block text-sm font-semibold text-gray-700 mb-1">
              Designation
            </label>
            <input
              type="text"
              id="hrDesignation"
              placeholder="Enter your designation"
              onChange={handleHRDetailsChange('designation')}
              value={values.designation}
              className="w-full px-3 py-2 border border-gray-300 text-sm placeholder:text-sm rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="hrExperience" className="block text-sm font-semibold text-gray-700 mb-1">
              Experience (years)
            </label>
            <input
              type="number"
              id="hrExperience"
              placeholder="Enter your experience"
              onChange={handleHRDetailsChange('experience')}
              value={values.experience}
              className="w-full px-3 py-2 border border-gray-300 text-sm placeholder:text-sm rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="hrLocality" className="block text-sm font-semibold text-gray-700 mb-1">
              Locality
            </label>
            <input
              type="text"
              id="hrLocality"
              placeholder="Enter locality"
              onChange={handleHRDetailsChange('locality')}
              value={values.locality}
              className="w-full px-3 py-2 border border-gray-300 text-sm placeholder:text-sm rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div>
          <label htmlFor="hrAddress1" className="block text-sm font-semibold text-gray-700 mb-1">
            Address line 1
          </label>
          <input
            type="text"
            id="hrAddress1"
            placeholder="Enter main address"
            onChange={handleHRDetailsChange('address1')}
            value={values.address1}
            className="w-full px-3 py-2 border border-gray-300 text-sm placeholder:text-sm rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label htmlFor="hrAddress2" className="block text-sm font-semibold text-gray-700 mb-1">
            Address line 2
          </label>
          <input
            type="text"
            id="hrAddress2"
            placeholder="Enter additional details/address"
            onChange={handleHRDetailsChange('address2')}
            value={values.address2}
            className="w-full px-3 py-2 border border-gray-300 text-sm placeholder:text-sm rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div>
            <label htmlFor="hrCity" className="block text-sm font-semibold text-gray-700 mb-1">
              City
            </label>
            <input
              type="text"
              id="hrCity"
              placeholder="Enter city name"
              onChange={handleHRDetailsChange('city')}
              value={values.city}
              className="w-full px-3 py-2 border border-gray-300 text-sm placeholder:text-sm rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label htmlFor="hrCountry" className="block text-sm font-semibold text-gray-700 mb-1">
              Country
            </label>
            <input
              type="text"
              id="hrCountry"
              placeholder="Enter country name"
              onChange={handleHRDetailsChange('country')}
              value={values.country}
              className="w-full px-3 py-2 border border-gray-300 text-sm placeholder:text-sm rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label htmlFor="hrPostal" className="block text-sm font-semibold text-gray-700 mb-1">
              Postal
            </label>
            <input
              type="text"
              id="hrPostal"
              placeholder="Enter your postal code"
              onChange={handleHRDetailsChange('postal')}
              value={values.postal}
              className="w-full px-3 py-2 border border-gray-300 text-sm placeholder:text-sm rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="hrState" className="block text-sm font-semibold text-gray-700 mb-1">
              State
            </label>
            <input
              type="text"
              id="hrState"
              placeholder="Enter your state"
              onChange={handleHRDetailsChange('state')}
              value={values.state}
              className="w-full px-3 py-2 border border-gray-300 text-sm placeholder:text-sm rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-600 text-white py-1 px-6 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Next →
          </button>
        </div>
      </form>
    </div>
  );
};

HRDetails.propTypes = {
    nextStep: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };
  
  export default HRDetails;