import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CompanyInformation = ({ prevStep, nextStep, handleChange, handleFileChange, values }) => {
  const [logoPreview, setLogoPreview] = useState(null);

  const continueStep = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div className="w-full max-w-4xl bg-gray-100 mx-auto p-4 sm:p-6 lg:p-8 rounded-lg">
      <h2 className="text-2xl font-semibold mb-6">Company Information</h2>
      <form onSubmit={continueStep} className="space-y-6">
        {/* <p className="text-sm text-gray-500 text-left">
          To upload file size is (Max 5Mb) and allowed file types are (.png, .jpg)
        </p> */}

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div>
            <label htmlFor="companyName" className="block text-sm font-semibold text-gray-700 mb-1">
              Company name
            </label>
            <input
              type="text"
              id="companyName"
              placeholder="Enter your company name"
              onChange={(e) => handleChange('name')(e.target.value)}
              value={values.name}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="industry" className="block text-sm font-semibold text-gray-700 mb-1">
              Industry
            </label>
            <select
              id="industry"
              onChange={(e) => handleChange('industry')(e.target.value)}
              value={values.industry}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select industry</option>
              <option value="Development">Development</option>
              <option value="Management">Management</option>
              <option value="Consultancy">Consultancy</option>
            </select>
          </div>
          <div>
            <label htmlFor="companySize" className="block text-sm font-semibold text-gray-700 mb-1">
              Company size
            </label>
            <select
              id="companySize"
              onChange={(e) => handleChange('size')(e.target.value)}
              value={values.size}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select company size</option>
              <option value="5-20">5 - 20 Employees</option>
              <option value="20-50">20 - 50 Employees</option>
              <option value="50+">More than 50 Employees</option>
            </select>
          </div>
          <div>
            <label htmlFor="companyWebsite" className="block text-sm font-semibold text-gray-700 mb-1">
              Website
            </label>
            <input
              type="url"
              id="companyWebsite"
              placeholder="Enter your company website"
              onChange={(e) => handleChange('website')(e.target.value)}
              value={values.website}
              className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="companyLinkedinUrl" className="block text-sm font-semibold text-gray-700 mb-1">
              Company LinkedIn URL
            </label>
            <input
              type="url"
              id="companyLinkedinUrl"
              placeholder="Enter company linkedin url"
              onChange={(e) => handleChange('linkedinUrl')(e.target.value)}
              value={values.linkedinUrl}
              className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div>
          <label htmlFor="companyDescription" className="block text-sm font-semibold text-gray-700 mb-1">
            Company Description
          </label>
          <textarea
            id="companyDescription"
            placeholder="Enter a brief description of your company"
            onChange={(e) => handleChange('description')(e.target.value)}
            value={values.description}
            rows="4"
            className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          ></textarea>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="agreeToTerms"
            onChange={(e) => handleChange('agreeToTerms')(e.target.checked)}
            checked={values.agreeToTerms}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor="agreeToTerms" className="ml-2 block text-sm text-gray-900">
            I agree to the terms and conditions
          </label>
        </div>

        <div className="flex flex-col sm:flex-row justify-between">
          <button
            type="button"
            onClick={prevStep}
            className="mb-2 sm:mb-0 bg-gray-300 text-gray-700 py-2 px-6 rounded-xl hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            ← Previous
          </button>
          <button
            type="submit"
            className="bg-blue-600 text-white py-1 px-6 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Next →
          </button>
        </div>
      </form>
    </div>
  );
};

CompanyInformation.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default CompanyInformation;