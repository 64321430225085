import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'


const PrivateEmployerRoutes = ({auth:{isEmployerAuthenticated, loading}}) => {
  return (
    isEmployerAuthenticated && !loading ? <Outlet /> : <Navigate to='/login' />
  )
}

PrivateEmployerRoutes.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(PrivateEmployerRoutes)