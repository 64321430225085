import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const AdminProtectedRoute = ({
    component: Component,
    auth: { isAdminAuthenticated, loading }
}) => {
    if (!isAdminAuthenticated && !loading) {
        return <Navigate to="/admin-login" />;
    }

    return <Component />;
};

AdminProtectedRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(AdminProtectedRoute);