import React from 'react';
import PropTypes from 'prop-types';

const Education = ({ prevStep, nextStep, handleChange, values }) => {
    const continueStep = (e) => {
        e.preventDefault();
        nextStep();
    };

    // Handle personal info changes for each field
    const handleEducationChange = (field) => (e) => {
        handleChange(field)(e.target.value);
    };

    return (
        <div className="bg-gray-100 p-4 sm:p-6 md:p-8 rounded-lg shadow-md max-w-4xl mx-auto">
            <h2 className="text-2xl font-semibold mb-4">Education</h2>
            <form onSubmit={continueStep} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="school" className="block text-sm font-semibold text-gray-700 mb-1">School/Institution</label>
            <input
              type="text"
              id="school"
              placeholder="Enter school/institution name"
              onChange={handleEducationChange('school')}
              value={values.school || ''}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="degree" className="block text-sm font-semibold text-gray-700 mb-1">Degree</label>
            <input
              type="text"
              id="degree"
              placeholder="Enter your degree"
              onChange={handleEducationChange('degree')}
              value={values.degree || ''}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="fieldofstudy" className="block text-sm font-semibold text-gray-700 mb-1">Field of Study</label>
            <input
              type="text"
              id="fieldofstudy"
              placeholder="Enter your field of study"
              onChange={handleEducationChange('fieldofstudy')}
              value={values.fieldofstudy || ''}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="from" className="block text-sm font-semibold text-gray-700 mb-1">Start Date</label>
            <input
              type="date"
              id="from"
              onChange={handleEducationChange('from')}
              value={values.from || ''}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="to" className="block text-sm font-semibold text-gray-700 mb-1">End Date</label>
            <input
              type="date"
              id="to"
              onChange={handleEducationChange('to')}
              value={values.to || ''}
              className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
                <div>
                    <label htmlFor="percentageCGPA" className="block text-sm font-semibold text-gray-700 mb-1">Percentage/CGPA (Optional)</label>
                    <input
                        type="text"
                        id="percentageCGPA"
                        placeholder="Enter your percentage/CGPA"
                        onChange={handleEducationChange('percentageCGPA')}
                        value={values.percentageCGPA}
                        className="w-full px-3 py-2 border border-gray-300 rounded-xl text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="flex justify-between">
                    <button
                        type="button"
                        onClick={prevStep}
                        className="mb-2 sm:mb-0 bg-gray-300 text-gray-700 py-2 px-6 rounded-xl hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    >
                        ← Previous
                    </button>
                    <button
                        type="submit"
                        className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition duration-300"
                    >
                        Next →
                    </button>
                </div>
            </form>
        </div>
    );
};

Education.propTypes = {
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired
};

export default Education;
