import axios from 'axios';
import { setAlert } from './alert';
import {
    GET_JOBS,
    GET_JOB,
    JOB_ERROR,
    DELETE_JOB,
    POST_JOB_SUCCESS,
    POST_JOB_FAIL,
    GET_EMPLOYER_JOBS,
    GET_JOB_APPLICANTS,
    GET_JOB_APPLICANTS_SUCCESS,
    GET_JOB_APPLICANTS_FAIL,
    UPDATE_APPLICANT_STATUS,
    UPDATE_APPLICANT_STATUS_SUCCESS,
    UPDATE_APPLICANT_STATUS_FAIL,
    GET_APPLIED_JOBS,
    APPLY_JOB_SUCCESS,
    APPLY_JOB_FAIL,
    JOB_LOADING,
    FETCH_JOBS_SUCCESS,
    FETCH_JOBS_FAILURE,
    FETCH_JOBS_REQUEST,
    RESUME_LOADING,
    FETCH_RESUME_SUCCESS,
    FETCH_RESUME_FAIL,
    UPDATE_RESUME_SUCCESS,
    UPDATE_RESUME_FAIL

  
 
} from './types';

// Get all jobs
export const getJobs = (filters) => async (dispatch) => {
    try {
      dispatch({ type: JOB_LOADING });
      const { data } = await axios.get('/api/jobs', { params: filters });
      dispatch({
        type: GET_JOBS,
        payload: data
      });
    } catch (err) {
      dispatch({
        type: JOB_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };

  export const fetchJobs = (searchTerm, jobType, experience) => async (dispatch) => {
    dispatch({ type: FETCH_JOBS_REQUEST });
    try {
      const response = await axios.post('/api/jobs/filterJobs', {
        data: {
          jobTitle: searchTerm,
          experienceLevel: experience,
          jobType: jobType,
        }
      });
      dispatch({ type: FETCH_JOBS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_JOBS_FAILURE, payload: error.message });
    }
  };
  
  

// Get job by ID
export const getJobById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/jobs/${id}`);

    dispatch({
      type: GET_JOB,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: JOB_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get employer jobs
export const getEmployerJobs = () => async dispatch => {
    try {
        const res = await axios.get('/api/jobs/employer');
        console.log("API response data:", res.data);
        dispatch({
          type: GET_EMPLOYER_JOBS,
          payload: res.data
        });
        console.log("Dispatched GET_EMPLOYER_JOBS action");
    } catch (err) {
      console.error("Error in getEmployerJobs:", err.response ? err.response.data : err.message);
      dispatch({
        type: JOB_ERROR,
        payload: { msg: err.response ? err.response.statusText : 'Server Error', status: err.response ? err.response.status : 500 }
      });
      console.log("Dispatched JOB_ERROR action");
    }
  };

// Delete job
export const deleteJob = id => async dispatch => {
  try {
    await axios.delete(`/api/jobs/${id}`);

    dispatch({
      type: DELETE_JOB,
      payload: id
    });

    dispatch(setAlert('Job Removed', 'success'));
  } catch (err) {
    dispatch({
      type: JOB_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post a new job
export const postJob = (formData) => async (dispatch, getState) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const { profile } = getState().profile;
    
    const jobData = {
      ...formData,
      employerProfile: profile._id,
      status: 'pending' // Explicitly set status
    };

    const res = await axios.post('/api/jobs', jobData, config);

    dispatch({
      type: POST_JOB_SUCCESS,
      payload: res.data
    });

    dispatch(setAlert('Job Posted Successfully - Waiting for Admin Approval', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: POST_JOB_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const getJobApplicants = (jobId) => async (dispatch) => {
    dispatch({ type: GET_JOB_APPLICANTS });
  
    try {
      const res = await axios.get(`/api/jobs/${jobId}/applicants`);
  
      dispatch({
        type: GET_JOB_APPLICANTS_SUCCESS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: GET_JOB_APPLICANTS_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };
  



  export const applyForJob = (jobId) => async (dispatch) => {
    try {
      const res = await axios.post(`/api/jobs/${jobId}/apply`);
  
      dispatch({
        type: APPLY_JOB_SUCCESS,
        payload: res.data
      });
  
      dispatch(setAlert('Application submitted successfully', 'success'));
    } catch (err) {
      dispatch({
        type: APPLY_JOB_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };

export const updateApplicantStatus = (jobId, applicantId, status) => async (dispatch) => {
    try {
      const res = await axios.put(`/api/jobs/${jobId}/applicants/${applicantId}`, { status });
  
      dispatch({
        type: UPDATE_APPLICANT_STATUS_SUCCESS,
        payload: { jobId, applicantId, updatedApplicant: res.data }
      });
    } catch (err) {
      dispatch({
        type: UPDATE_APPLICANT_STATUS_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };



  export const getAppliedJobs = () => async (dispatch) => {
    try {
      const res = await axios.get('/api/jobs/applied');
  
      dispatch({
        type: GET_APPLIED_JOBS,
        payload: {
          jobs: res.data,
          count: res.data.length
        }
      });
    } catch (err) {
      dispatch({
        type: JOB_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };


// Fetch current resume
export const fetchCurrentResume = () => async (dispatch) => {
    try {
      dispatch({ type: RESUME_LOADING });
  
      const res = await axios.get('/api/jobseekerProfile/resume');
  
      dispatch({
        type: FETCH_RESUME_SUCCESS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: FETCH_RESUME_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };
  
  // Update resume
  export const updateResume = (formData) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
  
      const res = await axios.put('/api/jobseekerProfile/resume', formData, config);
  
      dispatch({
        type: UPDATE_RESUME_SUCCESS,
        payload: res.data
      });
  
      dispatch(setAlert('Resume updated successfully', 'success'));
    } catch (err) {
      dispatch({
        type: UPDATE_RESUME_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
  
      dispatch(setAlert('Failed to update resume', 'danger'));
    }
  };