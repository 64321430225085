import React from 'react';
import { NavLink } from 'react-router-dom';

function Sidebar({ isOpen, setIsOpen }) {
  return (
    <>
      <div
        className={`fixed inset-0 bg-white bg-opacity-75 z-20 md:hidden transition-opacity duration-300 ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setIsOpen(false)}
      ></div>
     <aside
  className={`fixed top-0 left-0 z-40 w-64 h-screen pt-16 transition-transform duration-300 transform ${
    isOpen ? 'translate-x-0' : '-translate-x-full'
  } md:translate-x-0 bg-white shadow-lg`}
>
        <div className="h-full px-4 py-6 overflow-y-auto">
          <ul className="space-y-4">
            <SidebarItem to="/applicant-dashboard" icon="fas fa-th-large" text="Dashboard" highlight={true} />
            <SidebarItem to="/applicant-dashboard/profile" icon="fas fa-user" text="My Profile" />
            <SidebarItem to="/applicant-dashboard/upload-resume" icon="fas fa-file-alt" text="Resume" />
            <SidebarItem to="/applicant-dashboard/applied-jobs" icon="fas fa-briefcase" text="Jobs Applied" />
            <SidebarItem to="/applicant-dashboard/job-listings" icon="fas fa-briefcase" text="Job Search" />
            <SidebarItem to="/applicant-dashboard/reset-password" icon="fas fa-key" text="Reset Password" />
            <SidebarItem to="/applicant-dashboard/logout" icon="fas fa-sign-out-alt" text="Logout" />

          </ul>
        </div>
      </aside>
    </>
  );
}

const SidebarItem = ({ to, icon, text, highlight }) => (
  <li>
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center font-extralight p-2 rounded-lg transition duration-75 ${
          isActive || highlight ? 'bg-blue-50 text-blue-500' : 'text-gray-500 hover:bg-gray-100'
        }`
      }
    >
      {({ isActive }) => (
        <>
          <i
            className={`${icon} w-5 h-5 ${
              isActive || highlight ? 'text-blue-500' : 'text-gray-400'
            }`}
          ></i>
          <span className="ml-3 text-lg font-extralight">{text}</span>
        </>
      )}
    </NavLink>
  </li>
);

export default Sidebar;