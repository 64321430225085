
import React, { useState ,useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate, useNavigate } from 'react-router-dom';

import { createEmployerProfile } from '../../../actions/employerProfileActions';
import HRDetails from './HrDetails';
import CompanyInformation from './CompanyInformation';
import ContactInformation from './CompanyContactInfo';
import CompanyAddress from './CompanyAddressInfo';
import FinishSetup from './FinishSetup';

const EmployerProfileSetup = ({ 
    createEmployerProfile,
    auth: { isEmployerAuthenticated, loading: authLoading,employer }
  }) => {
  const initialFormData = {
    employerId: '',
    hrDetails: {
      name: '',
      email: '',
      phone: '',
      designation: '',
      experience: '',
      locality: '',
      image: '',
   
    },
    companyInfo: {
 
      name: '',
      industry: '',
      size: '',
      website: '',
      linkedinUrl: '',
      description: '',
    },
    contactInfo: {
      name: '',
      email: '',
      phone: '',
      jobTitle: '',
    },
    companyAddress: {
      address1: '',
      address2: '',
      country: '',
      city: '',
      state: '',
      postalCode: '',
    }
  };

  
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState(null);
  const [contentHeight, setContentHeight] = useState(0);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (employer && employer._id) {
      setFormData(prevData => ({
        ...prevData,
        employerId: employer._id
      }));
    }
  }, [employer]);
  
  useEffect(() => {
    // Adjust content height when step changes
    setContentHeight(0);
    setTimeout(() => {
      const content = document.getElementById('step-content');
      if (content) {
        setContentHeight(content.scrollHeight);
      }
    }, 50);
  }, [step]);
  
  if (authLoading) {
    return <div>Loading...</div>;
  }
  
  if (!isEmployerAuthenticated && !authLoading) {
    return <Navigate to="/login" />;
  }
  
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

 
  
  const handleChange = (section) => (field) => (value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form values before submission:", formData);
    setError(null);
    try {
      const response = await createEmployerProfile(formData);
      console.log("Response from createEmployerProfile:", response);
      navigate('/company-dashboard');
    } catch (error) {
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
      setError('An error occurred while submitting the form. Please try again.');
    }
  };
  

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
            <HRDetails
              nextStep={nextStep}
              handleChange={handleChange('hrDetails')}
              values={formData.hrDetails}
            />
          );
      case 2:
        return (
          <CompanyInformation
            prevStep={prevStep}
            nextStep={nextStep}
            handleChange={handleChange('companyInfo')}

            values={formData.companyInfo}
          />
        );
      case 3:
        return (
          <ContactInformation
            prevStep={prevStep}
            nextStep={nextStep}
            handleChange={handleChange('contactInfo')}
            values={formData.contactInfo}
          />
        );
      case 4:
        return (
          <CompanyAddress
            prevStep={prevStep}
            nextStep={nextStep}
            handleChange={handleChange('companyAddress')}
            values={formData.companyAddress}
          />
        );
      case 5:
        return (
          <FinishSetup
            handleSubmit={handleSubmit}
            values={formData}
            handleChange={handleChange}
          />
        );
      default:
        return null;
    }
  };

  const steps = [
    { label: 'HR Details', number: 1 },
    { label: 'Company Information', number: 2 },
    { label: 'Contact Information', number: 3 },
    { label: 'Company Address', number: 4 },
    { label: 'Finish', number: 5 },
  ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col md:flex-row">
      {/* Mobile Navigation */}
      <div className="md:hidden w-full bg-white shadow-md mb-4 overflow-x-auto">
        <div className="flex items-center px-6 py-4">
          {steps.map((s, index) => (
            <React.Fragment key={s.number}>
              <div className="flex flex-col items-center">
                <div className={`w-8 h-8 rounded-full flex items-center justify-center text-sm font-light ${
                  step >= s.number ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-600'
                }`}>
                  {s.number}
                </div>
              </div>
              {index < steps.length - 1 && (
                <div className="w-full h-1 bg-gray-300 mx-2">
                  <div 
                    className="h-full bg-green-500 transition-all duration-300 ease-in-out"
                    style={{ width: step > s.number ? '100%' : '0%' }}
                  ></div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Desktop Navigation */}
      <div className="hidden md:block md:w-1/4 bg-gray-100 fixed h-full overflow-y-auto">
        <div className="py-24 px-6">
          {steps.map((s, index) => (
            <div key={s.number} className="flex items-center mb-8 text-sm">
              <div className="relative">
                <div className={`w-8 h-8 rounded-full flex items-center justify-center font-semibold ${
                  step >= s.number ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-600'
                }`}>
                  {s.number}
                </div>
                {index < steps.length - 1 && (
                  <div className={`absolute left-4 top-8 w-0.5 h-12 -ml-px ${
                    step > s.number ? 'bg-green-500' : 'bg-gray-200'
                  }`} />
                )}
              </div>
              <span className="ml-4 text-gray-700 font-medium">{s.label}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Content */}
      <div className="w-full md:w-3/4 md:ml-auto p-2 md:p-4"> 
        <div className="bg-white rounded-lg shadow-md p-4 md:p-6"> 
          <h1 className="text-2xl md:text-3xl font-bold mb-4 text-center">Set up your Profile</h1>
          <div className="flex justify-center">
            <div className="bg-blue-600 text-white px-3 py-1.5 rounded-full inline-block mb-4 md:mb-5 font-semibold"> 
              Employer
            </div>
          </div>
          {error && <div className="text-red-500 text-center mb-4">{error}</div>}
          <div 
            id="step-content"
            className="overflow-hidden transition-all duration-500 ease-in-out"
            style={{ maxHeight: `${contentHeight}px` }}
          >
            {renderStep()}
          </div>
        </div>
      </div>
    </div>
  );
};
EmployerProfileSetup.propTypes = {
    createEmployerProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth
  });
  
  export default connect(mapStateToProps, { createEmployerProfile })(EmployerProfileSetup);