import React, { useState } from 'react';
import { connect } from 'react-redux';
import { XCircleIcon } from '@heroicons/react/solid';
import { postJob } from '../../../actions/jobActions';

const PostJob = ({ postJob }) => {
  const [formData, setFormData] = useState({
    jobTitle: '',
    jobType: '',
    industry: '',
    offeredSalary: '',
    applicationDeadline: '',
    location: '',
    qualification: '',
    experience: '',
    experienceLevel: '',
    interviewVenue: '',
    keySkills: [],
    jobHighlights: '',
    jobDescription: ''
  });

  const [skillInput, setSkillInput] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddSkill = () => {
    if (skillInput && !formData.keySkills.includes(skillInput)) {
      setFormData({ ...formData, keySkills: [...formData.keySkills, skillInput] });
      setSkillInput('');
    }
  };

  const handleRemoveSkill = (skill) => {
    setFormData({
      ...formData,
      keySkills: formData.keySkills.filter((s) => s !== skill)
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postJob(formData);
      setSubmissionStatus('Job posted successfully and pending admin approval');
      setFormData({
      jobTitle: '',
      jobType: '',
      industry: '',
      offeredSalary: '',
      applicationDeadline: '',
      location: '',
      qualification: '',
      experience: '',
      experienceLevel: '', 
      interviewVenue: '',
      keySkills: [],
      jobHighlights: '',
      jobDescription: ''
    });
  } catch (err) {
    setSubmissionStatus('Error posting job. Please try again.');
  }
};


  return (
    <div className="max-w-6xl mx-auto p-4 bg-white rounded-lg">
      <h1 className="text-3xl font-bold mb-6">Post a New Job</h1>
      {submissionStatus && (
        <div className={`p-4 mb-4 rounded-lg ${
          submissionStatus.includes('Error') ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'
        }`}>
          {submissionStatus}
        </div>
      )}
      <form onSubmit={handleSubmit} >
      <div className="px-2 pt-2 pb-4 mb-4 bg-gray-50 md:px-8 md:pt-6 md:pb-8">

          <h2 className="text-xl font-semibold mb-4">Job Details</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2 md:p-4">

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="jobTitle">
                Job Title
              </label>
              <input
                className="shadow appearance-none border-gray-200  rounded-lg text-base placeholder:text-base w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="jobTitle"
                type="text"
                placeholder="Enter job title"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="jobType">
                Job Type
              </label>
              <select
                className="shadow appearance-none border-gray-200  rounded-lg text-base placeholder:text-base w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="jobType"
                name="jobType"
                value={formData.jobType}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>Select Job Type</option>
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Contract">Contract</option>
                <option value="Temporary">Temporary</option>
                <option value="Internship">Internship</option>
                <option value="Freelance">Freelance</option>
                <option value="Volunteer">Volunteer</option>
              </select>
            </div>
            <div>
            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="experienceLevel">
              Experience Level
            </label>
            <select
              className="shadow appearance-none border-gray-200 rounded-lg text-base placeholder:text-base w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="experienceLevel"
              name="experienceLevel"
              value={formData.experienceLevel}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>Select Experience Level</option>
              <option value="Entry">Entry Level</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Senior">Senior</option>
            </select>
          </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="industry">
                Industry
              </label>
              <input
                className="shadow appearance-none border-gray-200  rounded-lg text-base placeholder:text-base w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="industry"
                type="text"
                placeholder="Enter industry name"
                name="industry"
                value={formData.industry}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="offeredSalary">
                Offered salary (in LPA)
              </label>
              <input
                className="shadow appearance-none border-gray-200  rounded-lg text-base placeholder:text-base w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="offeredSalary"
                type="text"
                placeholder="Enter salary"
                name="offeredSalary"
                value={formData.offeredSalary}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="applicationDeadline">
                Application Deadline Date
              </label>
              <input
                className="shadow appearance-none border-gray-200  rounded-lg text-base placeholder:text-base w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="applicationDeadline"
                type="date"
                name="applicationDeadline"
                value={formData.applicationDeadline}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="location">
                Location
              </label>
              <input
                className="shadow appearance-none border-gray-200  rounded-lg text-base placeholder:text-base w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="location"
                type="text"
                placeholder="Enter location"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="qualification">
                Qualification
              </label>
              <input
                className="shadow appearance-none border-gray-200  rounded-lg text-base placeholder:text-base w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="qualification"
                type="text"
                placeholder="Enter qualification"
                name="qualification"
                value={formData.qualification}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="experience">
                Experience (in Years)
              </label>
              <input
                className="shadow appearance-none border-gray-200  rounded-lg text-base placeholder:text-base w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="experience"
                type="text"
                placeholder="Enter experience"
                name="experience"
                value={formData.experience}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="md:col-span-2">
    
     
        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="interviewVenue">
          Interview Venue
        </label>
        <input
          className="shadow appearance-none border-gray-200  rounded-lg text-base placeholder:text-base w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="interviewVenue"
          type="text"
          placeholder="Enter location"
          name="interviewVenue"
          value={formData.interviewVenue}
          onChange={handleInputChange}
          required
        />
    
      
        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="keySkills">
          Key Skills
        </label>
        <div className="flex">
          <input
            className="shadow appearance-none border-gray-200 rounded-lg text-base placeholder:text-base w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none "
            id="keySkills"
            type="text"
            placeholder="Add Skills"
            value={skillInput}
            onChange={(e) => setSkillInput(e.target.value)}
          />
          <button
            type="button"
            onClick={handleAddSkill}
            className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
          >
            Add
          </button>
        
      </div>
    
  </div>
          </div>

          <div className="flex flex-wrap">
      {formData.keySkills.map((skill) => (
        <span
          key={skill}
          className="bg-gray-200 rounded-lg px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 flex items-center"
        >
          {skill}
          <XCircleIcon
            className="h-5 w-5 ml-1 text-red-500 cursor-pointer"
            onClick={() => handleRemoveSkill(skill)}
          />
        </span>
      ))}
    </div>
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="jobHighlights">
              Job Highlights (Optional)
            </label>
            <textarea
              className="shadow appearance-none  rounded-lg text-base placeholder:text-base w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="jobHighlights"
              placeholder="Add job highlights"
              name="jobHighlights"
              value={formData.jobHighlights}
              onChange={handleInputChange}
              rows="3"
            ></textarea>
          </div>

          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="jobDescription">
              Job Description
            </label>
            <textarea
              className="shadow appearance-none  rounded-lg text-base placeholder:text-base w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="jobDescription"
              placeholder="Write about your job"
              name="jobDescription"
              value={formData.jobDescription}
              onChange={handleInputChange}
              rows="5"
              required
            ></textarea>
          </div>
        </div>

        <div className="flex justify-center mt-4">
          <button
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg shadow-lg focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105"
            type="submit"
          >
            Post
          </button>
        </div>
      </form>
    </div>
  );
};

export default connect(null, { postJob })(PostJob);