import React, { useState } from 'react';

const ResetPassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your password reset logic here
    console.log('Password reset submitted');
  };

  // const handleResetPassword = async (e) => {
  //   e.preventDefault();

  //   if (newPassword !== confirmPassword) {
  //     alert('New passwords do not match');
  //     return;
  //   }
  
  //   // Here you would typically send the password reset request to your backend
  //   // This is a placeholder for that functionality
  //   console.log('Resetting password');

  //   // Example of how you might send the reset request to a server
  //   // try {
  //   //   const response = await fetch('/api/reset-password', {
  //   //     method: 'POST',
  //   //     headers: {
  //   //       'Content-Type': 'application/json',
  //   //     },
  //   //     body: JSON.stringify({ oldPassword, newPassword }),
  //   //   });
  //   //   if (response.ok) {
  //   //     alert('Password reset successfully');
  //   //   } else {
  //   //     alert('Password reset failed');
  //   //   }
  //   // } catch (error) {
  //   //   console.error('Error resetting password:', error);
  //   //   alert('Password reset failed');
  //   // }
  // };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8 bg-white rounded-lg">
    <h1 className="text-2xl font-bold mb-6">Reset Password</h1>
    <div className="max-w-3xl mx-auto px-4 py-8 bg-white rounded-lg">
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label htmlFor="oldPassword" className="block text-sm font-semibold text-gray-700 mb-1">
          Old Password
        </label>
        <input
          type="password"
          id="oldPassword"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          placeholder="Enter old password"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm placeholder: text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="newPassword" className="block text-sm font-semibold text-gray-700 mb-1">
          New password
        </label>
        <input
          type="password"
          id="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Enter new password"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="mb-6">
        <label htmlFor="confirmPassword" className="block text-sm font-semibold text-gray-700 mb-1">
          Confirm password
        </label>
        <input
          type="password"
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Enter new password again"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="flex justify-center">
        <button
          type="submit"
          className="inline-block bg-blue-600 text-white py-2 px-8 rounded-lg text-sm placeholder:text-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Reset
        </button>
      </div>
    </form>
    </div>
  </div>
  );
};

export default ResetPassword;