import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import { updateApplicantStatus } from '../../../actions/jobActions';
import ApplicantProfileOverlay from './ApplicantsProfileOverlay';

const ApplicantsList = ({ jobs, updateApplicantStatus }) => {
  const [selectedProfile, setSelectedProfile] = useState(null);

  const handleUpdateStatus = (jobId, applicantId, status) => {
    updateApplicantStatus(jobId, applicantId, status);
  };

  const handleViewProfile = (profile) => {
    setSelectedProfile(profile);
  };

  const closeOverlay = () => {
    setSelectedProfile(null);
  };

  const formatAppliedDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const applicants = jobs.flatMap((job) =>
    job.applicants.map((applicant) => ({
      ...applicant,
      jobTitle: job.jobTitle,
      jobId: job._id,
    }))
  );

  return (
    <div className="max-w-7xl bg-white rounded-xl mx-auto px-4 py-8">
  <h1 className="text-3xl font-semibold mb-2">All Applicants</h1>
  <p className="text-gray-600 text-base mb-6">Applicants who applied for a job</p>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    {applicants.map((applicant, index) => (
      <div key={index} className="bg-white rounded-lg shadow-md p-6 flex flex-col h-full">
        <div className="flex items-start mb-4">
          <img
            src={applicant.user.avatar || '/default-avatar.png'}
            alt={applicant.user.name}
            className="w-16 h-16 rounded-full mr-4 flex-shrink-0"
          />
          <div className="flex-grow min-w-0">
            <h2 className="text-xl font-semibold mb-2 truncate">{applicant.user.name}</h2>
            <p className="text-sm mb-2">
              <span className="text-blue-600">{applicant.user.title || 'UI/UX Designer'}</span>
              <span className="text-gray-500 ml-2">• {applicant.user.experience || '2 yr+ experience'}</span>
            </p>
            <p className="text-sm mb-2">
              <span className="text-gray-500">Applied for</span>
              <span className="text-blue-500 ml-1">{applicant.jobTitle}</span>
            </p>
            <div className="flex items-center text-gray-500 text-xs flex-wrap">
              <FaMapMarkerAlt className="mr-1" />
              <span className="mr-2">{applicant.user.city || 'London, UK'}</span>
              <FaClock className="mr-1 ml-2" />
              <span>Applied: {formatAppliedDate(applicant.appliedDate) || formatAppliedDate('2024-12-04')}</span>
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center">
          <div>
            {applicant.status === 'pending' && (
              <div className="space-x-2">
                <button
                  onClick={() => handleUpdateStatus(applicant.jobId, applicant.user._id, 'accepted')}
                  className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded text-sm transition duration-300"
                >
                  Accept
                </button>
                <button
                  onClick={() => handleUpdateStatus(applicant.jobId, applicant.user._id, 'rejected')}
                  className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded text-sm transition duration-300"
                >
                  Reject
                </button>
              </div>
            )}
            {applicant.status === 'accepted' && (
              <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs">Accepted</span>
            )}
            {applicant.status === 'rejected' && (
              <span className="bg-red-100 text-red-800 px-2 py-1 rounded-full text-xs">Rejected</span>
            )}
          </div>
          <button
            onClick={() => handleViewProfile(applicant.profile)}
            className="text-green-500 hover:text-green-700 text-sm underline"
          >
            View Profile
          </button>
        </div>
      </div>
    ))}
  </div>
  {selectedProfile && (
    <ApplicantProfileOverlay profile={selectedProfile} onClose={closeOverlay} />
  )}
</div>
  );
};

const mapStateToProps = (state) => ({
  jobs: state.job.jobs,
});

export default connect(mapStateToProps, { updateApplicantStatus })(ApplicantsList);
