// actions/analyticsActions.js
import axios from 'axios';
import {
  GET_ANALYTICS_REQUEST,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAIL,
  GET_MONTHLY_STATS_REQUEST,
  GET_MONTHLY_STATS_SUCCESS,
  GET_MONTHLY_STATS_FAIL,
  GET_DASHBOARD_COUNTS_REQUEST,
  GET_DASHBOARD_COUNTS_SUCCESS,
  GET_DASHBOARD_COUNTS_FAIL
} from './types';

// Get dashboard analytics
export const getDashboardAnalytics = () => async dispatch => {
  try {
    dispatch({ type: GET_ANALYTICS_REQUEST });

    const res = await axios.get('/api/admin/analytics/dashboard');

    dispatch({
      type: GET_ANALYTICS_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_ANALYTICS_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get monthly statistics
export const getMonthlyStats = () => async dispatch => {
  try {
    dispatch({ type: GET_MONTHLY_STATS_REQUEST });

    const res = await axios.get('/api/admin/analytics/monthly');

    dispatch({
      type: GET_MONTHLY_STATS_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_MONTHLY_STATS_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const getDashboardCounts = () => async dispatch => {
    try {
      dispatch({ type: GET_DASHBOARD_COUNTS_REQUEST });
  
      const res = await axios.get('/api/admin/dashboard/counts');
  
      dispatch({
        type: GET_DASHBOARD_COUNTS_SUCCESS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: GET_DASHBOARD_COUNTS_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };