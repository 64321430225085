  import React, { useEffect, useState } from 'react';
  import { connect, useDispatch } from 'react-redux';
  import PropTypes from 'prop-types';
  import { Navigate, useNavigate } from 'react-router-dom';

  import { createJobseekerProfile } from '../../../actions/jobseekerProfile';
  import { setAlert } from '../../../actions/alert'; 
  import PersonalInfo from '../PersonalInfo';
  import Skills from '../AddSkills';
  import ResumeUpload from '../ResumeUpload'; 
  import Education from '../EducationDetails';
  import WorkExperience from './WorkExperience';
  import FinishSetup from '../FinishSetup';

  const ExperiencedProfileSetup = ({ 
      createJobseekerProfile,
      setAlert,
      auth: { isAuthenticated, loading: authLoading,jobseeker }
  }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

      const initialFormData = {
          personalInfo: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            address1: '',
            address2: '',
            country: '',
            city: '',
            postalCode: '',
            stateProvince: '',
            dateOfBirth: '',
            gender: '',
            avatar: '',
          },
          education: [{
            highestQualification: '',
            institutionName: '',
            fieldOfStudy: '',
            yearOfGraduation: '',
            percentageCGPA: ''
          }],
          workExperiences: [],
          skills: [],
          resume: null,
          isFresher: false
        };

    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState(initialFormData);
    const [error, setError] = useState(null);
  
    const [profileCreated, setProfileCreated] = useState(false);

    useEffect(() => {
      if (jobseeker && jobseeker._id) {
        setFormData(prevData => ({
          ...prevData,
          jobseekerId: jobseeker._id
        }));
      }
    }, [jobseeker]);

 
    if (authLoading) {
      return <div>Loading...</div>;
    }

    if (!isAuthenticated && !authLoading) {
      return <Navigate to="/login" />;
    }

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);
    

    const handleChange = (section) => (field) => (value) => {
      setFormData((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [field]: value,
        },
      }));
    };

  
    const handleWorkExperienceChange = (experiences) => {
      setFormData((prevData) => ({
        ...prevData,
        workExperiences: experiences,
      }));
    };

    const handleSkillsChange = (skills) => {
      setFormData((prevData) => ({
        ...prevData,
        skills: skills,
      }));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const profileData = {
          ...formData.personalInfo,
          education: JSON.stringify(formData.education),
          workExperiences: JSON.stringify(formData.workExperiences),
          skills: JSON.stringify(formData.skills),
          isFresher: formData.isFresher,
          resume: formData.resume
        };
          const result = await createJobseekerProfile(profileData, formData.isFresher);
      
        
        // Navigate to the applicant dashboard after successful profile creation
        navigate('/applicant-dashboard', { state: { profileCreated: true }, replace: true });
      } catch (error) {
        console.error('Error submitting form:', error.response ? error.response.data : error.message);
        setError('An error occurred while submitting the form. Please try again.');
        setAlert('Error creating profile. Please try again.', 'danger');
      }
    };
    

    const renderStep = () => {
      switch (step) {
        case 1:
          return <PersonalInfo nextStep={nextStep} handleChange={handleChange('personalInfo')} values={formData.personalInfo} />;
        case 2:
          return <Education prevStep={prevStep} nextStep={nextStep} handleChange={handleChange('education')} values={formData.education} />;
          case 3:
          return (
              <WorkExperience 
              prevStep={prevStep} 
              nextStep={nextStep} 
              handleChange={handleWorkExperienceChange} 
              values={{ workExperiences: formData.workExperiences }} 
              />
          );
          case 4:
          return <Skills prevStep={prevStep} nextStep={nextStep} handleChange={handleSkillsChange} values={formData.skills} />;
        
        case 5: 
        return <ResumeUpload prevStep={prevStep} nextStep={nextStep} handleChange={handleChange('resume')} />;
        case 6:
          return <FinishSetup handleSubmit={handleSubmit} values={formData} handleChange={handleChange} />;
        default:
          return null;
      }
    };

    const steps = [
      { label: 'Personal Information', number: 1 },
      { label: 'Education', number: 2 },
      { label: 'Work Experience', number: 3 },
      { label: 'Skills', number: 4 },
      
      { label: 'Resume', number: 5 },
      { label: 'Finish', number: 6 },
    ];

    return (
      <div className="min-h-screen bg-gray-100 flex flex-col md:flex-row">
      {/* Desktop Navigation */}
      <div className="hidden md:block md:w-1/4 bg-gray-100 fixed h-full overflow-y-auto">
        <div className="py-24 px-6">
          {steps.map((s, index) => (
            <div key={s.number} className="flex items-center mb-8 text-sm">
              <div className="relative">
                <div className={`w-8 h-8 rounded-full flex items-center justify-center font-semibold ${
                  step >= s.number ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-600'
                }`}>
                  {s.number}
                </div>
                {index < steps.length - 1 && (
                  <div className={`absolute left-4 top-8 w-0.5 h-12 -ml-px ${
                    step > s.number ? 'bg-green-500' : 'bg-gray-200'
                  }`} />
                )}
              </div>
              <span className="ml-4 text-gray-700 font-medium">{s.label}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Content */}
      <div className="w-full md:w-3/4 md:ml-auto p-2 md:p-4"> 
        <div className="bg-white rounded-lg shadow-md p-4 md:p-6"> 
          <h1 className="text-2xl md:text-3xl font-bold mb-4 text-center">Set up your Profile</h1>
          <div className="flex justify-center">
            <div className="bg-blue-600 text-white px-3 py-1.5 rounded-full inline-block mb-4 md:mb-5 font-light"> 
              Experienced Jobseeker
            </div>
          </div>
          {error && <div className="text-red-500 text-center mb-4">{error}</div>}
          <div className="overflow-y-auto max-h-[calc(100vh-250px)] transition-all duration-300 ease-in-out">
            {renderStep()}
          </div>
        </div>
      </div>
    </div>
    );
  };

  ExperiencedProfileSetup.propTypes = {
    createJobseekerProfile: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired, 
    auth: PropTypes.object.isRequired
  };

  const mapStateToProps = (state) => ({
    auth: state.auth
  });

  export default connect(mapStateToProps, { createJobseekerProfile,setAlert })(ExperiencedProfileSetup);
