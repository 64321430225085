import React from 'react';
import PropTypes from 'prop-types';

const FinishSetup = ({ handleSubmit, values, handleChange }) => {
  const handleAgreeChange = (e) => {
    handleChange('agreeToTerms')('agreeToTerms')(e.target.checked);
  };

  return (
    <div className="w-full max-w-4xl bg-gray-100 mx-auto p-4 sm:p-6 lg:p-8 rounded-lg">
      <h2 className="text-2xl font-semibold mb-6">Finish Setup</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-600 text-white py-1 px-6 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

FinishSetup.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default FinishSetup;