import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CompanyProfileTop from './ProfileTop';
import CompanyProfileAbout from './ProfileAbout';
import CompanyProfileValues from './ProfileValues';
import EditCompanyProfile from './EditCompanyProfile';

const CompanyProfile = ({ profile }) => {
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);

  const handleEditProfile = () => {
    setIsEditProfileOpen(true);
  };

  const handleCloseEditProfile = () => {
    setIsEditProfileOpen(false);
  };

  if (!profile) {
    return <div>Loading profile...</div>;
  }

  return (
    <div className="rounded-lg bg-white max-w-6xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">My Profile</h1>
      </div>
      <CompanyProfileTop profile={profile} onEditProfile={handleEditProfile} />
      <div className="bg-[#F7F9FF] rounded-lg">
        <CompanyProfileAbout 
          about={profile.companyInfo.about}
          mission={profile.companyInfo.mission}
          vision={profile.companyInfo.vision}
        />
      </div>
      <div className="bg-[#F7F9FF] rounded-lg mt-6">
        <CompanyProfileValues values={profile.companyInfo.values} />
      </div>
     

      {isEditProfileOpen && (
        <EditCompanyProfile
          profile={profile}
          onClose={handleCloseEditProfile}
        />
      )}
    </div>
  );
};

CompanyProfile.propTypes = {
  profile: PropTypes.object
};

export default CompanyProfile;