import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllApplications, updateApplicationStatus } from '../../actions/applicantManagement';

const statusColors = {
  pending: 'bg-yellow-100 text-yellow-800',
  approved: 'bg-green-100 text-green-800',
  rejected: 'bg-red-100 text-red-800'
};

const ApplicationManagement = () => {
  const dispatch = useDispatch();
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [statusTab, setStatusTab] = useState('all');
  const [detailsTab, setDetailsTab] = useState('personal');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  const { applications, loading } = useSelector(state => state.applicantManagement);

  useEffect(() => {
    dispatch(getAllApplications());
  }, [dispatch]);

  const handleStatusChange = async (jobId, applicantId, newStatus) => {
    await dispatch(updateApplicationStatus(jobId, applicantId, newStatus));
    dispatch(getAllApplications());
    if (selectedApplication && selectedApplication.id === applicantId) {
      setSelectedApplication(prev => ({ ...prev, status: newStatus }));
    }
  };

  // Filter applications based on status and search term
  const filteredApplications = applications?.filter(app => {
    const matchesStatus = statusTab === 'all' || app.status.toLowerCase() === statusTab;
    const matchesSearch = searchTerm === '' || 
      app.applicantName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      app.jobTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      app.companyName.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesStatus && matchesSearch;
  });

 // Pagination logic
 const indexOfLastItem = currentPage * itemsPerPage;
 const indexOfFirstItem = indexOfLastItem - itemsPerPage;
 const currentItems = filteredApplications?.slice(indexOfFirstItem, indexOfLastItem);
 const totalPages = Math.ceil((filteredApplications?.length || 0) / itemsPerPage);


  const ApplicationDetailModal = () => (
    showModal && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold">Application Details</h2>
              <button 
                onClick={() => {
                  setShowModal(false);
                  setDetailsTab('personal');
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {selectedApplication && (
              <div className="space-y-6">
                <div className="flex items-start space-x-6">
                  <div className="w-24 h-24 bg-gray-200 rounded-full flex-shrink-0 overflow-hidden">
                    {selectedApplication.applicantImage ? (
                      <img 
                        src={selectedApplication.applicantImage} 
                        alt={selectedApplication.applicantName} 
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center bg-gray-100">
                        <svg className="w-12 h-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="flex-1">
                    <h3 className="text-2xl font-semibold mb-2">{selectedApplication.applicantName}</h3>
                    <div className="flex items-center space-x-4">
                      <span className={`px-4 py-1.5 rounded-full text-sm font-medium ${statusColors[selectedApplication.status]}`}>
                        {selectedApplication.status.toUpperCase()}
                      </span>
                      <select
                        value={selectedApplication.status}
                        onChange={(e) => handleStatusChange(selectedApplication.jobId, selectedApplication.id, e.target.value)}
                        className="border rounded px-3 py-1.5 text-sm"
                      >
                        <option value="pending">Change to Pending</option>
                        <option value="approved">Change to Approved</option>
                        <option value="rejected">Change to Rejected</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="border-t pt-6">
                  <div className="flex space-x-6 mb-6">
                    {['personal', 'job'].map(tab => (
                      <button 
                        key={tab}
                        className={`px-4 py-2 text-sm font-medium transition-colors
                          ${detailsTab === tab 
                            ? 'text-blue-600 border-b-2 border-blue-600' 
                            : 'text-gray-500 hover:text-gray-700'}`}
                        onClick={() => setDetailsTab(tab)}
                      >
                        {tab === 'personal' ? 'Personal Information' : 'Job Information'}
                      </button>
                    ))}
                  </div>

                  <div className="bg-gray-50 rounded-lg p-6">
                    {detailsTab === 'personal' ? (
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="space-y-2">
                          <p className="flex items-center text-gray-600">
                            <span className="mr-2">📧</span>
                            <span className="font-medium">Email:</span>
                            <span className="ml-2">{selectedApplication.applicantEmail}</span>
                          </p>
                          <p className="flex items-center text-gray-600">
                            <span className="mr-2">📱</span>
                            <span className="font-medium">Phone:</span>
                            <span className="ml-2">{selectedApplication.applicantPhone}</span>
                          </p>
                          <p className="flex items-center text-gray-600">
                            <span className="mr-2">📍</span>
                            <span className="font-medium">Location:</span>
                            <span className="ml-2">{selectedApplication.applicantCity}</span>
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="space-y-2">
                          <p className="flex items-center text-gray-600">
                            <span className="font-medium">Position:</span>
                            <span className="ml-2">{selectedApplication.jobTitle}</span>
                          </p>
                          <p className="flex items-center text-gray-600">
                            <span className="font-medium">Company:</span>
                            <span className="ml-2">{selectedApplication.companyName}</span>
                          </p>
                          <p className="flex items-center text-gray-600">
                            <span className="font-medium">Experience Level:</span>
                            <span className="ml-2">{selectedApplication.experience}</span>
                          </p>
                          <p className="flex items-center text-gray-600">
                            <span className="font-medium">Applied Date:</span>
                            <span className="ml-2">
                              {new Date(selectedApplication.appliedDate).toLocaleDateString()}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );

  const PaginationControls = () => (
    <div className="flex flex-col sm:flex-row justify-between items-center mt-4 space-y-2 sm:space-y-0">
      <div className="flex items-center space-x-2">
        <span className="text-sm text-gray-600">Show</span>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(Number(e.target.value));
            setCurrentPage(1);
          }}
          className="border rounded px-2 py-1 text-sm"
        >
          {[5, 10, 25, 50].map(size => (
            <option key={size} value={size}>{size}</option>
          ))}
        </select>
        <span className="text-sm text-gray-600">entries</span>
      </div>

      <div className="flex items-center space-x-2">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-3 py-1 text-sm border rounded hover:bg-gray-50 disabled:opacity-50"
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, i) => i + 1)
          .filter(page => {
            const distance = Math.abs(page - currentPage);
            return distance === 0 || distance === 1 || page === 1 || page === totalPages;
          })
          .map((page, index, array) => (
            <React.Fragment key={page}>
              {index > 0 && array[index - 1] !== page - 1 && (
                <span className="text-gray-500">...</span>
              )}
              <button
                onClick={() => setCurrentPage(page)}
                className={`px-3 py-1 text-sm rounded ${
                  currentPage === page
                    ? 'bg-blue-500 text-white'
                    : 'hover:bg-gray-50'
                }`}
              >
                {page}
              </button>
            </React.Fragment>
          ))}
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="px-3 py-1 text-sm border rounded hover:bg-gray-50 disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="p-2 sm:p-6">
      <div className="bg-white rounded-lg shadow">
        <div className="p-3 sm:p-6">
          <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">Application Management</h1>
          
          {/* Status Tabs - Now scrollable on mobile */}
          <div className="overflow-x-auto">
            <div className="flex space-x-4 mb-4 border-b min-w-max">
              {['all', 'pending', 'approved', 'rejected'].map(tab => (
                <button
                  key={tab}
                  onClick={() => setStatusTab(tab)}
                  className={`px-3 py-2 text-xs sm:text-sm font-medium transition-colors
                    ${statusTab === tab 
                      ? 'text-blue-600 border-b-2 border-blue-600' 
                      : 'text-gray-500 hover:text-gray-700'}`}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
          </div>

          {/* Search Input */}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search applications..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
              className="w-full sm:w-64 px-3 py-2 border rounded text-sm"
            />
          </div>

          {/* Table Container */}
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-2 sm:px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Applicant</th>
                  <th className="px-2 sm:px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">Job Details</th>
                  <th className="px-2 sm:px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell">Experience</th>
                  <th className="px-2 sm:px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">Applied Date</th>
                  <th className="px-2 sm:px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-2 sm:px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentItems?.map(application => (
                  <tr key={application.id} className="hover:bg-gray-50">
                    <td className="px-2 sm:px-4 py-2">
                      <div className="flex items-center space-x-2">
                        <div className="w-8 h-8 sm:w-10 sm:h-10 bg-gray-200 rounded-full flex-shrink-0 overflow-hidden">
                          {application.applicantImage ? (
                            <img 
                              src={application.applicantImage} 
                              alt="" 
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <div className="w-full h-full flex items-center justify-center bg-gray-100">
                              <svg className="w-4 h-4 sm:w-6 sm:h-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                              </svg>
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="text-xs sm:text-sm font-medium">{application.applicantName}</div>
                          <div className="text-xs text-gray-500 hidden sm:block">{application.applicantEmail}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 sm:px-4 py-2 hidden sm:table-cell">
                      <div className="text-xs sm:text-sm font-medium">{application.jobTitle}</div>
                      <div className="text-xs text-gray-500">{application.companyName}</div>
                    </td>
                    <td className="px-2 sm:px-4 py-2 text-xs sm:text-sm hidden lg:table-cell">{application.experience}</td>
                    <td className="px-2 sm:px-4 py-2 text-xs sm:text-sm hidden md:table-cell">
                      {new Date(application.appliedDate).toLocaleDateString()}
                    </td>
                    <td className="px-2 sm:px-4 py-2">
                      <span className={`px-2 py-1 rounded-full text-xs ${statusColors[application.status]}`}>
                        {application.status.toUpperCase()}
                      </span>
                    </td>
                    <td className="px-2 sm:px-4 py-2">
                      <button
                        onClick={() => {
                          setSelectedApplication(application);
                          setShowModal(true);
                        }}
                        className="bg-blue-500 text-white px-2 sm:px-4 py-1 sm:py-2 rounded text-xs sm:text-sm hover:bg-blue-600 transition-colors"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <PaginationControls />
        </div>
      </div>
      <ApplicationDetailModal />
    </div>
  );
};

export default ApplicationManagement;