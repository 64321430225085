import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const JobSeekerProfileTop = ({ profile, onEditProfile }) => {
  const getCurrentOrMostRecentJob = (workExperiences) => {
    if (!workExperiences || workExperiences.length === 0) return null;
    
    const sortedExperiences = [...workExperiences].sort((a, b) => 
      moment(b.startDate).diff(moment(a.startDate))
    );

    return sortedExperiences.find(exp => !exp.endDate) || sortedExperiences[0];
  };

  const currentJob = getCurrentOrMostRecentJob(profile?.workExperiences);

  const formatMemberSince = (date) => {
    if (!date) return 'Not specified';
    return moment(date).format('MMMM YYYY');
  };

  return (
    <div className="p-4 sm:p-6 bg-white rounded-lg">
      <h1 className="text-2xl sm:text-3xl font-bold mb-6">My Profile</h1>
      <div className="flex flex-col sm:flex-row items-start sm:items-center">
      <div className="w-24 h-24 rounded-lg mr-4 overflow-hidden">
          {profile?.avatar?.url ? (
            <img 
              src={profile.avatar.url} 
              alt={`${profile.firstName} ${profile.lastName}`} 
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-indigo-600 flex items-center justify-center text-white text-xl font-bold">
              {profile?.firstName?.[0] || ''}
            </div>
          )}
        </div>
        <div className="flex-grow">
          <h2 className="text-xl sm:text-2xl font-semibold">{`${profile?.firstName || ''} ${profile?.lastName || ''}`}</h2>
          <p className="text-blue-600 text-base">
            {currentJob ? `${currentJob.jobTitle} at ${currentJob.companyName}` : 'Job Title Not Specified'}
          </p>
          <div className="flex items-center text-gray-600 mt-2 text-base">
            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
            <span>{`${profile?.address?.city || 'City'}, ${profile?.address?.country || 'Country'}`}</span>
          </div>
          <div className="flex items-center text-gray-600 mt-1 text-base">
            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>
            <span>Member since: {formatMemberSince(profile?.date)}</span>
          </div>
        </div>
        <button
        onClick={onEditProfile}
        className="bg-white text-blue-600 border border-blue-400 font-roboto font-medium py-1 px-4 rounded hover:bg-blue-50 hover:border-blue-500 hover:text-blue-700"
        style={{ color: '#2563eb' }} 
      >
        Edit Profile
      </button>

      </div>
    </div>
  );
};

JobSeekerProfileTop.propTypes = {
  profile: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatar: PropTypes.shape({
      url: PropTypes.string,
    }),
    email: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
    }),
    dateOfBirth: PropTypes.string,
    gender: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.string),
    education: PropTypes.arrayOf(PropTypes.shape({
      degree: PropTypes.string,
    })),
    workExperiences: PropTypes.arrayOf(PropTypes.shape({
      jobTitle: PropTypes.string,
      companyName: PropTypes.string,
      startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    })),
    isFresher: PropTypes.bool,
    resume: PropTypes.shape({
      url: PropTypes.string,
    }),
    date: PropTypes.string,
  }),
  onEditProfile: PropTypes.func.isRequired,
};

export default JobSeekerProfileTop;