import React, { useState, useEffect, useRef } from 'react';
import { FaSearch, FaChevronDown, FaMoneyBillWave, FaBullhorn, FaPaintBrush, FaLaptopCode, FaUsers, FaBriefcaseMedical, FaMapMarkerAlt, FaClock, FaBookmark } from 'react-icons/fa';
import backgroundImage from "../../img/heroBgHireHub.webp";
import { connect } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import Login from '../auth/Login';

const Hero = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [jobType, setJobType] = useState('');
  const [experience, setExperience] = useState('');
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const searchResultsRef = useRef(null);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'N/A';
    const dateObj = new Date(timestamp);
    if (isNaN(dateObj.getTime())) {
      console.error('Invalid timestamp:', timestamp);
      return 'Invalid Date';
    }
    return dateObj.toISOString().split('T')[0];
  };

  const searchJobs = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post('/api/jobs/filterJobs', {
        jobTitle: data.jobTitle,
        jobType: data.jobType,
        experienceLevel: data.experienceLevel,
      });
      console.log('API response:', response.data);
      setJobs(response.data);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setJobs([]);
    } finally {
      setIsLoading(false);
      setShowResults(true);
    }
  };

  const debouncedSearch = debounce((term, type, exp) => {
    if (term.length >= 3 || type || exp) {
      const data = {
        jobTitle: term,
        jobType: type,
        experienceLevel: exp,
      };
      searchJobs(data);
    } else {
      setShowResults(false);
    }
  }, 300);

  useEffect(() => {
    if (searchTerm.length >= 3 || jobType || experience) {
      debouncedSearch(searchTerm, jobType, experience);
    } else {
      setJobs([]);
      setShowResults(false);
    }
  }, [searchTerm, jobType, experience]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const jobLogo = (jobTitle) => {
    if (!jobTitle || typeof jobTitle !== 'string') return '';
    const jobTitleUppercase = jobTitle.toUpperCase();
    let logo = '';
    jobTitleUppercase.split(' ').forEach((word) => {
      if (word.length > 0) {
        logo += word[0];
      }
    });
    return logo;
  };

  const handleViewJob = (jobId) => {
    navigate(`/job-listings?jobId=${jobId}&searchTerm=${searchTerm}&jobType=${jobType}&experience=${experience}`);
  };

  return (
    <section
      className="bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen p-4 sm:p-8"
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.1)), url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="max-w-5xl mx-auto">
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-6 sm:mb-8 text-center text-black leading-tight">
          Start Your Dream Career Now
        </h1>

        <div className="bg-white rounded-full shadow-xl mb-8 sm:mb-12 transition-all duration-300 hover:shadow-2xl overflow-visible relative">
        <div className="flex flex-col sm:flex-row items-center">
  {/* Search Input */}
  <div className="flex-grow flex items-center w-full sm:w-auto border-b sm:border-b-0 rounded-none sm:rounded-lg">
    <FaSearch className="text-gray-400 ml-4 sm:ml-6 mr-3" />
    <input
      type="text"
      placeholder="Enter Job titles"
      className="w-full py-3 sm:py-4 focus:outline-none text-sm sm:text-base rounded-none sm:rounded-lg"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  </div>

  {/* Job Type and Experience Dropdowns */}
  <div className="flex w-full sm:w-auto">
    {/* Job Type Dropdown */}
    <div className="relative w-1/2 sm:w-auto border-r rounded-none sm:rounded-lg">
      <select
        value={jobType}
        onChange={(e) => setJobType(e.target.value)}
        className="appearance-none bg-transparent pl-4 pr-8 py-3 sm:py-4 text-gray-600 focus:outline-none text-sm sm:text-base rounded-none sm:rounded-lg"
      >
        <option value="">Job type</option>
        <option value="full-time">Full-time</option>
        <option value="part-time">Part-time</option>
        <option value="contract">Contract</option>
        <option value="internship">Internship</option>
        <option value="freelance">Freelance</option>
      </select>
      <FaChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none text-xs sm:text-sm" />
    </div>

    {/* Experience Dropdown */}
    <div className="relative w-1/2 sm:w-auto rounded-none sm:rounded-lg">
      <select
        value={experience}
        onChange={(e) => setExperience(e.target.value)}
        className="appearance-none bg-transparent pl-4 pr-8 py-3 sm:py-4 text-gray-600 focus:outline-none text-sm sm:text-base rounded-none sm:rounded-lg"
      >
        <option value="">Experience</option>
        <option value="Entry">Entry Level</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Senior">Senior</option>
      </select>
      <FaChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none text-xs sm:text-sm" />
    </div>
  </div>
</div>


{(showResults || isLoading) && (
        <div 
          ref={searchResultsRef}
          className="absolute left-0 right-0 mt-2 bg-white rounded-lg shadow-lg overflow-hidden z-10"
          style={{maxHeight: '60vh', overflowY: 'auto'}}
        >
          {isLoading ? (
            <div className="text-center py-8">
              <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              <p className="mt-2 text-gray-500">Searching for jobs...</p>
            </div>
          ) : jobs && jobs.length > 0 ? (
            jobs.map(job => (
              <div key={job._id} className="p-4 border-b last:border-b-0 hover:bg-gray-100">
                <div className="flex items-center">
                  <div className="w-10 h-10 flex items-center justify-center rounded-full mr-4 bg-blue-500 text-white font-bold text-lg">
                    {jobLogo(job.jobTitle)}
                  </div>
                  <div className="flex-grow">
                    <h2 className="text-lg font-semibold">{job.jobTitle || 'Job Title Not Available'}</h2>
                    <p className="text-sm text-gray-600">{job.industry || 'Industry Not Specified'}</p>
                    <div className="text-xs text-gray-500 flex items-center mt-1">
                      <FaMapMarkerAlt className="mr-1" />
                      <span className="mr-2">{job.location || 'Location Not Specified'}</span>
                      <FaClock className="mr-1" />
                      <span>Posted: {formatTimestamp(job.date || job.postedOn)}</span>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleViewJob(job._id)}
                      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded text-xs transition duration-300"
                    >
                      View Job
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-8">
              <p className="text-gray-500">No jobs found</p>
            </div>
          )}
        </div>
      )}
        </div>

        <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold mb-6 sm:mb-8 text-center text-black">
          What are you exploring?
        </h2>

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-6">
          {[
            { icon: <FaMoneyBillWave />, text: "Accounting / Finance" },
            { icon: <FaBullhorn />, text: "Marketing" },
            { icon: <FaPaintBrush />, text: "Design" },
            { icon: <FaLaptopCode />, text: "Development" },
            { icon: <FaUsers />, text: "Human Resource" },
            { icon: <FaBriefcaseMedical />, text: "Health and Care" },
          ].map((item, index) => (
            <div key={index} className="bg-white p-3 sm:p-4 rounded-lg shadow-md text-center transition-all duration-300 hover:shadow-lg">
              <div className="inline-block p-2 sm:p-3 bg-blue-100 rounded-full mb-2 sm:mb-3 text-xl sm:text-2xl text-blue-500">
                {item.icon}
              </div>
              <p className="font-medium text-xs sm:text-sm text-gray-800 font-roboto">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
     
    </section>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Hero);