import React, { useState } from 'react';
import { FaGlobe, FaBriefcase, FaUsers, FaBuilding } from 'react-icons/fa';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const CompanyStats = () => {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  const stats = [
    {
      icon: <FaGlobe className="text-4xl text-blue-500" />,
      number: 50,
      label: "Countries Served",
      suffix: "+"
    },
    {
      icon: <FaBriefcase className="text-4xl text-teal-500" />,
      number: 100,
      label: "Job Opportunities",
      suffix: "K+"
    },
    {
      icon: <FaUsers className="text-4xl text-purple-500" />,
      number: 1000000,
      label: "Job Seekers",
      suffix: "+"
    },
    {
      icon: <FaBuilding className="text-4xl text-orange-500" />,
      number: 10000,
      label: "Employers",
      suffix: "+"
    }
  ];

  const features = [
    "Personalized job recommendations",
    "Resume builder and optimization tools",
    "Interview preparation resources",
    "Career development workshops"
  ];

  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setViewPortEntered(true);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="flex flex-col lg:flex-row items-center">
        {/* Left side - Image */}
        <div className="lg:w-1/2 mb-8 lg:mb-0 relative">
          <img 
            src="https://images.pexels.com/photos/3777564/pexels-photo-3777564.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load" 
            alt="Job seeker using HireHub" 
            className="rounded-lg w-full max-w-md mx-auto shadow-lg"
          />
          <div className="absolute top-4 left-1/2 transform -translate-x-1/2 bg-white rounded-full py-2 px-4 shadow-lg">
            <div className="flex items-center space-x-2">
              <div className="flex -space-x-1 overflow-hidden">
                {[...Array(6)].map((_, i) => (
                  <img key={i} className="inline-block h-6 w-6 rounded-full ring-2 ring-white" src={`https://randomuser.me/api/portraits/men/${i}.jpg`} alt="" />
                ))}
              </div>
              <span className="text-green-500 font-semibold">1M+</span>
              <span className="text-sm text-gray-600">Happy Job Seekers</span>
            </div>
          </div>
        </div>

        {/* Right side - Content */}
        <div className="lg:w-1/2 lg:pl-12">
          <h2 className="text-sm font-semibold text-blue-600 uppercase tracking-wide">About HireHub</h2>
          <h1 className="mt-2 text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Connecting Talent with Opportunities Worldwide
          </h1>
          <p className="mt-4 text-lg text-gray-500">
            HireHub is your gateway to a world of career possibilities. We're committed to helping you find the perfect job that aligns with your skills and aspirations.
          </p>
          <div className="mt-6 space-y-4">
            {features.map((feature, index) => (
              <div key={index} className="flex items-center">
                <svg className="h-5 w-5 text-blue-500" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <span className="ml-2 text-gray-600 text-base">{feature}</span>
              </div>
            ))}
          </div>

          <VisibilitySensor onChange={onVisibilityChange} partialVisibility offset={{ bottom: 200 }}>
            <div className="mt-8 grid grid-cols-2 gap-8">
              {stats.map((stat, index) => (
                <div key={index} className="flex items-center">
                  {stat.icon}
                  <div className="ml-4">
                    <div className="text-2xl font-bold text-gray-900">
                      <CountUp
                        start={viewPortEntered ? null : 0}
                        end={stat.number}
                        duration={2.5}
                        separator=","
                        suffix={stat.suffix}
                        redraw={true}
                      >
                        {({ countUpRef }) => (
                          <span ref={countUpRef} />
                        )}
                      </CountUp>
                    </div>
                    <div className="text-sm font-medium text-gray-500">{stat.label}</div>
                  </div>
                </div>
              ))}
            </div>
          </VisibilitySensor>
        </div>
      </div>
    </div>
  );
};

export default CompanyStats;