// reducers/adminJobReducer.js
import {
    GET_ADMIN_JOBS,
    UPDATE_JOB_STATUS,
    ADMIN_JOB_ERROR,
    SET_LOADING,
    DELETE_JOB,
    BULK_UPDATE_JOB_STATUS
  } from '../actions/types';
  
  const initialState = {
    jobs: [],
    loading: false,
    error: null
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case SET_LOADING:
        return {
          ...state,
          loading: true
        };
      case GET_ADMIN_JOBS:
        return {
          ...state,
          jobs: payload,
          loading: false
        };
      case UPDATE_JOB_STATUS:
        return {
          ...state,
          jobs: state.jobs.map(job =>
            job._id === payload.jobId
              ? { ...job, status: payload.status }
              : job
          ),
          loading: false
        };
        case BULK_UPDATE_JOB_STATUS:
        const updatedJobs = state.jobs.map(job => {
            const result = action.payload.results.find(r => r.jobId === job._id);
            if (result) {
            return result.status === 'rejected' ? null : result;
            }
            return job;
        }).filter(Boolean);

        return {
            ...state,
            jobs: updatedJobs,
            loading: false
        };
        case DELETE_JOB:
        return {
            ...state,
            jobs: state.jobs.filter(job => job._id !== payload),
            loading: false
        };
      case ADMIN_JOB_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
      default:
        return state;
    }
  }