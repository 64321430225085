import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../../actions/auth'; // Adjust the import path as needed

const LogOut = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    // Optionally, you can redirect the user after logout
    window.location.href = '/login';
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8 bg-white rounded-lg">
      <div className="p-8 mt-6">

        <div className="max-w-3xl mx-auto px-4 py-8 bg-white rounded-lg">
          <p className="text-center mb-6">Are you sure you want to log out?</p>
          <div className="flex justify-center">
            <button onClick={handleLogout} className="bg-red-500 hover:bg-red-600 text-white font-semibold text-base py-2 px-4 rounded inline-flex items-center">
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
              </svg>
              Log out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogOut;