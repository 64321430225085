import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  FaMapMarkerAlt, FaClock, FaFilter, FaBriefcase, FaRupeeSign, 
  FaBookmark, FaTimes, FaGraduationCap, FaCalendarAlt, FaIndustry,
  FaBuilding, FaUsers, FaGlobe, FaLinkedin, FaRegEnvelope, FaTags
} from 'react-icons/fa';
import debounce from 'lodash/debounce';

const JobListings = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({
      jobType: [],
      experienceLevel: [],
      location: '',
      salary: [0, 100],
      jobTitle: '',
      industry: '',
    });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get('jobId');
    const searchTerm = searchParams.get('searchTerm');
    const jobType = searchParams.get('jobType');
    const experience = searchParams.get('experience');
  
    setFilters(prevFilters => ({
      ...prevFilters,
      jobTitle: searchTerm || '',
      jobType: jobType ? [jobType] : [],
      experienceLevel: experience ? [experience] : [],
    }));
  
    fetchJobs();
  }, [location]);

  const fetchJobs = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('/api/jobs/filterJobs', filters);
      setJobs(response.data);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchJobs = useCallback(
    debounce(() => {
      fetchJobs();
    }, 300),
    [filters]
  );

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters(prevFilters => {
      if (type === 'checkbox') {
        const updatedArray = checked
          ? [...prevFilters[name], value]
          : prevFilters[name].filter(item => item !== value);
        return { ...prevFilters, [name]: updatedArray };
      } else if (name === 'salary') {
        return { ...prevFilters, [name]: value.split(',').map(Number) };
      } else {
        return { ...prevFilters, [name]: value };
      }
    });
  };

  useEffect(() => {
    debouncedFetchJobs();
  }, [filters, debouncedFetchJobs]);

  const applyFilters = () => {
    const searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(v => searchParams.append(key, v));
      } else if (value) {
        searchParams.append(key, value);
      }
    });
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const clearFilters = () => {
    setFilters({
      jobType: [],
      experienceLevel: [],
      location: '',
      salary: [],
      jobTitle: '',
      industry: '',
    });
    navigate(location.pathname);
  };

  const removeFilter = (key, value) => {
    setFilters(prevFilters => {
      if (key === 'salary') {
        return { ...prevFilters, salary: [0, 100] };
      } else if (Array.isArray(prevFilters[key])) {
        return { ...prevFilters, [key]: prevFilters[key].filter(v => v !== value) };
      } else {
        return { ...prevFilters, [key]: '' };
      }
    });
  };

  const handleSalaryChange = (e, index) => {
    const value = Math.max(0, Math.min(100, Number(e.target.value)));
    setFilters(prevFilters => {
      const newSalary = [...prevFilters.salary];
      newSalary[index] = value;
      return { ...prevFilters, salary: newSalary };
    });
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'N/A';
    const dateObj = new Date(timestamp);
    if (isNaN(dateObj.getTime())) {
      console.error('Invalid timestamp:', timestamp);
      return 'Invalid Date';
    }
    const now = new Date();
    const diffTime = Math.abs(now - dateObj);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
  };

  const formatDeadline = (timestamp) => {
    if (!timestamp) return 'N/A';
    const dateObj = new Date(timestamp);
    if (isNaN(dateObj.getTime())) {
      console.error('Invalid timestamp:', timestamp);
      return 'Invalid Date';
    }
    return dateObj.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  };

  return (
    <div className="mt-[-6rem]">
      <div className="container mx-auto px-4 py-2 bg-[#f8f9fa]">
        <div className="flex flex-col md:flex-row py-3 gap-6">
          {/* Mobile Filter Toggle */}
          <div className="md:hidden mb-4">
            <button 
              onClick={() => setShowFilters(!showFilters)}
              className="w-full bg-blue-600 text-white px-4 py-2 rounded-xl hover:bg-blue-700 transition duration-300 flex items-center justify-center"
            >
              <FaFilter className="mr-2" />
              {showFilters ? 'Hide Filters' : 'Show Filters'}
            </button>
          </div>

          {/* Filters */}
          <div className={`w-full md:w-1/4 md:sticky md:top-0 md:h-screen overflow-y-auto ${showFilters ? 'block' : 'hidden md:block'}`}>
            <div className="bg-[#fff] p-4 rounded-lg shadow-sm mb-4 md:mb-0">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">All Filters</h2>
                <button
                  onClick={clearFilters}
                  className="text-sm text-blue-500 hover:text-blue-600"
                >
                  Clear All
                </button>
              </div>
              <div className="space-y-4">
                {/* Job Type Filter */}
                <div>
                  <h3 className="font-bold text-base mb-2">Job Type</h3>
                  <div className="space-y-2">
                    {['Full-time', 'Part-time', 'Contract', 'Internship'].map((type) => (
                      <label key={type} className="flex items-center text-base">
                        <input
                          type="checkbox"
                          name="jobType"
                          value={type}
                          checked={filters.jobType.includes(type)}
                          onChange={handleFilterChange}
                          className="mr-2"
                        />
                        {type}
                      </label>
                    ))}
                  </div>
                </div>

                {/* Experience Level Filter */}
                <div>
                  <h3 className="font-bold text-base mb-2">Experience Level</h3>
                  <div className="space-y-2">
                    {['Entry', 'Intermediate', 'Senior'].map((level) => (
                      <label key={level} className="flex items-center text-base">
                        <input
                          type="checkbox"
                          name="experienceLevel"
                          value={level}
                          checked={filters.experienceLevel.includes(level)}
                          onChange={handleFilterChange}
                          className="mr-2"
                        />
                        {level}
                      </label>
                    ))}
                  </div>
                </div>

                {/* Location Filter */}
                <div>
                  <h3 className="font-bold text-base mb-2">Location</h3>
                  <input
                    type="text"
                    name="location"
                    value={filters.location}
                    onChange={handleFilterChange}
                    className="w-full border rounded p-2 text-sm placeholder:text-sm"
                    placeholder="Enter location"
                  />
                </div>

                {/* Salary Filter */}
                <div>
                  <h3 className="font-bold text-base mb-2">Salary Range (in Lakhs per annum)</h3>
                  <div className="flex items-center space-x-2">
                    <input
                      type="number"
                      name="salaryMin"
                      value={filters.salary[0]}
                      onChange={(e) => handleSalaryChange(e, 0)}
                      className="w-1/4 border rounded p-2 text-sm"
                      min="0"
                      max="100"
                    />
                    <span>-</span>
                    <input
                      type="number"
                      name="salaryMax"
                      value={filters.salary[1]}
                      onChange={(e) => handleSalaryChange(e, 1)}
                      className="w-1/4 border rounded p-2 text-sm"
                      min="0"
                      max="100"
                    />
                    <span className="text-base">Lakhs</span>
                  </div>
                </div>

                {/* Industry Filter */}
                <div>
                  <h3 className="font-bold text-base mb-2">Industry</h3>
                  <input
                    type="text"
                    name="industry"
                    value={filters.industry}
                    onChange={handleFilterChange}
                    className="w-full border rounded p-2 text-sm placeholder:text-sm"
                    placeholder="Enter industry"
                  />
                </div>

                <button
                  onClick={applyFilters}
                  className="w-full text-base bg-blue-600 text-white px-4 py-2 rounded-xl hover:bg-blue-700 transition duration-300"
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </div>

          {/* Job Listings */}
          <div className="w-full md:w-3/4 overflow-y-auto">
            {/* Active Filters */}
            <div className="mb-4 flex flex-wrap gap-2">
              {Object.entries(filters).map(([key, value]) => {
                if (key === 'salary' && (value[0] > 0 || value[1] < 100)) {
                  return (
                    <span key={key} className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded flex items-center">
                      {`Salary: ${value[0]}-${value[1]} Lakhs`}
                      <button onClick={() => removeFilter('salary')} className="ml-1">
                        <FaTimes className="w-3 h-3" />
                      </button>
                    </span>
                  );
                } else if (Array.isArray(value) && value.length > 0) {
                  return value.map(v => (
                    <span key={`${key}-${v}`} className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded flex items-center">
                      {v}
                      <button onClick={() => removeFilter(key, v)} className="ml-1">
                        <FaTimes className="w-3 h-3" />
                      </button>
                    </span>
                  ));
                } else if (value && typeof value === 'string') {
                  return (
                    <span key={key} className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded flex items-center">
                      {`${key}: ${value}`}
                      <button onClick={() => removeFilter(key)} className="ml-1">
                        <FaTimes className="w-3 h-3" />
                      </button>
                    </span>
                  );
                }
                return null;
              })}
            </div>

            {isLoading ? (
              <div className="text-center py-8">
                <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                <p className="mt-2 text-gray-500">Loading jobs...</p>
              </div>
            ) : jobs.length > 0 ? (
              <div className="space-y-4">
                {jobs.map((job) => (
                  <Link
                    to={`/job/${job._id}`}
                    key={job._id}
                    className="block bg-white p-4 md:p-6 rounded-2xl shadow-sm hover:shadow-md transition duration-300 mb-4 border border-gray-200"
                  >
                    <div className="flex justify-between items-start mb-2">
                      <div>
                        <h2 className="text-base font-semibold text-[#333]">{job.jobTitle}</h2>
                      </div>
                      <button className="text-blue-500 hover:text-blue-600">
                        <FaBookmark size={20} />
                      </button>
                    </div>

                    <div className="flex flex-wrap items-center text-xs md:text-sm text-gray-500 mb-4 gap-2 md:gap-4">
                      <div className="flex items-center">
                        <FaMapMarkerAlt className="mr-1 text-gray-400" />
                        <span>{job.location || 'Mumbai'}</span>
                      </div>
                      <div className="flex items-center">
                        <FaBriefcase className="mr-1 text-gray-400" />
                        <span>{job.jobType || 'Full-time'}</span>
                      </div>
                      <div className="flex items-center">
                        <FaRupeeSign className="mr-1 text-gray-400" />
                        <span>{job.offeredSalary || '3 lac'}</span>
                      </div>
                      <div className="flex items-center">
                        <FaGraduationCap className="mr-1 text-gray-400" />
                        <span>{job.qualification || 'Bachelors'}</span>
                      </div>
                      <div className="flex items-center">
                        <FaCalendarAlt className="mr-1 text-gray-400" />
                        <span>Deadline: {formatDeadline(job.applicationDeadline)}</span>
                      </div>
                    </div>

                    <div className="mb-4">
                      <h3 className="font-semibold text-sm md:text-base mb-2 text-[#333]">Key Skills:</h3>
                      <div className="text-xs md:text-sm text-gray-600">
                        {job.keySkills && job.keySkills.length > 0 ? job.keySkills.join(', ') : 'Upwork bidding, web dev'}
                      </div>
                    </div>

                    <div className="flex justify-between items-center text-xs md:text-sm mt-4">
                      <div className="flex items-center text-gray-500">
                        <FaClock className="mr-1 text-gray-400" />
                        <span>{formatTimestamp(job.date)}</span>
                      </div>
                      <div className="flex items-center">
                        <FaTags className="mr-1 text-gray-400" />
                        <span className="text-gray-600">{job.industry || 'Marketing'}</span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <div className="text-center py-8">
                <p className="text-gray-500">No jobs found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobListings;