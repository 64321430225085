// reducers/analyticsReducer.js
import {
    GET_ANALYTICS_REQUEST,
    GET_ANALYTICS_SUCCESS,
    GET_ANALYTICS_FAIL,
    GET_MONTHLY_STATS_REQUEST,
    GET_MONTHLY_STATS_SUCCESS,
    GET_MONTHLY_STATS_FAIL,
    GET_DASHBOARD_COUNTS_REQUEST,
    GET_DASHBOARD_COUNTS_SUCCESS,
    GET_DASHBOARD_COUNTS_FAIL
  } from '../actions/types';
  
  const initialState = {
    loading: true,
    dashboardStats: null,
    monthlyStats: null,
    error: null
  };
  
  const analyticsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ANALYTICS_REQUEST:
      case GET_MONTHLY_STATS_REQUEST:
        return {
          ...state,
          loading: true
        };
  
      case GET_ANALYTICS_SUCCESS:
        return {
          ...state,
          loading: false,
          dashboardStats: action.payload
        };
  
      case GET_MONTHLY_STATS_SUCCESS:
        return {
          ...state,
          loading: false,
          monthlyStats: action.payload
        };
  
      case GET_ANALYTICS_FAIL:
      case GET_MONTHLY_STATS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
        
        case GET_DASHBOARD_COUNTS_REQUEST:
            return {
              ...state,
              loading: true
            };
      
          case GET_DASHBOARD_COUNTS_SUCCESS:
            return {
              ...state,
              loading: false,
              dashboardCounts: action.payload
            };
      
          case GET_DASHBOARD_COUNTS_FAIL:
            return {
              ...state,
              loading: false,
              error: action.payload
            };
      default:
        return state;
    }
  };
  
  export default analyticsReducer;