import './App.css';
import { Fragment, useEffect } from 'react';
import {BrowserRouter as Router, Routes, Route, useLocation,   } from 'react-router-dom';
import setAuthToken from './utils/setAuthToken';
//components
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import JobSeekerDashboard from './components/dashboard/ApplicantDashboard/Dashboard';


import ProfileSetupFresher from './components/profile-forms/SetupProfileJobSeeker';
import EmpolyerProfileCreation from './components/profile-forms/EmployerProfile/EmpolyerProfileCreation';
import ExperiencedProfileSetup from './components/profile-forms/JobSeekerExp/SetupExpJobSkr';

import Alert from './components/layout/Alert';
import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';

// Redux
import { Provider } from 'react-redux'
import store from './store'
//actions
import { loadEmployer, loadJobSeeker } from './actions/auth';
import CompanyDashboard from './components/dashboard/CompanyDashboard/Dashboard';
import PrivateEmployerRoutes from './components/routing/PrivateEmployerRoutes';
import PrivateRoutesJobseeker from './components/routing/PrivateRoutesJobseeker';
import FeaturedCompanies from './components/layout/FeaturedCompanies';
import FeaturedJobs from './components/layout/FeaturedJobs';
import SponsoredCompanies from './components/layout/SponsoredCompanies';
import JobListings from './components/job/JobListingsGuest';
import JobDetails from './components/job/JobDetails';
import PrivateRoute from './components/routing/PrivateRoute';

import AdminDashboard from './components/admin/AdminDashboard';

import AdminLogin from './components/admin/AdminLogin';
import AdminProtectedRoute from './components/routing/AdminProtectedRoute';



//import Dashboard from './components/dashboard/CompanyDashboard/Dashboard';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  
  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      const userType = localStorage.getItem('userType');
      if (userType === 'jobseeker') {
        store.dispatch(loadJobSeeker());
      } else if (userType === 'employer') {
        store.dispatch(loadEmployer());
      }
    }
  }, []);




  return (
    <Provider store={store}>
      <Router>
        <div className='relative'>
        <Navbar className="sticky top-0 z-50 bg-white" />
          {/* <Sidebar className=" bg-white" /> */}
          <main className="flex-grow pt-20 "> 
            <Alert />
            <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/login" element={<Login />} />
       
          
            <Route exact path="/job/:id" element={<JobDetails />} />
            <Route
                exact
                path="/companies"
                element={
                  <Fragment>
                    <FeaturedCompanies />
                    <SponsoredCompanies />
                  </Fragment>
                }
              />
            <Route exact path="/jobs" element={<FeaturedJobs />} />
            <Route element={<PrivateEmployerRoutes />}>
            <Route exact path="/employer-profile-creation" element={<EmpolyerProfileCreation />}/>
          </Route>
           
            <Route exact path="/fresher-profile-creation" element={<ProfileSetupFresher />}/>
              <Route exact path="/experienced-profile-creation" element={<ExperiencedProfileSetup />}/> 
                
          
         
            <Route path="/applicant-dashboard/*" element={<JobSeekerDashboard />} />
            <Route path="/company-dashboard/*" element={<CompanyDashboard/>} /> 

            <Route exact path="/job-listings" element={<JobListings />} />
            <Route path="/admin-login" element={<AdminLogin />} />
        <Route
            path="/admin-dashboard/*"
            element={<AdminProtectedRoute component={AdminDashboard} />}
        />
            </Routes>
            </main>
        </div>
      </Router>
    </Provider>
  );
}

export default App;

   {/* <Route element={<PrivateRoutesJobseeker />}>
              
            </Route> */}
{/* Company dashboard UI changes */}

{/* 



<Route exact path="/profile" element={<ApplicantProfile />} />
<Route exact path="/job" element={<Job />} /> */}


 
  {/* <Route exact path="/" element={<Landing />} />
  <Route exact path="/email" element={<Email />} />
  <Route exact path="/video" element={<Video />} />
  <Route exact path="/admin" element={<AdminLogin />} />
  <Route exact path="/login" element={<Login />} />
  <Route exact path="/register" element={<Register />} />
  <Route exact path="/profiles" element={<Profiles />} /> */}
  {/* <Route exact path="/profile/:id" element={<Profile />} />
  <Route exact path="/company-profile/:id" element={<CompanyProfile />} /> */}
  {/* <Route exact path="/jobs" element={<Jobs />} />
  <Route exact path="/job/:id" element={<Job />} />
  <Route exact path="/resume" element={<ResumeList />} />
  <Route exact path="/parser" element={<Parser />} /> */}
  {/* <Route element={<PrivateRoutes/>} >
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/create-profile" element={<CreateProfile />} />
    <Route path="/edit-profile" element={<EditProfile />} />
    <Route path="/add-experience" element={<AddExperience />} />
    <Route path="/add-education" element={<AddEducation />} />
    <Route path="/edit-cv" element={<CV/>} />
    <Route path="/posts" element={<Posts />} />
    <Route path="/posts/:id" element={<Post />} />
  </Route>
  <Route element={<PrivateCompanyRoutes />} >
    <Route path="/company-dashboard" element={<CompanyDashboard />} />
    <Route path="/post-job" element={<PostJob />} />
    <Route path="/edit-job/:id" element={<EditJob />} />
    <Route path="/job-company/:id" element={<JobCompany />} />
    <Route path="/create-company-profile" element={<CreateCompanyProfile />} />
    <Route path="/edit-company-profile" element={<EditCompanyProfile />} />
    <Route exact path="/resumes" element={<Resumes />} />
  </Route>
  <Route element={<PrivateAdminRoutes />}>
    <Route path='/admin-dashboard' element={<AdminDashboard />} />
  </Route> */}

