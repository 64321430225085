// src/reducers/employerProfileReducer.js
import {
    EMPLOYER_PROFILE_CREATE_SUCCESS,
    EMPLOYER_PROFILE_CREATE_FAIL
  } from '../actions/types';
  
  const initialState = {
    profile: null,
    loading: true,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case EMPLOYER_PROFILE_CREATE_SUCCESS:
        return {
          ...state,
          profile: payload,
          loading: false
        };
      case EMPLOYER_PROFILE_CREATE_FAIL:
        return {
          ...state,
          error: payload,
          loading: false
        };
      default:
        return state;
    }
  }