import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const ProfileExperience = ({ workExperiences, isFresher }) => {
  return (
    <div className="p-6 bg-[#F7F9FF] ">
      <h2 className="text-xl font-bold mb-4">Work and Experience</h2>
      {isFresher ? (
        <FresherMessage />
      ) : workExperiences && workExperiences.length > 0 ? (
        <div className="space-y-4">
          {workExperiences.map((exp, index) => (
            <ExperienceItem 
              key={index}
              jobTitle={exp.jobTitle}
              companyName={exp.companyName}
              startDate={exp.startDate}
              endDate={exp.endDate}
              responsibilities={exp.responsibilities}
            />
          ))}
        </div>
      ) : (
        <p className="text-gray-600">No work experience added yet.</p>
      )}
    </div>
  );
};

const ExperienceItem = ({ jobTitle, companyName, startDate, endDate, responsibilities }) => {
  const formatDate = (date) => moment(date).format('MMM YYYY');
  const period = `${formatDate(startDate)} - ${endDate ? formatDate(endDate) : 'Present'}`;

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-lg font-semibold">{jobTitle}</h3>
        <span className="text-sm bg-blue-100 text-blue-800 px-3 py-1 rounded-full">{period}</span>
      </div>
      <p className="text-blue-600 mb-2">{companyName}</p>
      {responsibilities && <p className="text-gray-600 text-sm">{responsibilities}</p>}
    </div>
  );
};

const FresherMessage = () => (
  <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded">
    <p className="font-bold">New to the workforce</p>
    <p>You're just starting your career journey. Good luck with your job search!</p>
  </div>
);

ProfileExperience.propTypes = {
  workExperiences: PropTypes.arrayOf(
    PropTypes.shape({
      jobTitle: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
      endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      responsibilities: PropTypes.string
    })
  ),
  isFresher: PropTypes.bool.isRequired
};

ExperienceItem.propTypes = {
  jobTitle: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  responsibilities: PropTypes.string
};

export default ProfileExperience;