import React from 'react';
import { Link } from 'react-router-dom';

const Newsletter = () => {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-blue-600 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center bg-white rounded-lg shadow-lg p-6 md:p-8">
          <div className="mb-6 md:mb-0 md:mr-8 flex-1 text-center md:text-left">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-2">Stay Updated</h3>
            <p className="text-sm md:text-base text-gray-600">
              Subscribe to our newsletter for the latest job opportunities and career insights.
            </p>
          </div>
          <div className="w-full md:w-auto flex-1">
            <form className="flex flex-col sm:flex-row">
              <input
                type="email"
                placeholder="Enter your email address"
                className="flex-grow px-4 py-3 mb-2 sm:mb-0 sm:mr-2 rounded-md sm:rounded-r-none border text-base placeholder:text-base border-gray-300 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              />
              <button 
                className="bg-blue-600 text-white px-6 py-3 rounded-md sm:rounded-l-none hover:bg-blue-700 transition duration-300 font-semibold w-full sm:w-auto"
                type="submit"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  const footerLinks = [
    {
      title: "Job Categories",
      links: [
        { name: "Telecommunications", path: "/jobs/" },
        { name: "Software", path: "/jobs" },
        { name: "Security Analyst", path: "/jobs-" },
        { name: "Market Research", path: "/jobs-" },
        { name: "Human Resource", path: "/jobs-" },
        { name: "Finance", path: "/jobs" },
        { name: "Education", path: "/jobsß" }
      ]
    },
    {
      title: "Job Types",
      links: [
        { name: "Full Time", path: "/job-types" },
        { name: "Part Time", path: "/job-types" },
        { name: "Temporary", path: "/job-types" },
        { name: "Freelance", path: "/job-types" },
        { name: "Internship", path: "/job-types" }
      ]
    },
    {
      title: "Resources",
      links: [
        { name: "Home", path: "/" },
        { name: "Jobs", path: "/jobs" },
        { name: "Employers", path: "/companies" },
        { name: "About Us", path: "/" },
        { name: "Blog", path: "/" },
        { name: "Contact", path: "/" },
        { name: "Privacy Policy", path: "/" },
        { name: "FAQ", path: "/" }
      ]
    }
  ];

  const socialLinks = [
    { name: 'facebook', url: 'https://facebook.com/hirehub' },
    { name: 'twitter', url: 'https://twitter.com/hirehub' },
    { name: 'linkedin', url: 'https://linkedin.com/company/hirehub' },
    { name: 'instagram', url: 'https://instagram.com/hirehub' },
    { name: 'pinterest', url: 'https://pinterest.com/hirehub' },
    { name: 'youtube', url: 'https://youtube.com/hirehub' }
  ];

  return (
    <footer className="bg-gray-50">
      <Newsletter />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          <div>
            <Link to="/" className="text-3xl font-bold mb-6 inline-block">
              Hire <span className="text-blue-600">Hub</span>
            </Link>
            <p className="text-gray-600 mb-6 text-base">
              Connecting talent with opportunities. Your next career move starts here.
            </p>
            <div className="space-y-3 text-sm text-gray-600">
              <p className="flex items-center">
                <svg className="w-5 h-5 mr-2 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                C-347, Near Metro Station New Ashok Nagar Delhi 110096
              </p>
              <p className="flex items-center">
                <svg className="w-5 h-5 mr-2 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>
                info@Hirehub.com
              </p>
              <p className="flex items-center">
                <svg className="w-5 h-5 mr-2 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" /></svg>
                +91 7827470343
              </p>
            </div>
          </div>

          {footerLinks.map((section, index) => (
            <div key={index}>
              <h3 className="text-lg font-semibold mb-6 text-gray-800">
                {section.title}
              </h3>
              <ul className="space-y-3">
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <Link to={link.path} className="text-base text-gray-600 hover:text-blue-600 transition duration-300 flex items-center">
                      <svg className="w-3 h-3 mr-2 text-blue-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-gray-100 py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm text-gray-600 mb-4 md:mb-0">
            © 2024 HireHub. All rights reserved.
          </p>
          <div className="flex space-x-6">
            {socialLinks.map((social) => (
              <a key={social.name} href={social.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700 transition duration-300">
                <i className={`fab fa-${social.name} text-xl`}></i>
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;